import { preTicketsPaginated } from 'types/PreTicketsTypes';
import useHttp from '../helpers/HttpMethods';
import { blackListPaginated, blacklistPaginatedContent } from 'types/BlackListTypes';
import { CompanyDetailed } from 'types/CompanyTypes';

class CompanyService {
  /**
   * Busca a empresa.
   * @param {number} id - ID da empresa.
   * @returns {Promise<CompanyDetailed>} - Empresa em formato detalhado.
   */
  static async findById (id: number): Promise<CompanyDetailed> {
    return await useHttp().get(`/companies/${id}`);
  }

  /**
   * Retorna o ID da empresa.
   * @returns {Promise<Response | string>}
   */
  static async getMyCompanyId(): Promise<Response | string> {
   return await useHttp().getInFormatText(`/companies/get-my-company-id`);
  }

  /**
   * Exclui uma blacklist.
   * @param {number} id - ID da empresa a ser atualizada.
   * @param {CompanyDetailed} body - Envia os dados para atualizar a empresa.
   * @returns {Promise<Response | CompanyDetailed>} - Retorno padrão de uma request ou 
   * o objeto inteiro atualizado.
   */
  static async edit(id: number, body: CompanyDetailed): Promise<Response | CompanyDetailed> {
    return await useHttp().put(body, `/companies/${id}`);
  }
}

export default CompanyService;