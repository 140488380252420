import Flex from "components/common/Flex";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import EmailNotificationTemplateService from "services/EmailNotificationTemplate.service";

const NotificationItem = ({ itemData, refreshAgent, refreshRequester }) => {

  const handleStatusChange = async () => {
    if (itemData.inactive) {
      const response = await EmailNotificationTemplateService.activate(itemData.id);
      if (response.ok) {
        itemData.recipientType === 'AGENT' ? refreshAgent() : refreshRequester();
      }
    } else {
      const response = await EmailNotificationTemplateService.inactivate(itemData.id);
      if (response.ok) {
        itemData.recipientType === 'AGENT' ? refreshAgent() : refreshRequester();
      }
    };
  }

  return (
    <tr className="mb-2">
      <td className="d-flex justify-content-between align-items-center px-1 border-0">
        <Flex>
          <Form.Check
            type="switch"
            id={`switch-${itemData.name}`}
            checked={!itemData.inactive}
            onChange={() => handleStatusChange()} />
          <Link
            to={`/settings/email-notifications/${itemData.id}/edit`}>
            {itemData.name}
          </Link>
        </Flex>

        <Button
          variant="falcon-default"
          size="sm"
          className="rounded-1 p-1"
          as={Link}
          to={`/settings/email-notifications/${itemData.id}/edit`}>
          Editar
        </Button>
      </td>
    </tr>
  )

}

export default NotificationItem;