import { Modal } from "react-bootstrap";

const NewReleaseModal = ({ show, close }) => {
  return (
    <Modal show={show} onHide={close} size="lg" centered>
      <Modal.Header closeButton className="border-bottom">
        <Modal.Title className="fw-bold">
          Novidades da Nova Release
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="lead mb-4">
          Estamos felizes em compartilhar as últimas atualizações que trouxemos para você. Confira os destaques desta release, melhorias implementadas e correções de bugs.
        </p>

        {/* Destaques da Release */}
        <h4 className="text-primary fw-bold mb-3">Destaques da Release</h4>
        <ul className="list-unstyled mb-4">
          {/* Notificações por E-mail */}
          <li className="mb-3">
            <span className="text-primary me-2">📧</span>
            <strong>Notificações por E-mail Personalizáveis:</strong>
            <ul className="list-unstyled ms-4">
              <li className="mb-2">
                Agora você pode criar templates personalizados para notificações por e-mail, tornando a comunicação com seus clientes e equipe mais eficiente.
              </li>
              <li className="mb-2">
                Use espaços reservados dinâmicos para incluir informações como número do ticket, nome do solicitante e agente responsável diretamente no e-mail.
              </li>
              <li className="mb-2">
                Para configurar, acesse <strong>Configurações / Notificações por email</strong> e crie seus templates de forma simples e intuitiva.
              </li>
            </ul>
          </li>

          {/* Integração MSP Talks: Cadastro Automático */}
          <li className="mb-3">
            <span className="text-success me-2">🤖</span>
            <strong>Cadastro Automático de Contatos (MSP Talks):</strong>
            <ul className="list-unstyled ms-4">
              <li className="mb-2">
                Agora, quando um cliente entra em contato pela primeira vez via MSP Talks, o cadastro dele é feito automaticamente no MSP Desk.
              </li>
              <li className="mb-2">
                Para usar essa funcionalidade, você precisa habilitar a opção de cadastro automático nas <strong>Configurações da Integração MSP Talks</strong>.
              </li>
              <li className="mb-2">
                Isso agiliza o atendimento e reduz a necessidade de cadastros manuais, permitindo que sua equipe foque no que realmente importa.
              </li>
            </ul>
          </li>

          {/* Integração MSP Talks: Inserção de Grupo de Serviço */}
          <li className="mb-3">
            <span className="text-success me-2">🎟️</span>
            <strong>Inserção Automática de Grupo de Serviço (MSP Talks):</strong>
            <ul className="list-unstyled ms-4">
              <li className="mb-2">
                Agora é possível configurar grupos de serviço no chatbot do MSP Talks e direcionar automaticamente os tickets para os grupos correspondentes no MSP Desk.
              </li>
              <li className="mb-2">
                Isso facilita o direcionamento correto dos tickets para as equipes responsáveis, agilizando o processo de resolução.
              </li>
            </ul>
          </li>

          {/* Importação de Clientes via Arquivo CSV */}
          <li className="mb-3">
            <span className="text-success me-2">📤</span>
            <strong>Importação de clientes via Arquivo CSV</strong>
            <ul className="list-unstyled ms-4">
              <li className="mb-2">
                Adicionamos a opção de importar clientes para o MSP Desk através de um arquivo CSV.
              </li>
              <li className="mb-2">
                Acesse <strong>Clientes / Importar Clientes</strong> e siga as instruções para realizar a importação.
              </li>
            </ul>
          </li>

          {/* Importação de Contatos via Arquivo CSV */}
          <li className="mb-3">
            <span className="text-success me-2">📥</span>
            <strong>Importação de contatos via Arquivo CSV</strong>
            <ul className="list-unstyled ms-4">
              <li className="mb-2">
                Agora você pode importar contatos para o MSP Desk através de um arquivo CSV.
              </li>
              <li className="mb-2">
                Acesse <strong>Contatos / Importar Contatos</strong> e siga as instruções para realizar a importação.
              </li>
            </ul>
          </li>

          {/* Melhoria na exibição de listas vazias */}
          <li className="mb-3">
            <span className="text-success me-2">🗂️</span>
            <strong>Melhoria na exibição de listas vazias:</strong>
            <ul className="list-unstyled ms-4">
              <li className="mb-2">
                Agora, quando uma lista estiver vazia, exibimos uma mensagem amigável para ajudar você a entender o que fazer a seguir.
              </li>
            </ul>
          </li>
        </ul>

        {/* Correções de Bugs */}
        <h4 className="text-danger fw-bold mb-3">Correções de Bugs</h4>
        <ul className="list-unstyled mb-4">
          <li className="mb-2">
            <span className="text-danger me-2">🐞</span>
            Correção de um erro no filtro por contato na listagem de tickets.
          </li>
          <li className="mb-2">
            <span className="text-danger me-2">🐞</span>
            Resolvido um problema que ocorria ao excluir feriados.
          </li>
          <li className="mb-2">
            <span className="text-danger me-2">🐞</span>
            Ajustes no carregamento do relatório de produtividade.
          </li>
          <li className="mb-2">
            <span className="text-danger me-2">🐞</span>
            Correção de um erro ao editar políticas de tickets agendados.
          </li>
          <li className="mb-2">
            <span className="text-danger me-2">🐞</span>
            Resolvido um problema que impedia a criação de perfis de agentes em alguns cenários.
          </li>
          <li className="mb-2">
            <span className="text-danger me-2">🐞</span>
            Correção de um erro que ocorria ao tentar excluir pré-tickets selecionados.
          </li>
          <li className="mb-2">
            <span className="text-danger me-2">🐞</span>
            Correção no botão de exportar relatório executivo de clientes.
          </li>
        </ul>

        <hr className="my-4" />

        <p className="text-center mt-4 fw-bold">
          Gostou das novidades? Aproveite essas melhorias e fique atento, pois temos muito mais em desenvolvimento.
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default NewReleaseModal;