import BlotFormatter from 'quill-blot-formatter';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './style.css';

Quill.register('modules/blotFormatter', BlotFormatter);

const EmailEditor = forwardRef((props, ref) => {
  const [editorHtml, setEditorHtml] = useState('');
  // Create a ref to store the Quill instance
  const [quillInstance, setQuillInstance] = useState(null);

  // Set up the toolbar accessibility improvements
  useEffect(() => {
    const toolbar = document.querySelector('.ql-toolbar');
    if (toolbar) {
      toolbar.setAttribute('tabIndex', -1);
      toolbar.querySelectorAll('button').forEach((item) => {
        item.setAttribute('tabIndex', -1);
      });
    }
  }, []);

  // Handle initial data loading
  useEffect(() => {
    if (props.data) {
      setEditorHtml(props.data);
    }
  }, [props.data]);

  // Expose methods through the ref for external components to use
  useImperativeHandle(ref, () => ({
    // Method to insert content at current cursor position
    insertContent: (content) => {
      if (quillInstance) {
        const range = quillInstance.getSelection(true);
        quillInstance.insertText(range.index, content);
        // Move cursor after inserted content
        quillInstance.setSelection(range.index + content.length);
      }
    },
    // Method to get current cursor position
    getSelection: () => {
      return quillInstance?.getSelection();
    },
    // Method to focus the editor
    focus: () => {
      quillInstance?.focus();
    },
    // Get the Quill instance directly if needed
    getQuill: () => quillInstance
  }));

  const handleChange = (html) => {
    setEditorHtml(html);
    props.value?.(html);
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false,
    },
    blotFormatter: {}
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
  ];

  return (
    <ReactQuill
      className='mb-3'
      formats={formats}
      theme={props.theme}
      onChange={handleChange}
      onFocus={e => props.onFocus(e)}
      value={editorHtml}
      modules={modules}
      bounds={'.quill'}
      placeholder="Insira uma descrição"
      ref={(el) => {
        // Store the Quill instance when the editor is mounted
        if (el) {
          setQuillInstance(el.getEditor());
        }
      }}
    />
  );
});

// Add display name for React DevTools
EmailEditor.displayName = 'EmailEditor';

export default EmailEditor;