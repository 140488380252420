import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import EmptyList from 'components/common/EmptyList'; // Importe o EmptyList
import DialogModal from 'components/modals/DialogModal';
import FailedRequest from 'components/requests-response/FailedRequest';
import SuccessRequest from 'components/requests-response/SuccessRequest';
import useApi from 'helpers/api';
import useAdvanceTable from 'hooks/useAdvanceTable';
import List from 'list.js';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Button } from 'react-bootstrap';

const TicketAttachments = forwardRef(({ ticketId, ticketStatus, refreshLogs }, ref) => {
  const api = useApi();
  const [attachments, setAttachments] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [error, setError] = useState({});
  const [selectedAttachment, setSelectedAttachment] = useState({});
  const [showDialogConfirm, setShowDialogConfirm] = useState(false);
  const options = {
    valueNames: ['fileName', 'contentType', 'size'],
    item: '<tr><td className="fileName"></td><td className="contentType"></td><td className="size"></td></tr>'
  };
  const attachmentList = new List('attachments', options);
  const hasTicketId = ticketId != undefined;
  const isAppointmentEmpty = Object.keys(selectedAttachment).length === 0;
  const isEmpty = hasTicketId && attachments && attachments.length === 0;

  useEffect(() => {
    getData();
  }, [ticketId]);

  useImperativeHandle(ref, () => ({
    getFiles: () => getData()
  }));

  const getData = async () => {
    if (hasTicketId) {
      let response = await api.ticketFiles.list(ticketId);
      setAttachments(response);
    }
  };

  const deleteFile = async response => {
    if (response && hasTicketId) {
      let request = await api.ticketFiles.delete(ticketId, selectedAttachment.id);
      if (request.ok) {
        getData();
        refreshLogs();
        setShowAlert(true);
      } else {
        setError(request);
        setShowAlertError(true);
      }
      handleCloseDialog();
    }
  };

  const handleDelete = item => {
    setSelectedAttachment(item);
    setShowDialogConfirm(true);
  };

  const handleCloseDialog = () => {
    setShowDialogConfirm(false);
  };

  const columns = [
    {
      accessorKey: 'fileName',
      header: 'Nome',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'contentType',
      header: 'Tipo do arquivo',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'size',
      header: 'Tamanho (bytes)',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'actions',
      header: 'Ações',
      meta: {
        headerProps: { className: 'text-900' }
      },
      cell: rowData => {
        const item = rowData.row.original;
        return (
          <Flex justifyContent="center" alignItems="center">
            <a href={item.url} target="_blank" className='p-0 me-2 d-flex align-items-center'>
              <FontAwesomeIcon icon={faEye} size="md" />
            </a>
            <Button
              variant='link'
              size='sm'
              className='p-0'
              onClick={() => handleDelete(item)}
              disabled={ticketStatus === 'COMPLETED' || ticketStatus === 'CLOSED'}>
              <FontAwesomeIcon icon={faTrash} size="sm" />
            </Button>
          </Flex>
        );
      }
    }
  ];

  const table = useAdvanceTable({
    data: attachments,
    columns
  });

  return (
    <>
      <SuccessRequest message="Arquivo excluído com sucesso" showAlert={showAlert} setShowAlert={() => setShowAlert(false)} />
      <FailedRequest message={error.description} showAlert={showAlertError} setShowAlert={() => setShowAlertError(false)} />
      <div>
        <div>
          <DialogModal
            message="Tem certeza que deseja excluir este arquivo?"
            showModal={showDialogConfirm}
            handleClose={handleCloseDialog}
            response={deleteFile} />

          {isEmpty ? (
            <div style={{ minHeight: '300px' }}>
              <EmptyList imgSize={250} />
            </div>
          ) : (
            <AdvanceTableProvider {...table}>
              <AdvanceTable
                table
                headerClassName="text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs-10 mb-0 overflow-hidden'
                }}
              />
            </AdvanceTableProvider>
          )}
        </div>
      </div>
    </>
  );
});

export default TicketAttachments;