import { faEnvelopeOpenText, faEye, faShieldAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FalconComponentCard from "components/common/FalconComponentCard";
import Flex from "components/common/Flex";
import IconButton from "components/common/IconButton";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import BlacklistModal from "components/modals/BlacklistModal";
import DialogModal from "components/modals/DialogModal";
import PreticketViewOffcanvas from "components/offcanvas/PreticketViewOffcanvas";
import TicketRegistrationOffcanvas from 'components/offcanvas/TicketRegistrationOffcanvas';
import Pagination from "components/pagination";
import FailedRequest from "components/requests-response/FailedRequest";
import SuccessRequest from "components/requests-response/SuccessRequest";
import useApi from 'helpers/api';
import { formatDateTime } from "helpers/utils";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider, { useAdvanceTableContext } from "providers/AdvanceTableProvider";
import { useEffect, useState } from "react";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import BlackListService from "services/BlackList.service";
import PreTicketService from "services/PreTickets.service";

const ListPreTickets = () => {
  const api = useApi();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [showBlacklist, setShowBlacklist] = useState(false);
  const [showTicketOffcanvas, setShowTicketOffcanvas] = useState(false);
  const [showPreticketView, setShowPreticketView] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [showDialogConfirm, setShowDialogConfirm] = useState(false);
  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const [showDialogDeleteAll, setShowDialogDeleteAll] = useState(false);
  const [showDialogBatchDelete, setShowDialogBatchDelete] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);

  const [selectedPreTickets, setSelectedPreTickets] = useState([]);
  const [dataFromPreticket, setDataFromPreticket] = useState({});
  const [preticket, setPreticket] = useState({});
  const [error, setError] = useState({ id: '' });
  const [success, setSuccess] = useState('');
  const [preTickets, setPretickets] = useState({ content: [] });
  const [sender, setSender] = useState({ sender: '' });
  const [filters, setFilters] = useState({
    page: 0,
    size: 20
  });
  const queryParams = new URLSearchParams(filters).toString();

  const getData = async () => {
    setIsLoading(true);
    const response = await PreTicketService.list(queryParams);
    setPretickets(response);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, [filters]);

  const columns = [
    {
      accessorKey: 'emailboxEmail',
      header: 'Caixa postal',
      meta: {
        headerProps: { className: 'text-900' }
      },
    },
    {
      accessorKey: 'sender',
      header: 'Remetente',
      meta: {
        headerProps: { className: 'text-900' }
      },
    },
    {
      accessorKey: 'subject',
      header: 'Assunto',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const { subject } = rowData.row.original;
        return (
          <div style={{ width: 250, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {subject}
          </div>
        );
      }
    },
    {
      accessorKey: 'description',
      header: 'Descrição',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const { description } = rowData.row.original;
        return (
          <div className="rich-text-content" style={{ width: 80, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {description}
          </div>
        );
      }
    },
    {
      accessorKey: 'creationDate',
      header: 'Data',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const { creationDate } = rowData.row.original;
        return (
          <span>
            {formatDateTime(creationDate)}
          </span>
        );
      }
    },
    {
      accessorKey: 'actions',
      header: 'Ações',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const item = rowData.row.original;
        return (
          <>
            <OverlayTrigger overlay={<Tooltip>Ver detalhes</Tooltip>}>
              <Button variant="link" className="px-1" size="sm" onClick={() => handleViewPreTicket(item)}>
                <FontAwesomeIcon icon={faEye} size="sm" />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger overlay={<Tooltip>Bloquear remetente</Tooltip>}>
              <Button className="px-1" variant="link" size="sm" onClick={() => handleBlockSender(item.sender)}>
                <FontAwesomeIcon icon={faShieldAlt} size="sm" />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger overlay={<Tooltip>Transformar em ticket</Tooltip>}>
              <Button className="px-1" variant="link" size="sm" onClick={() => handleOpenTicket(item, item.id)}>
                <FontAwesomeIcon icon={faEnvelopeOpenText} size="sm" />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger overlay={<Tooltip>Excluir</Tooltip>}>
              <Button className="px-1" variant="link" size="sm" onClick={() => handleDeletePreTicket(item)}>
                <FontAwesomeIcon icon={faTrash} size="sm" />
              </Button>
            </OverlayTrigger>

          </>
        );
      }
    }
  ];

  const table = useAdvanceTable({
    data: preTickets.content,
    columns,
    selection: true
  });

  const blockSender = async response => {
    if (response) {
      let response = await BlackListService.add(sender);
      if (response.status) {
        setError(response);
        setShowAlertError(true);
      } else {
        setSuccess("E-mail bloqueado com sucesso");
        setShowDialogConfirm(false);
        setShowAlert(true);
      }
    }
  };

  const deletePreTicket = async response => {
    if (response) {
      let request = await PreTicketService.delete(preticket.id);
      if (request.ok) {
        getData();
        setSuccess("Pré-ticket excluído com sucesso");
        setShowDialogDelete(false);
        setShowAlert(true);
      } else {
        setError(request);
        setShowAlertError(true);
      }
    }
  };

  const deleteAll = async response => {
    if (response) {
      let request = await PreTicketService.deleteAll();
      if (request.ok) {
        getData();
        setSuccess("Pré-tickets excluídos com sucesso");
        setShowDialogDeleteAll(false);
        setShowAlert(true);
      } else {
        setError(request);
        setShowAlertError(true);
      }
    }
  };

  const batchDelete = async response => {
    if (response) {
      if (selectedPreTickets.length == 0) {
        setError({ description: "Nenhum item selecionado" });
        setShowAlertError(true);
        setShowDialogBatchDelete(false);
      } else {
        let request = await PreTicketService.batchDelete({ preTickets: selectedPreTickets });
        if (request.ok) {
          getData();
          setSuccess("Pré-tickets excluídos com sucesso");
          setShowDialogBatchDelete(false);
          setShowAlert(true);

          setIsSuccessful(true);
        } else {
          setError(request);
          setShowAlertError(true);
          setIsSuccessful(false);
        }
      }
    }
  };

  const handleViewPreTicket = item => {
    setPreticket(item);
    setShowPreticketView(true);
  };

  const handleBlockSender = sender => {
    setSender({ sender: sender });
    setShowDialogConfirm(true);
  };

  const handleOpenTicket = async (item, id) => {
    setPreticket(item);
    let response = await api.tickets.getDataFromPreticket(id);
    setDataFromPreticket(response);
    setShowTicketOffcanvas(true);
  };

  const handleDeletePreTicket = item => {
    setPreticket(item);
    setShowDialogDelete(true);
  };


  return (
    <>
      <TicketRegistrationOffcanvas
        show={showTicketOffcanvas}
        handleClose={() => setShowTicketOffcanvas(false)}
        preTicketData={dataFromPreticket}
        preTicketId={preticket.id}
        refreshPretickets={() => getData()} />
      <BlacklistModal show={showBlacklist} handleClose={() => setShowBlacklist(false)} />
      <PreticketViewOffcanvas
        data={preticket}
        show={showPreticketView}
        handleClose={() => setShowPreticketView(false)} />
      <DialogModal
        message="Tem certeza que deseja bloquear este remetente?"
        showModal={showDialogConfirm}
        handleClose={() => setShowDialogConfirm(false)}
        response={blockSender} />
      <DialogModal
        message="Tem certeza que deseja excluir este pré-ticket?"
        showModal={showDialogDelete}
        handleClose={() => setShowDialogDelete(false)}
        response={deletePreTicket} />
      <DialogModal
        message="Tem certeza que deseja excluir todos os pré-tickets?"
        showModal={showDialogDeleteAll}
        handleClose={() => setShowDialogDeleteAll(false)}
        response={deleteAll} />
      <DialogModal
        message="Tem certeza que deseja excluir os pré-tickets selecionados?"
        showModal={showDialogBatchDelete}
        handleClose={() => setShowDialogBatchDelete(false)}
        response={batchDelete} />
      <SuccessRequest message={success} showAlert={showAlert} setShowAlert={() => setShowAlert(false)} />
      <FailedRequest message={error.description} showAlert={showAlertError} setShowAlert={() => setShowAlertError(false)} />
      <FalconComponentCard>
        <Flex justifyContent="between" alignItems="center" className="w-100 p-3">
          <Flex alignItems="center">
            <IconButton
              onClick={() => navigate(-1)}
              variant="falcon-default"
              size="sm"
              icon="arrow-left"
              className="me-3"
            />

            <h3 className="m-0">Pré-tickets</h3>
          </Flex>
          <Flex alignItems="center">
            <Button variant="dark" size="sm" className="me-1" onClick={() => setShowBlacklist(true)}>
              Blacklist
            </Button>
            <Button
              variant="falcon-default"
              size="sm"
              className="me-1"
              disabled={selectedPreTickets.length == 0}
              onClick={() => setShowDialogBatchDelete(true)}>
              Remover selecionados
            </Button>
            <Button variant="falcon-default" size="sm" onClick={() => setShowDialogDeleteAll(true)}>
              Remover todos
            </Button>
          </Flex>
        </Flex>
        <FalconComponentCard.Body>
          {isLoading ?
            <Skeleton height={53} count={10} />
            :
            <>
              <AdvanceTableProvider {...table}>
                <BulAction setSelectedPreTickets={setSelectedPreTickets} isSuccessful={isSuccessful} />
                <AdvanceTable
                  table
                  headerClassName="text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    striped: true,
                    className: 'fs-10 mb-0 overflow-hidden'
                  }} />
              </AdvanceTableProvider>

              <Pagination
                data={preTickets}
                filters={filters}
                setFilters={setFilters} />
            </>
          }
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

function BulAction({ setSelectedPreTickets, isSuccessful }) {
  const { getSelectedRowModel, resetRowSelection } = useAdvanceTableContext();
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setSelected(getSelectedRowModel().rows);
  }, [getSelectedRowModel()]);

  useEffect(() => {
    setSelectedPreTickets(selected.map(item => item.original.id));
  }, [selected]);

  useEffect(() => {
    if (isSuccessful) {
      setSelectedPreTickets([]);
      setSelected([]);
      resetRowSelection();
    }
  }, [isSuccessful]);

  return (
    <Row className="flex-between-center mb-3">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
          {
            Object.keys(getSelectedRowModel().rows).length > 0 &&
            Object.keys(getSelectedRowModel().rows).length + `${Object.keys(getSelectedRowModel().rows).length == 1 ? ' linha selecionada' : ' linhas selecionadas'}`
          }
        </h5>
      </Col>
    </Row>
  );
};

export default ListPreTickets;