import useApi from 'helpers/api';
import { useEffect, useState } from "react";
import { Accordion, Button, Modal } from "react-bootstrap";
import ProfilesService from 'services/Profiles.service';

const Permission = ({ data, onToggle, isChecked }) => {
  return (
    <div className="d-flex form-check">
      <input
        type="checkbox"
        className="form-check-input"
        key={data.id}
        checked={isChecked}
        onChange={onToggle} />
      <label className="mx-2">{data.description}</label>
    </div>
  );
};

const Submodule = ({ data, selectedPermissions, togglePermission }) => {
  return (
    <Accordion>
      <Accordion.Item eventKey={data.id} className="border border-0">
        <Accordion.Header>{data.name}</Accordion.Header>
        <Accordion.Body>
          {data.permissions.map((item, i) => (
            <Permission
              data={item}
              onToggle={() => togglePermission(item)}
              key={item.name}
              isChecked={selectedPermissions.includes(item.id)} />
          ))}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

const Module = ({ data, selectedPermissions, togglePermission }) => {
  return (
    <Accordion>
      <Accordion.Item eventKey={data.id} className="border border-0">
        <Accordion.Header>{data.name}</Accordion.Header>
        <Accordion.Body>
          {data.subModules.map((item, i) => (
            <Submodule
              data={item}
              key={`${data.id}-${i}`}
              selectedPermissions={selectedPermissions}
              togglePermission={togglePermission} />
          ))}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

const ProfilePermissionModal = ({ data, showModal, handleClose, handleSubmit }) => {
  const api = useApi();
  const [permissions, setPermissions] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState({});
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [permissionsToAdd, setPermissionsToAdd] = useState({ permissions: [] });
  const [permissionsToRemove, setPermissionsToRemove] = useState({ permissions: [] });

  const getPermissions = async () => {
    let response = await ProfilesService.listPermissions(selectedProfile.id);
    setPermissions(response);
  };

  const togglePermission = item => {
    if (selectedPermissions.includes(item.id)) {
      setSelectedPermissions(selectedPermissions.filter(id => id !== item.id));
      if (item.associated) { setPermissionsToRemove({ permissions: [...permissionsToRemove.permissions, parseInt(item.id)] }); }
      setPermissionsToAdd({ permissions: permissionsToAdd.permissions.filter(id => id !== item.id) });
    } else {
      setSelectedPermissions([...selectedPermissions, item.id]);
      if (!item.associated) { setPermissionsToAdd({ permissions: [...permissionsToAdd.permissions, parseInt(item.id)] }); }
      setPermissionsToRemove({ permissions: permissionsToRemove.permissions.filter(id => id !== item.id) });
    }
  };

  useEffect(() => {
    permissions.forEach(item => {
      item.subModules.forEach(subModule => {
        const filteredPermissions = subModule.permissions.filter(p => p.associated);
        const permissionIds = filteredPermissions.map(p => p.id);
        setSelectedPermissions(prevPermissions => [...prevPermissions, ...permissionIds]);
      });
    });
  }, [permissions]);

  useEffect(() => {
    if (Object.keys(data).length != 0) {
      setSelectedProfile(data);
      setSelectedPermissions([]);
      setPermissionsToAdd({ permissions: [] });
      setPermissionsToRemove({ permissions: [] });
    }
  }, [data]);

  useEffect(() => {
    if (selectedProfile.id != undefined) {
      getPermissions();
    }
  }, [selectedProfile]);

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Body>
        {permissions.map((item, i) => (
          <Module data={item} key={i} selectedPermissions={selectedPermissions} togglePermission={togglePermission} />
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="falcon-default" onClick={handleClose}>
          Fechar
        </Button>
        <Button variant="primary" onClick={() => handleSubmit(permissionsToAdd, permissionsToRemove)}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProfilePermissionModal;