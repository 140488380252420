import FalconComponentCard from "components/common/FalconComponentCard";
import Flex from "components/common/Flex";
import IconButton from "components/common/IconButton";
import MultiSelect from "components/common/MultiSelect";
import FailedRequest from "components/requests-response/FailedRequest";
import SuccessRequest from "components/requests-response/SuccessRequest";
import useApi from 'helpers/api';
import { useEffect, useRef, useState } from "react";
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import EmailNotificationTemplateService from "services/EmailNotificationTemplate.service";
import PlaceholderService from "services/Placeholder.service";
import EmailEditor from "./EmailNotificationEditor";

const EmailNotificationEdit = () => {
  const { id } = useParams();
  const api = useApi();
  const navigate = useNavigate();
  const editorRef = useRef(null);

  const [body, setBody] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState({});
  const [focusedInput, setFocusedInput] = useState(null);
  const [placeholders, setPlaceholders] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    getNotification();
  }, [id]);

  useEffect(() => {
    getUsers();
    getPlaceholders();
  }, []);

  useEffect(() => {
    console.log(body);
  }, [body]);

  useEffect(() => {
    selectedUsers.length > 0
      ? setBody({ ...body, agents: selectedUsers.map(item => item.value) })
      : setBody({ ...body, agents: [] });
  }, [selectedUsers]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.ctrlKey && e.code === "Space") {
        e.preventDefault();
        setShowDropdown(!showDropdown);
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [showDropdown]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await EmailNotificationTemplateService.edit(id, body);
    if (response.id) {
      setBody(response);
      setShowSuccess(true);
      handleAgents(response);
    } else {
      setError(response);
      setShowError(true);
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setBody({ ...body, [name]: value });
  };

  const getNotification = async () => {
    const response = await EmailNotificationTemplateService.find(id);
    if (response.id) {
      const formattedBody = {
        ...response,
        agents: response.agents.map(agent => agent.id)
      };
      setBody(formattedBody);
      handleAgents(response);
    }
  };

  const getPlaceholders = async () => {
    const response = await PlaceholderService.list();
    setPlaceholders(response);
  };

  const getUsers = async () => {
    const response = await api.agents.listSummaryData();
    if (response.length > 0) {
      const formattedUsers = response.map(user => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName || ''}`.trim(),
      }));
      setUsers(formattedUsers);
    }
  };

  const handleAgents = (response) => {
    if (response.agents.length > 0) {
      const selected = response.agents.map(agent => ({
        value: agent.id,
        label: `${agent.firstName} ${agent.lastName || ''}`.trim(),
      }));
      setSelectedUsers(selected);
    }
  };

  const handleInputFocus = (event) => {
    setFocusedInput(event.target);
  };

  const handleInsertPlaceholder = (placeholderKey) => {
    const placeholder = `{{${placeholderKey}}}`;

    if (focusedInput && focusedInput.name === "subjectTemplate") {
      const start = focusedInput.selectionStart;
      const end = focusedInput.selectionEnd;
      const value = focusedInput.value;

      const newValue =
        value.substring(0, start) + placeholder + value.substring(end);

      focusedInput.value = newValue;

      focusedInput.focus();
      focusedInput.setSelectionRange(
        start + placeholder.length,
        start + placeholder.length
      );

      setBody({ ...body, subjectTemplate: newValue });
    } else {
      const currentDescription = body.descriptionTemplate || "";
      const newDescription = currentDescription + placeholder;

      setBody({ ...body, descriptionTemplate: newDescription });
    }
    setShowDropdown(true);
  };

  return (
    <>
      <SuccessRequest
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)}
        message="Template de notificação editado com sucesso"
      />
      <FailedRequest
        showAlert={showError}
        setShowAlert={() => setShowError(false)}
        message={error.userMessage}
      />
      <Row className="g-2">
        <Col xl={12}>
          <FalconComponentCard>
            <Col>
              <Flex justifyContent={"between"} alignItems={"center"}>
                <Flex alignItems={"center"}>
                  <IconButton
                    onClick={() => navigate(-1)}
                    variant="falcon-default"
                    size="sm"
                    icon="arrow-left"
                    className="me-1 ms-3"
                  />
                  <FalconComponentCard.Header className="py-3 px-1" title={`Notificação de ${body.recipientType === 'AGENT' ? 'Agente' : 'Solicitante'} / ${body.name}`} />
                </Flex>

                <Flex alignItems={"center"} className={"me-2"}>
                  <Form.Label className="mb-0 me-2">Notificar</Form.Label>
                  <Form.Check
                    type="switch"
                    checked={!body.inactive}
                    onChange={() => setBody({ ...body, inactive: !body.inactive })} />
                </Flex>
              </Flex>
            </Col>
            <FalconComponentCard.Body>
              <Form onSubmit={handleSubmit} className="pb-2">
                <Form.Group className="mb-3" controlId="subject">
                  <Col>
                    <Flex justifyContent={"between"}>
                      <Form.Label>
                        Assunto <span className="text-danger"> *</span>
                      </Form.Label>
                      <Dropdown show={showDropdown} onToggle={(isOpen) => setShowDropdown(isOpen)}>
                        <Dropdown.Toggle
                          size="sm"
                          variant="falcon-default"
                          id="placeholder-dropdown"
                          className="rounded-1"
                        >
                          Inserir Espaços Reservados
                        </Dropdown.Toggle>
                        <Dropdown.Menu autoClose="outside">
                          {Object.entries(placeholders).map(([groupName, items]) => (
                            <div key={groupName}>
                              <Dropdown.Header>{groupName}</Dropdown.Header>
                              {Object.entries(items).map(([key, label]) => (
                                <Dropdown.Item
                                  key={key}
                                  onClick={() => handleInsertPlaceholder(key)}
                                >
                                  <strong>{label}</strong> - {`{{${key}}}`}
                                </Dropdown.Item>
                              ))}
                            </div>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Flex>
                  </Col>
                  <Form.Control
                    type="text"
                    placeholder="Assunto"
                    name="subjectTemplate"
                    value={body.subjectTemplate}
                    onChange={handleChange}
                    onFocus={handleInputFocus}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-2" controlId="message">
                  <Form.Label className="mb-1 fs-10">
                    Descrição <span className="text-danger"> *</span>
                  </Form.Label>
                  <EmailEditor
                    theme="snow"
                    onFocus={handleInputFocus}
                    ref={editorRef}
                    data={body.descriptionTemplate}
                    value={(value) => setBody({ ...body, descriptionTemplate: value })}
                  />
                </Form.Group>
                <Row className="align-items-end">
                  {body.recipientType === 'AGENT' ? (
                    <Col md={6}>
                      <Form.Group controlId="people">
                        <Form.Label>Notificar Agentes</Form.Label>
                        <MultiSelect
                          placeholder="Selecione os Agentes"
                          options={users}
                          value={selectedUsers}
                          onChange={(values) => setSelectedUsers(values)}
                        />
                      </Form.Group>
                    </Col>
                  ) : (<Col md={6} />)}
                  <Col md={6}>
                    <Flex justifyContent="end" className="gap-2">
                      <Button size="sm" variant="falcon-default">Cancelar</Button>
                      <Button type="submit" size="sm">Salvar</Button>
                    </Flex>
                  </Col>
                </Row>
              </Form>
            </FalconComponentCard.Body>
          </FalconComponentCard>
        </Col>
      </Row>
    </>
  );
};

export default EmailNotificationEdit;