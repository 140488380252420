import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import TreeSelect from 'components/common/TreeSelect';
import DialogModal from 'components/modals/DialogModal';
import FailedRequest from 'components/requests-response/FailedRequest';
import SuccessRequest from 'components/requests-response/SuccessRequest';
import useApi from 'helpers/api';
import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import ServiceCatalogService from 'services/ServiceCatalog.service';
import TypeTicketsService from 'services/TypeTickets.service';

const DattoTicketSettings = ({ settings, setSettings }) => {
  const api = useApi();

  const inputContact = useRef(null);
  const queryParams = new URLSearchParams(filters).toString();

  const [showMenu, setShowMenu] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const [filters, setFilters] = useState({
    page: 0,
    size: 1000,
    includeInactives: false,
    name: ''
  });

  const [body, setBody] = useState([]);

  const [contacts, setContacts] = useState([]);
  const [typesTicket, setTypesTicket] = useState({ content: [] });
  const [agents, setAgents] = useState([]);
  const [serviceGroups, setServiceGroups] = useState({ content: [] });
  const [serviceCatalogs, setServiceCatalogs] = useState([]);
  const [ticketOrigins, setTicketOrigins] = useState([]);
  const [dattoMonitoringTypes, setDattoMonitoringTypes] = useState([]);
  const [idsToDelete, setIdsToDelete] = useState([]);
  const [validIds, setValidIds] = useState([]);
  const [applyToAllRows, setApplyToAllRows] = useState({
    serviceGroup: { id: 0 },
    serviceCatalog: { id: 0 },
    category: { id: 0 },
    subcategory: { id: 0 },
    typeTicket: { id: 0 },
    user: { id: 0 }
  });

  useEffect(() => {
    if (settings.contactCustomer?.name) {
      setFilters({ ...filters, name: settings.contactCustomer?.name });
    }
  }, [settings]);

  useEffect(() => {
    getContacts();
  }, [filters.name]);

  useEffect(() => {
    getTicketOrigins();
    getDattoMonitorMapping();
    getServiceGroups();
    getTypesTicket();
    getAgents();
    getServiceCatalogs();
  }, []);

  useEffect(() => {
    const mappings = dattoMonitoringTypes.map(mapping => ({
      id: mapping.id,
      dattoMonitorType: mapping.dattoMonitorType?.dattoMonitoringType,
      dattoMonitorTitle: mapping.dattoMonitorType?.description,
      serviceGroup: mapping.serviceGroup || null,
      serviceCatalog: mapping.serviceCatalog || null,
      category: mapping.category || null,
      subcategory: mapping.subcategory || null,
      typeTicket: mapping.typeTicket || null,
      user: mapping.user || null
    }));

    setValidIds(dattoMonitoringTypes.filter(item => item.id != null).map(item => item.id));
    setBody(mappings);
  }, [dattoMonitoringTypes]);

  const editSettings = async () => {
    const response = await api.apiCredentials.dattoRMM.settings.edit(settings);
    handleRequest(response);
  };

  const getContacts = async () => {
    if (filters.name.length > 2) {
      const queryParams = new URLSearchParams(filters);
      const response = await api.contacts.list(queryParams);
      response.content && setContacts(response.content);
    } else if (filters.name.length === 0) {
      setContacts([]);
    }
  };

  const getTicketOrigins = async () => {
    const response = await api.ticketOrigins.list();

    if (Array.isArray(response)) {
      setTicketOrigins(response);
    } else {
      setError(response.userMessage);
      setShowError(true);
    }
  };

  const getDattoMonitorMapping = async () => {
    const response = await api.dattoMonitorMapping.list();

    if (Array.isArray(response)) {
      setDattoMonitoringTypes(response);
    } else {
      setError(response.userMessage);
      setShowError(true);
    }
  };

  const getTypesTicket = async () => {
    let response = await TypeTicketsService.list(queryParams);
    if (response.content) setTypesTicket(response);
  };
  const getServiceGroups = async () => {
    let response = await api.serviceGroups.list(queryParams);
    if (response.content) setServiceGroups(response);
  };
  const getAgents = async () => {
    const response = await api.agents.listSummaryData();
    setAgents(response);
  };
  const getServiceCatalogs = async () => {
    const response = await ServiceCatalogService.listInTreeView();
    setServiceCatalogs(response);
  };

  const handleRequest = (request) => {
    if (request.id) {
      setSuccess("Configurações alteradas com sucesso");
      setShowSuccess(true);
      setSettings(request);
    } else {
      setError(request.userMessage);
      setShowError(true);
    }
  };

  const handleChangeBody = (e, index) => {
    const { name, value } = e.target;

    setBody(prevBody =>
      prevBody.map((item, i) => i == index
        ? {
          ...item,
          [name]: value !== '' ? { id: parseInt(value, 10) } : null
        }
        : item
      )
    );
  };

  const handleChangeApplyToAllRows = (e) => {
    const { name, value } = e.target;
    setApplyToAllRows({ ...applyToAllRows, [name]: { id: parseInt(value) } });
  };

  const handleServiceCatalogApplyToAllRows = ids => {
    setApplyToAllRows({
      ...applyToAllRows,
      serviceCatalog: { id: parseInt(ids[0]) },
      category: { id: parseInt(ids[1]) },
      subcategory: { id: parseInt(ids[2]) }
    });
  };

  const handleSelectServiceCatalog = (ids, index) => {
    setBody(prevBody =>
      prevBody.map((item, i) => i == index
        ? {
          ...item,
          serviceCatalog: ids[0] !== '' ? { id: parseInt(ids[0], 10) } : null,
          category: ids[1] !== '' ? { id: parseInt(ids[1], 10) } : null,
          subcategory: ids[2] !== '' ? { id: parseInt(ids[2], 10) } : null
        }
        : item
      )
    );
  };

  const addIdToDelete = id => {
    if (id) {
      setIdsToDelete(prevIds => prevIds.includes(id)
        ? prevIds.filter(existingId => existingId !== id)
        : [...prevIds, id]
      );
    }
  };

  const addAllIdsToDelete = () => {
    if (idsToDelete.length === validIds.length) {
      setIdsToDelete([]);
    } else {
      const ids = body.map(item => item.id).filter(id => id);
      setIdsToDelete(ids);
    }
  };

  const saveMappings = async () => {
    const validMappings = body.filter(item =>
      item.serviceGroup?.id &&
      item.serviceCatalog?.id &&
      item.category?.id &&
      item.subcategory?.id &&
      item.typeTicket?.id &&
      item.user?.id
    );

    const response = await api.dattoMonitorMapping.batchMap(validMappings);

    if (response.ok) {
      setSuccess("Mapeamento(s) adicionado(s) com sucesso");
      setShowSuccess(true);

      getDattoMonitorMapping();
    } else {
      setError(request.userMessage);
      setShowError(true);
    }
  };

  const deleteMappings = async response => {
    if (response) {
      const response = await api.dattoMonitorMapping.batchUnmap(idsToDelete);

      if (response.ok) {
        setSuccess("Mapeamento(s) removido(s) com sucesso");
        setShowSuccess(true);

        getDattoMonitorMapping();

        // Resetar a lista de IDs selecionados
        setIdsToDelete([]);
      } else {
        setError(request.userMessage);
        setShowError(true);
      }
      setShowDialog(false);
    }
  };

  const apply = () => {
    setBody(prevBody => prevBody.map(item => ({
      ...item,
      serviceGroup: applyToAllRows.serviceGroup,
      serviceCatalog: applyToAllRows.serviceCatalog,
      category: applyToAllRows.category,
      subcategory: applyToAllRows.subcategory,
      typeTicket: applyToAllRows.typeTicket,
      user: applyToAllRows.user,
    })));
  };

  return (
    <>
      <SuccessRequest
        message={success}
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)} />
      <FailedRequest
        message={error}
        showAlert={showError}
        setShowAlert={() => setShowError(false)} />
      <DialogModal
        message="Tem certeza que deseja remover os mapeamentos existentes?"
        showModal={showDialog}
        handleClose={() => setShowDialog(false)}
        response={deleteMappings} />

      <Form>
        <p className="fs-9 fw-bold mb-3">Configurações de Ticket</p>
        <Form.Group as={Flex} alignItems="center" className="mb-2">
          <Form.Check
            type="switch"
            value={settings?.resolveTicketOnAlertResolve}
            onChange={() => setSettings({ ...settings, resolveTicketOnAlertResolve: !settings.resolveTicketOnAlertResolve })}
            checked={settings.resolveTicketOnAlertResolve}
          />
          <Form.Label className="m-0">Resolver ticket quando o alerta for resolvido</Form.Label>
        </Form.Group>

        <Form.Group as={Flex} alignItems="center" className="mb-2">
          <Form.Check
            type="switch"
            value={settings?.resolveAlertOnTicketResolve}
            onChange={() => setSettings({ ...settings, resolveAlertOnTicketResolve: !settings.resolveAlertOnTicketResolve })}
            checked={settings.resolveAlertOnTicketResolve}
          />
          <Form.Label className="m-0">Resolver alerta quando o ticket for resolvido</Form.Label>
        </Form.Group>
        <Row className='mb-3'>
          <Form.Group as={Col} className="d-flex">
            <Form.Check
              type="switch"
              value={settings?.alertGrouping}
              onChange={() => setSettings({ ...settings, alertGrouping: !settings.alertGrouping })}
              checked={settings.alertGrouping}
            />
            <Form.Label className="m-0">Agrupar alertas relacionados ao mesmo problema e dispositivo em um único ticket.</Form.Label>
          </Form.Group>

          <Form.Group as={Col} className='w-25'>
            <Form.Label>Disparados dentro do seguinte prazo (minutos)</Form.Label>
            <Form.Control
              type="number"
              min={1}
              size='sm'
              value={settings?.alertGroupingPeriod}
              disabled={!settings.alertGrouping}
              onChange={e => setSettings({ ...settings, alertGroupingPeriod: parseInt(e.target.value) })} />
          </Form.Group>

          <Col></Col>
        </Row>
        <Row className='mb-3'>
          <Col>
            <Form.Label className='m-0'>Origem Padrão do Ticket</Form.Label>
          </Col>

          <Col>
            <Form.Select size='sm'
              value={settings?.ticketOrigin}
              onChange={e => setSettings({ ...settings, ticketOrigin: e.target.value })}>
              <option value="" key=""></option>
              {ticketOrigins.map(item => (
                <option value={item.ticketOrigin} key={item.ticketOrigin}>{item.description}</option>
              ))}
            </Form.Select>
          </Col>

          <Col></Col>
        </Row>
        <Row className='mb-2'>
          <Col>
            <Form.Label>Contato Padrão do Ticket</Form.Label>
          </Col>

          <Col>
            <Form.Control
              type='text'
              placeholder='Digite para pesquisar'
              value={filters.name}
              ref={inputContact}
              size='sm'
              onFocus={() => setShowMenu(true)}
              onChange={e => setFilters(prevFilters => ({ ...prevFilters, name: e.target.value }))} />
            {showMenu &&
              <ul className='px-0 list-customer bg-100 shadow rounded z-3' style={{ height: 'auto', maxHeight: 350, overflowY: 'auto' }}>
                {contacts.map(item => (
                  <li
                    style={{ listStyleType: 'none', cursor: 'pointer' }}
                    className='px-3 py-2 bg-primary-hover item-list-hover'
                    data-value={item.id}
                    key={item.name + item.id}
                    onClick={() => {
                      setFilters(prevFilters => ({ ...prevFilters, name: item.name }));
                      setSettings({ ...settings, contactCustomer: { id: parseInt(item.id) } });
                      setShowMenu(false);
                    }}>
                    {item.name}
                  </li>
                ))}
              </ul>
            }
            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Selecione um cliente.</Form.Control.Feedback>
          </Col>

          <Col></Col>
        </Row>
        <Form.Group className='w-100' as={Flex} justifyContent='end'>
          <Button size="sm" onClick={editSettings}>
            <FontAwesomeIcon icon={faSave} className="me-1" size="sm" />
            Salvar
          </Button>
        </Form.Group>
      </Form>

      <hr />

      <Flex className="w-100 my-3" justifyContent="between" alignItems="center">
        <p className="fs-9 fw-bold mb-3">Mapeamento Padrão de Atributos</p>
        <Button size='sm' onClick={() => setShowDialog(true)} disabled={idsToDelete.length === 0}>
          <FontAwesomeIcon icon={faTrash} size='sm' className='me-1' />
          Remover selecionados
        </Button>
      </Flex>

      {/* apply to all rows */}
      <Table>
        <tbody>
          <tr>
            <td style={{ width: '18.5%' }}>
              <Form.Label>Aplicar a todas as linhas</Form.Label>
            </td>
            <td>
              <Form.Group className="mb-2">
                <Form.Select
                  value={applyToAllRows.serviceGroup?.id || ''}
                  required
                  name="serviceGroup"
                  onChange={handleChangeApplyToAllRows}
                >
                  <option value={''}></option>
                  {serviceGroups.content.map((item, i) => (
                    <option value={item.id} key={item.name + i}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </td>
            <td className='w-25'>
              <Form.Group className="mb-2">
                <TreeSelect
                  data={serviceCatalogs}
                  onSelect={handleServiceCatalogApplyToAllRows}
                  showPlaceholder={false}
                />
              </Form.Group>
            </td>
            <td>
              <Form.Group className="mb-2">
                <Form.Select
                  value={applyToAllRows.typeTicket.id || ''}
                  required
                  name="typeTicket"
                  onChange={handleChangeApplyToAllRows}
                >
                  <option value={''}></option>
                  {typesTicket.content.map((item, i) => (
                    <option value={item.id} key={item.name + i}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </td>
            <td>
              <Form.Group className="mb-2">
                <Form.Select
                  value={applyToAllRows.user.id || ''}
                  required
                  name="user"
                  onChange={handleChangeApplyToAllRows}
                >
                  <option value={''}></option>
                  {agents.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.firstName + ' '} {item.lastName && item.lastName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </td>
            <td>
              <Button onClick={() => apply()} variant="primary" size="sm">Aplicar</Button>
            </td>
          </tr>
        </tbody>
      </Table>

      <Table responsive>
        <thead>
          <tr>
            <th>
              <Form.Check
                type='checkbox'
                className='fs-10'
                onChange={() => addAllIdsToDelete()}
                checked={idsToDelete.length === validIds.length && validIds.length > 0} />
            </th>
            <th className="text-900 fs-10" style={{ width: '14%' }}>Tipo de Monitor</th>
            <th className="text-900 fs-10">Grupo de Serviço</th>
            <th className="text-900 fs-10">Catálogo de Serviço</th>
            <th className="text-900 fs-10">Tipo de Ticket</th>
            <th className="text-900 fs-10">Agente</th>
          </tr>
        </thead>
        <tbody>
          {body.map((item, index) => (
            <tr key={index}>
              <td>
                <Form.Check
                  type='checkbox'
                  className='fs-10'
                  onChange={() => addIdToDelete(item.id)}
                  checked={idsToDelete.includes(item.id)} />
              </td>
              <td className="fs-10">{item.dattoMonitorTitle}</td>
              <td>
                <Form.Group className="mb-2">
                  <Form.Select
                    value={item.serviceGroup?.id || ''}
                    required
                    name="serviceGroup"
                    onChange={e => handleChangeBody(e, index)}
                  >
                    <option value={''}></option>
                    {serviceGroups.content.map((item, i) => (
                      <option value={item.id} key={item.name + i}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Selecione um grupo de serviço.
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
              <td className='w-25'>
                <Form.Group className="mb-2">
                  <TreeSelect
                    data={serviceCatalogs}
                    onSelect={ids => handleSelectServiceCatalog(ids, index)}
                    showPlaceholder={false}
                    initialValue={[item.serviceCatalog?.id, item.category?.id, item.subcategory?.id]} />
                  <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Selecione um catálogo de serviço.
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
              <td>
                <Form.Group className="mb-2">
                  <Form.Select
                    value={item.typeTicket?.id || ''}
                    required
                    name="typeTicket"
                    onChange={e => handleChangeBody(e, index)}
                  >
                    <option value={''}></option>
                    {typesTicket.content.map((item, i) => (
                      <option value={item.id} key={item.name + i}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Selecione um tipo de ticket.
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
              <td>
                <Form.Group className="mb-2">
                  <Form.Select
                    value={item.user?.id || ''}
                    required
                    name="user"
                    onChange={e => handleChangeBody(e, index)}
                  >
                    <option value={''}>
                    </option>
                    {agents.map((item, i) => (
                      <option value={item.id} key={item.id}>
                        {item.firstName + ' '} {item.lastName && item.lastName}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Selecione um grupo de serivço.
                  </Form.Control.Feedback>
                </Form.Group>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Flex className="w-100" justifyContent="end">
        <Button size="sm" onClick={() => saveMappings()}>
          <FontAwesomeIcon icon={faSave} className="me-1" />
          Salvar
        </Button>
      </Flex>
    </>
  );
};

export default DattoTicketSettings;