import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Editor from "components/editor/Editor";
import FailedRequest from "components/requests-response/FailedRequest";
import SuccessRequest from "components/requests-response/SuccessRequest";
import useApi from 'helpers/api';
import { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import PauseReasonsService from "services/PauseReasons.service";

const SlaPauseModal = ({ showModal, handleClose, ticketId, refreshData }) => {
  const [body, setBody] = useState({
    description: '',
    pauseReason: { id: '' }
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [validated, setValidated] = useState(false);
  const [filters] = useState({ includeInactives: false });
  const [pauseReasons, setPauseReasons] = useState([]);
  const [error, setError] = useState({});

  const form = useRef(null);
  const api = useApi();

  useEffect(() => {
    if (showModal) {
      setValidated(false);
    }
  }, [showModal]);

  useEffect(() => {
    getPauseReasons();
  }, []);

  const getPauseReasons = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await PauseReasonsService.list(queryParams);

    response.content && setPauseReasons(response.content);
  }

  const onSubmit = async e => {
    if (form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      const response = await api.slaPauses.pause(ticketId, body);
      if (response.id) {
        setShowSuccess(true);
        refreshData();
        handleClose();
      } else {
        setError(response);
        setShowError(true);
        handleClose();
      }
      setValidated(false);
    }
  }

  return (
    <>
      <SuccessRequest
        message="SLA pausado com sucesso"
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)} />
      <FailedRequest
        message={error.userMessage}
        showAlert={showError}
        setShowAlert={() => setShowError(false)} />
      <Modal size="lg" show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Pausar SLA</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form validated={validated} ref={form}>
            <Form.Group>
              <Form.Label>Motivo de pausa <span className="text-danger">*</span></Form.Label>
              <Form.Select required value={body.pauseReason.id} onChange={e => setBody({ ...body, pauseReason: { id: e.target.value } })}>
                <option value="">Selecione</option>
                {pauseReasons.map((item, i) => (
                  <option value={item.id} key={i}>{item.name}</option>
                ))}
              </Form.Select>
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Motivo de pausa obrigatório.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="editForm.Name">
              <Form.Label>Descrição <span className="text-danger">*</span></Form.Label>
              <Editor value={value => setBody({ ...body, description: value })} />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira uma desrição.</Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="falcon-default" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            <FontAwesomeIcon icon={faSave} className="me-2" />
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>

  );
};

export default SlaPauseModal;