import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FailedRequest from "components/requests-response/FailedRequest";
import SuccessRequest from "components/requests-response/SuccessRequest";
import useApi from 'helpers/api';
import { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import PauseReasonsService from "services/PauseReasons.service";

const PauseReasonRegistrationModal = ({ showModal, handleClose, isSuccess }) => {
  const [body, setBody] = useState({
    name: ''
  });
  const [validated, setValidated] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState({});
  const [showError, setShowError] = useState(false);

  const api = useApi();
  const form = useRef(null);

  useEffect(() => {
    if (showModal) {
      setBody({ name: '' });
      setValidated(false);
    }
  }, [showModal]);

  const onSubmit = async e => {
    if (form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      setValidated(false);
      const response = await PauseReasonsService.create(body);
      if (!response.status) {
        isSuccess(true);
        setShowSuccess(true);
        setBody({ name: '' });
        handleClose();
      } else {
        setError(response);
        setShowError(true);
        isSuccess(false);
        setBody({ name: '' });
        handleClose();
      }
    }
  };

  return (
    <>
      <SuccessRequest
        message="Motivo de pausa adicionado com sucesso"
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)} />
      <FailedRequest
        message={error.userMessage}
        showAlert={showError}
        setShowAlert={() => setShowError(false)} />

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Novo motivo de pausa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form validated={validated} ref={form}>
            <Form.Group className="mb-3" controlId="pauseReason.name">
              <Form.Label>Nome <span className="text-danger">*</span></Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Nome"
                autoFocus
                value={body.name}
                onChange={e => setBody({ name: e.target.value })}
              />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira um nome.</Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            <FontAwesomeIcon icon={faSave} className="me-2" />
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PauseReasonRegistrationModal;