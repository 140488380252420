import { ProfilePermissions, ProfilesPaginated, ProfilesPaginatedContent, SubModulesPermissions } from 'types/ProfilesTypes';
import useHttp from '../helpers/HttpMethods';

class ProfilesService {
/**
   * @param {string} filter - Filtro opcional para pesquisa dos perfis de agente.
   * Ex: page=0&size=10
   * @returns {Promise<ProfilesPaginated>}
   */
static async list(filter: string): Promise<ProfilesPaginated> {
  return await useHttp().get(`/profiles?${filter}`);
}

/**
   * @param {{name: string}} body - Objeto com apenas a propriedade name que cria um
   * novo perfil de agente.
   * @returns {Promise<ProfilesPaginatedContent>}
   */
static async create(body: {name: string}): Promise<ProfilesPaginatedContent> {
  return await useHttp().post(body, `/profiles`);
}

/**
   * @param {number} id - id do item a ser alterado.
   * @param {{name: string}} body - Conteúdo a ser atualizado no perfil de agente.
   * @returns {Promise<Response | PauseReasonModel>}
   */
static async edit(id: number, body: {name: string}): Promise<Response | ProfilesPaginatedContent> {
  return await useHttp().put(body, `/profiles/${id}`);
}

/**
   * @param {number} id - id do item a ser excluído.
   * @returns {Promise<Response>}
   */
  static async delete(id: number): Promise<Response> {
    return await useHttp().del(`/profiles/${id}`);
  }

  /**
   * @param {number} id - id do item que contém as permissões.
   * @returns {Promise<ProfilePermissions>}
   */
static async listPermissions(id: number): Promise<ProfilePermissions> {
  return await useHttp().get(`/profiles/${id}/permissions`);
}

/**
   * @param {number} id - id do item a ser alterado.
   * @returns {Promise<Response | SubModulesPermissions>}
   */
static async associatePermissions(id: number, body: []): Promise<Response | SubModulesPermissions> {
  return await useHttp().put(body, `/profiles/${id}/permissions/add-batch`);
}

/**
   * @param {number} id - id do item a ser alterado.
   * @param {SubModulesPermissions} body
   * @returns {Promise<Response>}
   */
static async unassociatePermissions(id: number, body: SubModulesPermissions): Promise<Response> {
  return await useHttp().delWithBody(body, `/profiles/${id}/permissions/remove-batch`);
}
}

export default ProfilesService