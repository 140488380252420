import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useApi from 'helpers/api';
import { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const CategoryEditModal = ({ serviceCatalogId, data, showModal, handleClose, handleSubmit }) => {
  const api = useApi();
  const [body, setBody] = useState({
    name: '',
    inactive: ''
  });
  const form = useRef(null);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (Object.keys(data).length !== 0) {
      setBody(data);
      setValidated(false);
    }
  }, [data]);

  useEffect(() => {
    if (showModal) {
      setBody(data);
      setValidated(false);
    }
  }, [showModal]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (form.current.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setValidated(false);
      handleSubmit({ name: body.name, serviceCatalog: { id: serviceCatalogId }, inactive: body.inactive });
      setBody({ name: '', inactive: false });
    }
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar categoria</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form validated={validated} ref={form}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Nome <span className="text-danger">*</span></Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Nome"
              autoFocus
              value={body.name}
              onChange={(e) => setBody((prevBody) => ({ ...prevBody, name: e.target.value }))}
            />
            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Insira um nome.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Status</Form.Label>
            <Form.Check
              type="switch"
              id="status-switch"
              label={body.inactive ? "Inativo" : "Ativo"}
              checked={!body.inactive}
              onChange={() => setBody((prevBody) => ({ ...prevBody, inactive: !prevBody.inactive }))}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="falcon-default" onClick={handleClose}>
          Fechar
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          <FontAwesomeIcon icon={faSave} className="me-2" />
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CategoryEditModal;