import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import Loading from 'components/loading/Loading';
import DialogModal from 'components/modals/DialogModal';
import FailedRequest from 'components/requests-response/FailedRequest';
import SuccessRequest from 'components/requests-response/SuccessRequest';
import useApi from 'helpers/api';
import { useEffect, useRef, useState } from "react";
import CompanyLogoService from 'services/CompanyLogo.service';

const CompanyLogo = ({ company }) => {
  const api = useApi();
  const inputFile = useRef(null);
  const [logo, setLogo] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [showDialogConfirm, setShowDialogConfirm] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [error, setError] = useState({ description: '' });
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    company && getLogo();

    return () => {
      if (logo) {
        URL.revokeObjectURL(logo);
      }
    };
  }, [company]);

  useEffect(() => {
    updateLogo();
  }, [selectedFile]);

  const getLogo = async () => {
    const request = await CompanyLogoService.findById(company.id);
    if (request.ok) {
      const blob = await request.blob();
      const url = URL.createObjectURL(blob);
      setLogo(url);
    }
  };

  const updateLogo = async () => {
    setLoading(true);
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      let response = await CompanyLogoService.update(company.id, formData);
      if (response.status) {
        setError(response);
        setShowAlertError(true);
      } else {
        getLogo();
        setShowAlert(true);
        setSuccess("Logo alterada com sucesso");
        setSelectedFile(null);
      }
    }
    setLoading(false);
  };

  const deleteLogo = async response => {
    setLoading(true);
    if (response) {
      let request = await CompanyLogoService.delete(company.id);
      if (request.ok) {
        setShowDialogConfirm(false);
        setSuccess("Logo excluído com sucesso");
        setShowAlert(true);
        getLogo();
      } else {
        setError(request);
        setShowAlertError(true);
      }
    }
    setLoading(false);
  };

  return (
    <>
      {loading && <Loading />}
      <DialogModal
        message="Tem certeza que deseja excluir o logo?"
        showModal={showDialogConfirm}
        handleClose={() => setShowDialogConfirm(false)}
        response={deleteLogo} />
      <SuccessRequest
        message={success}
        showAlert={showAlert}
        setShowAlert={() => setShowAlert(false)} />
      <FailedRequest
        message={error.description}
        showAlert={showAlertError}
        setShowAlert={() => setShowAlertError(false)} />
      <Flex direction="column">
        {logo
          ? (
            <>
              <img src={logo} alt="Logo Empresa" className='mb-3' style={{ width: 400 }} />
              <span className='fs-10 mb-3'>Resolução recomendada - 540 x 200 - .png</span>
            </>
          )
          : <p>Não há logo</p>
        }
        <Flex>
          <IconButton
            variant="falcon-default"
            size="sm"
            icon="camera"
            transform="shrink-2"
            iconAlign="middle"
            className="me-2"
            onClick={() => inputFile.current.click()}
          >
            <span className="d-none d-md-inline-block ms-1">Alterar</span>
          </IconButton>
          <input
            type="file"
            ref={inputFile}
            onChange={e => setSelectedFile(e.target.files[0])}
            style={{ display: 'none' }} />

          <IconButton
            variant="falcon-default"
            size="sm"
            icon="trash"
            transform="shrink-2"
            iconAlign="middle"
            onClick={() => setShowDialogConfirm(true)}
          >
            <span className="d-none d-md-inline-block ms-1">Remover</span>
          </IconButton>
        </Flex>
      </Flex>
    </>
  );
};

export default CompanyLogo;