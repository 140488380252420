import AgentAnalysis from 'components/app/support-desk/reports/AgentAnalysis';
import IndepthServiceDesk from 'components/app/support-desk/reports/IndepthServiceDesk';
import RushDayOfWeek from 'components/app/support-desk/reports/rush-day-of-week/RushDayOfWeek';
import RushHour from 'components/app/support-desk/reports/rush-hour/RushHour';
import NumberOfTickets from 'components/charts/number-of-tickets/NumberOfTickets';
import DatePickerComponent from 'components/common/DatePickerComponent';
import Flex from 'components/common/Flex';
import MultiSelect from 'components/common/MultiSelect';
import useApi from 'helpers/api';
import { formatDateUs } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import SummaryOverview from '../SummaryOverview';

const Overview = () => {
  const api = useApi();
  const date = new Date();
  const [startDate, setStartDate] = useState(date.setDate(date.getDate() - 30));
  const [endDate, setEndDate] = useState(new Date());
  const [serviceGroups, setServiceGroups] = useState([]);
  const [selectedServiceGroups, setSelectedServiceGroups] = useState([]);
  const [formattedServiceGroups, setFormattedServiceGroups] = useState([]);
  const [filterQuery] = useState({
    size: 100,
    includeInactives: false
  });
  const [filters, setFilters] = useState({
    initialDate: formatDateUs(startDate),
    endDate: formatDateUs(endDate),
    serviceGroups: []
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getServiceGroups();
  }, []);

  useEffect(() => {
    setFormattedServiceGroups(serviceGroups.map(item => ({
      value: parseInt(item.id),
      label: item.name
    })));
  }, [serviceGroups]);

  useEffect(() => {
    setFilters({ ...filters, initialDate: formatDateUs(startDate), endDate: formatDateUs(endDate) });
  }, [startDate, endDate]);

  useEffect(() => {
    setFilters({ ...filters, serviceGroups: selectedServiceGroups.map(item => item.value) });
  }, [selectedServiceGroups]);

  const getServiceGroups = async () => {
    const queryParams = new URLSearchParams(filterQuery);
    let response = await api.serviceGroups.list(queryParams);
    if (response.content) {
      setServiceGroups(response.content);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Flex alignItems="center">
        <Form.Group className='w-25 me-3'>
          <h6 className="text-700">Período </h6>
          {isLoading ? (
            <Skeleton height={38} />
          ) : (
            <DatePickerComponent startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} applyCustomStyles={false} />
          )}
        </Form.Group>

        <Form.Group className='me-3 flex-1'>
          <h6 className="text-700">Grupos de serviço </h6>
          {isLoading ? (
            <Skeleton height={38} />
          ) : (
            <MultiSelect
              value={selectedServiceGroups}
              options={formattedServiceGroups}
              placeholder='Selecione...'
              onChange={selectedOptions => setSelectedServiceGroups(selectedOptions)} />
          )}
        </Form.Group>
      </Flex>

      {isLoading ? (
        <>
          <Skeleton height={137} className="mb-3" />
          <Skeleton height={286} className="mb-3" />
          <Skeleton height={48} className="mb-3" />
        </>
      ) : (
        <>
          <SummaryOverview filters={filters} />
          <IndepthServiceDesk filters={filters} />
          <RushHour filters={filters} />
          <AgentAnalysis filters={filters} />
          <RushDayOfWeek filters={filters} />
          <NumberOfTickets filters={filters} />
        </>
      )}
    </>
  );
};

export default Overview;