export const rootPaths = {
  registrationRoot: '/registration',
  errorsRoot: '/errors',
  authRoot: '/authentication',
  dashboardRoot: '/dashboards',
  ticketsRoot: '/tickets',
  userRoot: '/user',
  companyRoot: '/company',
  reports: '/reports',
  settings: '/settings',
  integrations: '/integrations',
  assets: '/assets',
  alerts: '/alerts'
};

export default {
  starter: '/',
  landing: '/landing',
  login: '/login',
  authorized: '/authorized',
  error403: `${rootPaths.errorsRoot}/403`,
  error404: `${rootPaths.errorsRoot}/404`,
  error500: `${rootPaths.errorsRoot}/500`,
  error403: `${rootPaths.errorsRoot}/access-denied`,
  settings: `${rootPaths.settings}`,
  emailNotifications: `${rootPaths.settings}/email-notifications`,
  editEmailNotification: `${rootPaths.settings}/email-notifications/:id/edit`,
  dashTickets: `${rootPaths.dashboardRoot}/tickets`,
  listTickets: `${rootPaths.ticketsRoot}`,
  ticketsPreview: `${rootPaths.ticketsRoot}/:id`,
  listPreTickets: `/pre-tickets`,

  // Rotas alteradas para settings
  agentRegistration: `${rootPaths.settings}/agents`,
  serviceGroupRegistration: `${rootPaths.settings}/service-groups`,
  profileRegistration: `${rootPaths.settings}/profiles`,
  serviceCatalogRegistration: `${rootPaths.settings}/services-catalog`,
  serviceCatalogDetails: `${rootPaths.settings}/services-catalog/:id`,
  typeTicketRegistration: `${rootPaths.settings}/types-ticket`,
  slaPolicyList: `${rootPaths.settings}/sla-policies`,
  slaPolicyEdit: `${rootPaths.settings}/sla-policies/:id`,
  businessHourRegistration: `${rootPaths.settings}/business-hour`,
  pauseReasonRegistration: `${rootPaths.settings}/pause-reasons`,
  scheduledTicketList: `${rootPaths.settings}/scheduleds-tickets`,
  scheduledTicketRegistration: `${rootPaths.settings}/scheduleds-tickets/new`,
  scheduledTicketEdit: `${rootPaths.settings}/scheduleds-tickets/:id`,
  assetTypeRegistration: `${rootPaths.settings}/asset-types`,
  assetTypeEdit: `${rootPaths.settings}/asset-types/:id`,
  emailBoxRegistration: `${rootPaths.settings}/mailbox`,
  holidayRegistration: `${rootPaths.settings}/holidays`,

  // Rotas mantidas em registration
  customerRegistration: `${rootPaths.registrationRoot}/customers`,
  customerDetails: `${rootPaths.registrationRoot}/customers/:id`,
  contactRegistration: `${rootPaths.registrationRoot}/contacts`,

  profileUser: `${rootPaths.userRoot}/profile`,
  myCompany: `${rootPaths.companyRoot}/my-company`,
  reportExecutiveCustomer: `${rootPaths.reports}/executive`,
  productivityAnalysis: `${rootPaths.reports}/productivity-analysis`,
  reportOverview: `${rootPaths.reports}/overview`,
  integrations: `${rootPaths.settings}/integrations`,
  dattoRMM: `${rootPaths.integrations}/datto-rmm`,
  mspTalks: `${rootPaths.integrations}/msptalks`,
  assetList: `${rootPaths.assets}`,
  assetDetails: `${rootPaths.assets}/:id`,
  assetEdit: `${rootPaths.assets}/:id/edit`,
  alertList: `${rootPaths.alerts}`
};