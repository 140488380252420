import IconButton from 'components/common/IconButton';
import useApi from 'helpers/api';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import MaskedInput from 'react-text-mask';
import CompanyService from 'services/Company.service';

const CompanyInfo = ({ data, error, success, loadingState }) => {
  const api = useApi();
  const form = useRef(null);
  const [body, setBody] = useState({});
  const [validated, setValidated] = useState(false);
  const [companyId, setCompanyId] = useState('');

  useEffect(() => {
    setBody(data);
  }, [data]);

  useEffect(() => {
    getCompanyId();
  }, []);

  const getCompanyId = async () => {
    let response = await CompanyService.getMyCompanyId();
    if (response)
      setCompanyId(response);
  };

  const handleSubmit = async e => {
    if (form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      let response = await CompanyService.edit(companyId, body);
      if (response.status) {
        error(response);
      } else {
        success(true);
        setBody(response);
      }
      setValidated(false);
    }
  };

  const handleChangeBody = (fieldName, fieldValue) => {
    setBody(prevBody => ({ ...prevBody, [fieldName]: fieldValue }));
  };

  return (
    <div className="mb-3">
      <div>
        <Row>
          <Col lg xxl={12}>
            <h6 className="fw-semi-bold ls mb-3 text-uppercase">
              Editar informações
            </h6>
            {loadingState ? (
              <>
                <Skeleton className="mb-3" height={66} baseColor="#5E6E82" />
                <Skeleton className="mb-3" height={66} baseColor="#5E6E82" />
                <Skeleton className="mb-3" height={66} baseColor="#5E6E82" />
                <Skeleton className="mb-3" height={66} baseColor="#5E6E82" />
              </>
            ) : (
              <Form validated={validated} ref={form}>
                <Row className="mb-3">
                  <Form.Group as={Col} md="3" controlId="company.cnpj">
                    <Form.Label>CNPJ</Form.Label>
                    <MaskedInput
                      mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                      guide={false}
                      required
                      type="text"
                      placeholder="CNPJ"
                      value={body.cnpj}
                      onChange={e => handleChangeBody('cnpj', e.target.value)}
                      render={(ref, props) => <Form.Control ref={ref} {...props} />}
                    />
                    <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Insira um CNPJ.</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="company.lastName">
                    <Form.Label>Razão social</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Razão social"
                      value={body.corporateName}
                      onChange={e => handleChangeBody('corporateName', e.target.value)}
                    />
                    <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Insira uma razão social.</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="3" controlId="company.lastName">
                    <Form.Label>Nome fantasia</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Nome fantasia"
                      value={body.fantasyName}
                      onChange={e => handleChangeBody('fantasyName', e.target.value)}
                    />
                    <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Insira um nome fantasia.</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="3" controlId="company.ie">
                    <Form.Label>Inscrição estadual</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Inscrição estadual"
                      value={body.stateRegistration}
                      onChange={e => handleChangeBody('stateRegistration', e.target.value)}
                    />
                    <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Insira um inscrição estadual.</Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col} md="3" controlId="company.ie">
                    <Form.Label>CEP</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="CEP"
                      value={body.addressCep}
                      onChange={e => handleChangeBody('addressCep', e.target.value)}
                    />
                    <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Insira um CEP.</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="4" controlId="company.ie">
                    <Form.Label>Logradouro</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Logradouro"
                      value={body.addressStreet}
                      onChange={e => handleChangeBody('addressStreet', e.target.value)}
                    />
                    <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Insira uma rua.</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="2" controlId="company.ie">
                    <Form.Label>Número</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Número"
                      value={body.addressNumber}
                      onChange={e => handleChangeBody('addressNumber', e.target.value)}
                    />
                    <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Insira um número.</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="3" controlId="company.ie">
                    <Form.Label>Complemento</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Complemento"
                      value={body.addressComplement}
                      onChange={e => handleChangeBody('addressComplement', e.target.value)}
                    />
                    <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Insira um complemento.</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="3" controlId="company.ie">
                    <Form.Label>UF</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="UF"
                      value={body.addressState}
                      onChange={e => handleChangeBody('addressState', e.target.value)}
                    />
                    <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Insira um estado.</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="5" controlId="company.ie">
                    <Form.Label>Bairro</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Bairro"
                      value={body.addressNeighborhood}
                      onChange={e => handleChangeBody('addressNeighborhood', e.target.value)}
                    />
                    <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Insira um bairro.</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="4" controlId="company.ie">
                    <Form.Label>Cidade</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Cidade"
                      value={body.addressCity}
                      onChange={e => handleChangeBody('addressCity', e.target.value)}
                    />
                    <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Insira um cidade.</Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col} md="6" controlId="company.ie">
                    <Form.Label>Telefone</Form.Label>
                    <MaskedInput
                      mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                      placeholder="Telefone"
                      required
                      guide={false}
                      value={body.phone}
                      onChange={e => handleChangeBody('phone', e.target.value)}
                      render={(ref, props) => (
                        <Form.Control ref={ref} {...props} />
                      )}
                    />
                    <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Insira um telefone.</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="6" controlId="company.ie">
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="E-mail"
                      value={body.email}
                      required
                      onChange={e => handleChangeBody('email', e.target.value)}
                    />
                    <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Insira um e-mail.</Form.Control.Feedback>
                  </Form.Group>
                </Row>
              </Form>
            )}
          </Col>
        </Row>
      </div>
      <footer className="border-top text-end">
        <IconButton
          className="ms-2"
          iconClassName="fs--2 me-1"
          variant="falcon-default"
          size="sm"
          icon="check"
          onClick={handleSubmit}
        >
          Salvar
        </IconButton>
      </footer>
    </div>
  );
};

export default CompanyInfo;
