import { faArrowRight, faDownload, faInfoCircle, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import example from 'assets/csv/contacts_test.csv';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import Loading from 'components/loading/Loading';
import FailedRequest from 'components/requests-response/FailedRequest';
import useApi from 'helpers/api';
import { useEffect, useState } from 'react';
import { Alert, Button, Form, Modal, Nav, Table } from 'react-bootstrap';
import './FileDropzone.css';
import './NavItem.css';

const ContactImportModal = ({ show, handleClose, refresh }) => {
  const api = useApi();
  const [step, setStep] = useState(1);
  const [file, setFile] = useState(null);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [csvSample, setCsvSample] = useState([]);
  const [fieldMapping, setFieldMapping] = useState({});
  const [importResult, setImportResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (!show) return;
    setStep(1);
    setFile(null);
  }, [show]);

  useEffect(() => {
    const autoMapFields = () => {
      const newMapping = {};

      csvHeaders.forEach((header) => {
        // Normaliza o nome da coluna (remove acentos e caixa baixa)
        const normalizedHeader = header.toLowerCase();

        // Procura um campo do sistema com o mesmo nome
        const match = systemFields.find(field =>
          field.label.toLowerCase() === normalizedHeader
        );

        // Se encontrar, mapeia automaticamente
        if (match) {
          newMapping[header] = match.value;
        }
      });

      setFieldMapping(newMapping);
    };

    if (csvHeaders.length > 0) {
      autoMapFields();
    }
  }, [csvHeaders, systemFields]);

  const systemFields = [
    { value: 'name', label: 'Nome' },
    { value: 'phone', label: 'Número de Telefone' },
    { value: 'landline', label: 'Telefone fixo' },
    { value: 'email', label: 'E-mail' },
    { value: 'cnpj_cpf_customer', label: 'CNPJ/CPF Cliente' }
  ];

  const navItems = [
    {
      number: 1,
      label: 'Carregue o arquivo'
    },
    {
      number: 2,
      label: 'Mapeamento'
    },
    {
      number: 3,
      label: 'Resultados'
    }
  ];

  const handleFileChange = (e) => setFile(e.target.files[0]);

  const handlePreview = async () => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      setLoading(true);
      const request = await api.contacts.importPreview(formData);
      if (request.ok) {
        const response = await request.json();
        setCsvHeaders(response.headers);
        setCsvSample(response.samples);
        setStep(2);
      } else {
        const errorResponse = await request.json();
        setError("Erro ao gerar preview: " + errorResponse.userMessage);
        setShowError(true);
      }
    } catch (error) {
      console.error('Erro ao gerar preview', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFieldChange = (csvColumn, systemField) => {
    setFieldMapping((prev) => ({ ...prev, [csvColumn]: systemField }));
  };

  const handleImport = async () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('mapping', JSON.stringify(fieldMapping));
    try {
      setLoading(true);
      const request = await api.contacts.importProcess(formData);
      if (request.ok) {
        const response = await request.json();
        setImportResult(response);
        setStep(3);
      }
    } catch (error) {
      console.error('Erro na importação', error);
    } finally {
      setLoading(false);
    }
  };

  const renderStep1 = () => (
    <FileUploadStep
      handleFileChange={handleFileChange}
      handlePreview={handlePreview}
      handleClose={handleClose}
      file={file}
      loading={loading} />
  );

  const renderStep2 = () => (
    <>
      <Flex justifyContent={'center'} className='mt-2'>
        <span className='mx-auto text-center fs-10'>Defina como deve ser feito o mapeamento das colunas da planilha importada</span>
      </Flex>
      <Table>
        <tbody>
          {csvHeaders.map((col) => (
            <tr key={col}>
              <td>
                <Form.Label>{col}</Form.Label>
                <Form.Select disabled>
                  <option value="">{csvSample[0]?.[col] || '-'}</option>
                </Form.Select>
              </td>
              <td>
                <Form.Label>Para</Form.Label>
                <Form.Select
                  value={fieldMapping[col] || ''}
                  onChange={(e) => handleFieldChange(col, e.target.value)}
                >
                  <option value="">Não importar</option>
                  {systemFields.map((field) => (
                    <option key={field.value} value={field.value}>{field.label}</option>
                  ))}
                </Form.Select>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Flex justifyContent={'end'}>
        <Button className='me-2' variant="falcon-default" onClick={() => setStep(1)}>Voltar</Button>{' '}
        <Button variant="primary" onClick={handleImport} disabled={loading}>
          Próximo
          <FontAwesomeIcon icon={faArrowRight} className='ms-2' />
        </Button>
      </Flex>
    </>
  );

  const renderStep3 = () => (
    <>
      <h5 className='mb-2'>Resultado da Importação</h5>
      <Alert variant="success">
        Total Importados: {importResult?.imported}

      </Alert>

      <Alert variant="info">
        Total Atualizados: {importResult?.updated}
      </Alert>

      {importResult?.errors?.length > 0 && (
        <Alert variant="danger">
          Foram encontrados erros em algumas linhas:
          <ul>
            {importResult.errors.map((err, idx) => (
              <li key={idx}>Linha {err.line}: {err.error}</li>
            ))}
          </ul>
        </Alert>
      )}

      <Flex justifyContent={'end'}>
        <Button variant="falcon-default" onClick={() => {
          handleClose()
          refresh()
        }}>Fechar</Button>
      </Flex>
    </>
  );

  return (
    <>
      {loading &&
        <Loading />}
      <FailedRequest
        showAlert={showError}
        setShowAlert={() => setShowError(false)}
        message={error} />
      <Modal show={show} onHide={handleClose} size="xl" centered >
        <Modal.Header closeButton>
          <Modal.Title className='d-flex align-items-center'>
            Importação de Contatos
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Nav className="justify-content-between mb-3">
            {navItems.map((item, index) => (
              <NavItem
                key={item.label}
                index={index + 1}
                step={step}
                number={item.number}
                label={item.label}
                isLast={index === navItems.length - 1}
              />
            ))}
          </Nav>

          {step === 1 && renderStep1()}
          {step === 2 && renderStep2()}
          {step === 3 && renderStep3()}
        </Modal.Body>
      </Modal>
    </>
  );
};

const FileUploadStep = ({ handleFileChange, handlePreview, handleClose, file, loading }) => {
  const [dragActive, setDragActive] = useState(false);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(e.type === 'dragover');
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFileChange({ target: { files: e.dataTransfer.files } });
    }
  };

  return (
    <Form>
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label className="mb-3">
          Aqui, você pode importar contatos para o MSP Desk usando um arquivo CSV. Certifique-se de que o arquivo CSV esteja codificado em UTF-8 e que a linha do cabeçalho inclua os campos de contato listados (nome, e-mail, etc.).
        </Form.Label>

        {/* Área customizada de Drop */}
        <Flex
          justifyContent={'center'}
          alignItems={'center'}
          className={classNames('bg-200 dropzone', dragActive && 'active')}
          onDragEnter={handleDrag}
          onDragOver={handleDrag}
          onDragLeave={handleDrag}
          onDrop={handleDrop}
          onClick={() => document.getElementById('hiddenFileInput').click()}
        >
          {file ? (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <FontAwesomeIcon icon={faDownload} size='2x' className='mb-2 text-600' />
              <Button variant='link'>Arquivo selecionado: {file.name}</Button>
            </div>
          ) : (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <FontAwesomeIcon icon={faUpload} size='2x' className='mb-2 text-600' />
              <Button variant='link'>Arraste o arquivo CSV aqui ou clique para selecionar</Button>
            </div>
          )}
        </Flex>

        {/* Input oculto */}
        <Form.Control
          type="file"
          id="hiddenFileInput"
          accept=".csv"
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </Form.Group>

      <div className='border rounded p-3 mb-3'>
        <Flex alignItems={'center'} className='mb-1'>
          <FontAwesomeIcon icon={faInfoCircle} color='red' className='me-2' />
          <h5>Observações importantes</h5>
        </Flex>

        <ul>
          <li>Se houver um contato existente no arquivo CSV, suas informações serão atualizadas com base nos dados do arquivo carregado.</li>
          <li>
            Os campos que podem ser importados no arquivo CSV são:
            <strong>Nome, E-mail, Número de Telefone, Telefone Fixo, CNPJ/CPF do cliente principal</strong>.
          </li>
          <li>Dos campos acima, somente os campos <strong>Nome, CNPJ/CPF do cliente principal e uma das informações de contato (E-mail, Número de Telefone ou Telefone Fixo)</strong> <span className='text-danger'>são obrigatórios.</span></li>
          <li>
            Caso tenha dúvidas sobre o formato do arquivo CSV, você pode baixar um exemplo clicando no link:
            <FontAwesomeIcon icon={faDownload} className='ms-2' />{' '}
            <a href={example} download className='ms-1'>Exemplo CSV</a>
          </li>
        </ul>
      </div>


      <Flex className={'w-100'} justifyContent={'end'}>
        <Button
          variant="falcon-default"
          onClick={handleClose}
          disabled={loading}
          className='me-2'>
          Cancelar
        </Button>
        <Button
          variant="primary"
          onClick={handlePreview}
          disabled={!file || loading}>
          Próximo
          <FontAwesomeIcon icon={faArrowRight} className='ms-2' />
        </Button>
      </Flex>
    </Form>
  );
};

const NavItem = ({ index, step, number, label, isLast }) => {
  return (
    <Nav.Item className='d-flex flex-column align-items-center step-item position-relative'>
      <span className={classNames(
        'rounded-circle text-white d-flex align-items-center justify-content-center mb-2',
        step === index ? 'bg-primary' : 'bg-secondary'
      )}
        style={{ width: '30px', height: '30px', zIndex: 1 }}
      >
        {number}
      </span>

      {/* Linha só se não for o último */}
      {!isLast && <div className="bg-300 step-line"></div>}

      <span className={classNames(
        'text-center',
        step === index ? 'text-primary' : 'text-secondary'
      )}>{label}</span>
    </Nav.Item>
  );
};


export default ContactImportModal;