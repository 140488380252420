import classNames from 'classnames';
import useApi from 'helpers/api';
import PropTypes from 'prop-types';
import { useKanbanContext } from 'providers/KanbanProvider';
import React, { useEffect, useRef, useState } from 'react';
import KanbanColumnHeader from './KanbanColumnHeader';
import StrictModeDroppable from './StrictModeDroppable';
import TaskCard from './TaskCard';
import AgentPhotoService from 'services/AgentPhoto.service';

const KanbanColumn = ({ kanbanColumnItem }) => {
  const { id, status, items, tickets } = kanbanColumnItem;
  const [showForm, setShowForm] = useState(false);
  const formViewRef = useRef(null);
  const api = useApi();
  const [agentPhotos, setAgentPhotos] = useState({});
  const {
    kanbanState: { kanbanItems },
    kanbanDispatch
  } = useKanbanContext();

  const handleSubmit = cardData => {
    const targetList = kanbanItems.find(item => item.id === id);
    const cardId = targetList.items.length
      ? Math.max(...targetList.items.map(item => item.id)) + 1
      : Number(`${targetList.id}0`);
    const newCard = {
      id: cardId,
      title: cardData.title
    };
    const isEmpty = !Object.keys(cardData).length;

    if (!isEmpty) {
      kanbanDispatch({
        type: 'ADD_TASK_CARD',
        payload: { targetListId: id, newCard }
      });
      setShowForm(false);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      formViewRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 500);

    return clearTimeout(timeout);
  }, [showForm]);

  useEffect(() => {
    fetchAgentPhotos();
  }, [tickets, api]);

  const fetchAgentPhotos = async () => {
    const agentIds = [...new Set(tickets.map(ticket => ticket.agent?.id).filter(id => id))];
    if (agentIds.length > 0) {
      const requests = agentIds.map(id => AgentPhotoService.find(id));
      const responses = await Promise.all(requests);
      const photos = {};
      for (let i = 0; i < responses.length; i++) {
        if (responses[i].ok) {
          const { url } = await responses[i].json();
          photos[agentIds[i]] = url;
        }
      }
      setAgentPhotos(photos);
    }
  };
  return (
    <div className={classNames('kanban-column', { 'form-added': showForm })}>
      <KanbanColumnHeader id={id} title={status} itemCount={tickets.length} />
      <StrictModeDroppable droppableId={`${id}`} type="KANBAN">
        {provided => (
          <>
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              id={`container-${id}`}
              className="kanban-items-container scrollbar"
            >
              {tickets.map((ticket, index) => (
                <TaskCard key={ticket.id} index={index} ticket={ticket} agentPhoto={agentPhotos[ticket.agent?.id || '']} />
              ))}
              {provided.placeholder}
              <div ref={formViewRef}></div>
            </div>
          </>
        )}
      </StrictModeDroppable>
    </div>
  );
};

KanbanColumn.propTypes = {
  kanbanColumnItem: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    status: PropTypes.string,
    items: PropTypes.arrayOf(TaskCard.propTypes.task)
  })
};

export default KanbanColumn;
