import { BusinessHourModel } from 'types/BusinessHourTypes';
import useHttp from '../helpers/HttpMethods';

class BusinessHourService {
  /**
   * Busca os horários de serviço cadastrados.
   * @returns {Promise<BusinessHourModel[]>}
   */
  static async list(): Promise<BusinessHourModel[]> {
    return await useHttp().get(`/business-hours`);
  }
  /**
   * Edita o cadastro de horário de serviço.
   * @param {BusinessHourModel} body - tipo do objeto enviado.  
   * @returns {Promise<>}
   */
  static async edit(id: number, body: BusinessHourModel): Promise<Response> {
    return await useHttp().put(body, `/business-hours/${id}`);
  }

}

export default  BusinessHourService