import { Col, Image } from "react-bootstrap";
import img from "../../assets/img/image.png";
import PropTypes from "prop-types";

const EmptyList = ({ imgSize, vhSize }) => {
  return (
    <Col
      style={{ position: "relative", textAlign: "center", height: `${vhSize}vh` }}
      className="d-flex flex-column align-items-center justify-content-center"
    >
      <Image
        src={img}
        height={imgSize}
        width={imgSize}
        style={{ objectFit: "contain" }}
      />
      <h2 style={{ marginTop: "-30px" }}>Nada por aqui...</h2>
      <p>Comece a adicionar novos registros</p>
    </Col>
  );
};

export default EmptyList;
