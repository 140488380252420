import useHttp from '../helpers/HttpMethods';
import { ServiceCatalogById, ServiceCatalogPaginated, ServiceCatalogTreeView, ServiceCatalogTreeViewByServiceCatalog } from 'types/ServiceCatalogTypes';

class ServiceCatalogService {
/**
   * Busca todos os catálogos de serviço paginados.
   * @param {string} filters - filtros em string.
   * @returns {Promise<ServiceCatalogPaginated | Response>}
   */
static async list(filters: string): Promise<ServiceCatalogPaginated | Response> {
  return await useHttp().get(`/service-catalogs?${filters}`);
}

/**
   * Busca todos os catálogos de serviço usados em tree view.
   * @returns {Promise<ServiceCatalogTreeView | Response>}
   */
static async listInTreeView(): Promise<ServiceCatalogTreeView | Response> {
  return await useHttp().get(`/service-catalogs/tree-view`);
}

/**
   * Busca os catálogos de serviço por grupo de serviço.
   * @param {number} id - id do ticket agendado.
   * ps: não usado ainda no código, porém já implementei parcialmente, falta a response certa.
   * ass: simões.
   * @returns {Promise<Response>}
   */
static async listByServiceGroup(id: number): Promise<Response> {
  return await useHttp().get(`/service-catalogs/by-service-group/${id}`);
}

/**
   * Busca os catálogos de serviço por grupo de serviço para usar em um tree view.
   * @param {number} serviceGroupId - serviceGroupId do ticket agendado.
   * @returns {Promise<Response | ServiceCatalogTreeViewByServiceCatalog>}
   */
static async listInTreeViewByServiceGroup(serviceGroupId: number): 
  Promise<Response | ServiceCatalogTreeViewByServiceCatalog> {
  return await useHttp().get(`/service-catalogs/by-service-group-tree-view/${serviceGroupId}`);
}

/**
   * Busca o catálogo de serviço por ID.
   * @param {number} id - id do ticket agendado.
   * @returns {Promise<Response | ServiceCatalogById>}
   */
static async findById(id: number): Promise<Response | ServiceCatalogById> {
  return await useHttp().get(`/service-catalogs/${id}`);
}

/**
   * Cria um catálogo de serviço.
   * @param {name: string} body - objeto apenas com propriedade name in simple string.
   * @returns {Promise<{id: number, name: string}>}
   */
static async create(body: {name: string}): Promise<{id: number, name: string}> {
  return await useHttp().post(body, `/service-catalogs`);
}

/**
   * Envia um arquivo para importar um catálogo de serviço em CSV.
   * @param {FormData} file - Arquivo enviado.
   * @returns {Promise<Response>} - Resposta padrão de uma Request.
   */
static async importCatalogs(file: FormData): Promise<Response> {
  return await useHttp().postFile(file, '/service-catalogs/import');
}

/**
   * Edita um catálogo de serviço.
   * @param {number} id - ID do catálogo de serviço.
   * @param {{name: string}} body - tipo do objeto enviado.
   * @returns {Promise<Response | {id: number, name: string}>}
   */
static async edit(id: number, body: {name: string}): Promise<Response | {id: number, name: string}> {
  return await useHttp().put(body, `/service-catalogs/${id}`);
}
}

export default ServiceCatalogService
