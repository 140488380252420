import { ScheduledTicketDetailed, ScheduledTicketInputModel, ScheduledTicketModel, ScheduledTicketsPaginated } from 'types/ScheduledTicketTypes';
import useHttp from '../helpers/HttpMethods';

class ScheduledTicketsService {
/**
   * Busca todos os tickets agendados paginados.
   * @param {BusinessHourModel} body - tipo do objeto enviado.
   * @returns {Promise<ScheduledTicketsPaginated>}
   */
static async list(filters: string): Promise<ScheduledTicketsPaginated> {
  return await useHttp().get(`/scheduleds-tickets?${filters}`);
}

/**
   * Busca o objeto detalhado do ticket agendado.
   * @param {BusinessHourModel} id - id do ticket agendado.
   * @returns {Promise<Response | ScheduledTicketDetailed>}
   */
static async findById(id: number): Promise<Response | ScheduledTicketDetailed> {
  return await useHttp().get(`/scheduleds-tickets/${id}`);
}

/**
   * Cria um novo ticket agendado.
   * @param {BusinessHourModel} body - tipo do objeto enviado.
   * @returns {Promise<ScheduledTicketModel>}
   */
static async create(body: ScheduledTicketInputModel): Promise<ScheduledTicketModel> {
  return await useHttp().post(body, `/scheduleds-tickets`);
}

/**
   * Edita um ticket agendado.
   * @param {ScheduledTicketInputModel} body - tipo do objeto enviado.  
   * @returns {Promise<ScheduledTicketDetailed>}
   */
static async edit(body: ScheduledTicketInputModel, id: number): Promise<Response | ScheduledTicketDetailed> {
  return await useHttp().put(body, `/scheduleds-tickets/${id}`);
}

/**
   * Exclui um ticket agendado.
   * @param {number} id - tipo do objeto enviado.  
   * @returns {Promise<Response>}
   */
static async delete(id: number): Promise<Response> {
  return await useHttp().del(`/scheduleds-tickets/${id}`);
}

/**
   * Desativa um ticket agendado.
   * @param {number} id - tipo do objeto enviado.  
   * @returns {Promise<Response>}
   */
static async inactivate(id: number): Promise<Response> {
  return await useHttp().del(`/scheduleds-tickets/${id}/inactivate`);
}

/**
   * Ativa um ticket agendado.
   * @param {number} id - tipo do objeto enviado.  
   * @returns {Promise<Response>}
   */
static async activate(id: number): Promise<Response> {
  return await useHttp().putWithoutBody(`/scheduleds-tickets/${id}/activate`);
}
}

export default ScheduledTicketsService
