import AdvanceTable from "components/common/advance-table/AdvanceTable";
import IconButton from "components/common/IconButton";
import ContactCustomerEditModal from "components/modals/CustomerContactEditModal";
import ContactCustomerRegistrationModal from "components/modals/CustomerContactRegistrationModal";
import CustomerDomainEditModal from "components/modals/CustomerDomainEditModal";
import CustomerDomainRegistrationModal from "components/modals/CustomerDomainRegistrationModal";
import DialogModal from "components/modals/DialogModal";
import Pagination from "components/pagination";
import useApi from "helpers/api";
import useAdvanceTable from "hooks/useAdvanceTable";
import useNotification from "hooks/useNotification";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { useCallback, useEffect, useState } from "react";
import { Card } from "react-bootstrap";

const CustomerContact = ({ data: customerData }) => {
  const api = useApi();
  const { showSuccessNotification, showErrorNotification } = useNotification();

  // State management
  const [customer, setCustomer] = useState({});
  const [contacts, setContacts] = useState({ content: [] });
  const [domains, setDomains] = useState({ content: [] });
  const [selectedContact, setSelectedContact] = useState({});
  const [selectedDomain, setSelectedDomain] = useState({});

  // Modal visibility states
  const [showDialog, setShowDialog] = useState(false);
  const [showModalDomainRegistration, setShowModalDomainRegistration] = useState(false);
  const [showModalRegistration, setShowModalRegistration] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalEditDomain, setShowModalEditDomain] = useState(false);

  // Pagination filters
  const [filterContacts, setFilterContacts] = useState({ page: 0, size: 10 });
  const [filterDomains, setFilterDomains] = useState({ page: 0, size: 10 });

  // Initialize customer data
  useEffect(() => {
    setCustomer(customerData);
  }, [customerData]);

  // Fetch contacts when customer or filters change
  useEffect(() => {
    if (Object.keys(customer).length !== 0) {
      fetchContacts();
    }
  }, [customer, filterContacts]);

  // Fetch domains when customer or filters change
  useEffect(() => {
    if (Object.keys(customer).length !== 0) {
      fetchDomains();
    }
  }, [customer, filterDomains]);

  // Data fetching functions
  const fetchContacts = useCallback(async () => {
    const queryParams = new URLSearchParams(filterContacts).toString();
    const response = await api.customersContacts.list(customer.id, queryParams);

    if (response.userMessage) { // Assumindo que response.userMessage indica erro
      showErrorNotification(response.userMessage || "Failed to fetch contacts");
    } else {
      setContacts(response);
    }
  }, [api.customersContacts, customer.id, filterContacts, showErrorNotification]);

  const fetchDomains = useCallback(async () => {
    const queryParams = new URLSearchParams(filterDomains).toString();
    const response = await api.allowedDomains.list(customer.id, queryParams);

    if (response.userMessage) {
      showErrorNotification(response.userMessage || "Failed to fetch domains");
    } else {
      setDomains(response);
    }
  }, [api.allowedDomains, customer.id, filterDomains, showErrorNotification]);

  // CRUD operations for contacts
  const addContact = async (body) => {
    const response = await api.customersContacts.add(body);

    if (response.userMessage) {
      showErrorNotification(response.userMessage || "Failed to add contact");
    } else {
      showSuccessNotification("Contato adicionado com sucesso");
      fetchContacts();
      handleCloseModalRegistration();
    }
  };

  const editContact = async (body) => {
    const response = await api.customersContacts.edit(selectedContact.id, body);

    if (response.userMessage) {
      showErrorNotification(response.userMessage || "Failed to edit contact");
    } else {
      showSuccessNotification("Contato editado com sucesso");
      fetchContacts();
      handleCloseModalEdit();
    }
  };

  // CRUD operations for domains
  const addDomain = async (body) => {
    const response = await api.allowedDomains.add(customer.id, body);

    if (response.userMessage) {
      console.log(response);
      showErrorNotification(response.userMessage || "Failed to add domain");
    } else {
      showSuccessNotification("Domínio liberado com sucesso");
      fetchDomains();
      handleCloseModalDomainRegistration();
    }
  };

  const editDomain = async (body) => {
    const response = await api.allowedDomains.edit(customer.id, selectedDomain.id, body);

    if (response.userMessage) {
      showErrorNotification(response.userMessage || "Failed to edit domain");
    } else {
      showSuccessNotification("Domínio editado com sucesso");
      fetchDomains();
      handleCloseModalEditDomain();
    }
  };

  const deleteDomain = async (confirm) => {
    if (!confirm) {
      handleCloseDialog();
      return;
    }

    const response = await api.allowedDomains.delete(customer.id, selectedDomain.id);

    if (response.userMessage) {
      showErrorNotification(response.userMessage || "Failed to delete domain");
    } else {
      showSuccessNotification("Domínio excluído com sucesso");
      fetchDomains();
      handleCloseDialog();
    }
  };

  // Modal handlers
  const handleCloseModalRegistration = () => setShowModalRegistration(false);
  const handleCloseModalDomainRegistration = () => setShowModalDomainRegistration(false);
  const handleCloseModalEdit = () => setShowModalEdit(false);
  const handleCloseModalEditDomain = () => setShowModalEditDomain(false);
  const handleCloseDialog = () => setShowDialog(false);

  // Action handlers
  const handleEditContact = (item) => {
    setSelectedContact(item);
    setShowModalEdit(true);
  };

  const handleEditDomain = (item) => {
    setSelectedDomain(item);
    setShowModalEditDomain(true);
  };

  const handleDeleteDomain = (item) => {
    setSelectedDomain(item);
    setShowDialog(true);
  };

  // Table configurations
  const domainColumns = [
    {
      accessorKey: 'domain',
      header: 'Domínio',
      meta: { headerProps: { className: 'text-900' } }
    },
    {
      accessorKey: 'actions',
      header: 'Ações',
      meta: { headerProps: { className: 'text-900' } },
      cell: ({ row }) => (
        <>
          <IconButton
            className="ms-2"
            iconClassName="fs--2"
            variant="link"
            size="sm"
            icon="pencil-alt"
            onClick={() => handleEditDomain(row.original)}
          />
          <IconButton
            className="ms-2"
            iconClassName="fs--2"
            variant="link"
            size="sm"
            icon="trash"
            onClick={() => handleDeleteDomain(row.original)}
          />
        </>
      )
    },
  ];

  const contactColumns = [
    {
      accessorKey: 'name',
      header: 'Nome',
      meta: { headerProps: { className: 'text-900' } }
    },
    {
      accessorKey: 'email',
      header: 'E-mail',
      meta: { headerProps: { className: 'text-900' } }
    },
    {
      accessorKey: 'landline',
      header: 'Telefone',
      meta: { headerProps: { className: 'text-900' } }
    },
    {
      accessorKey: 'phone',
      header: 'Celular',
      meta: { headerProps: { className: 'text-900' } }
    },
    {
      accessorKey: 'actions',
      header: 'Ações',
      meta: { headerProps: { className: 'text-900' } },
      cell: ({ row }) => (
        <IconButton
          className="ms-2"
          iconClassName="fs--2"
          variant="link"
          size="sm"
          icon="pencil-alt"
          onClick={() => handleEditContact(row.original)}
        />
      )
    },
  ];

  const domainTable = useAdvanceTable({
    data: domains.content,
    columns: domainColumns
  });

  const contactTable = useAdvanceTable({
    data: contacts.content,
    columns: contactColumns
  });

  return (
    <>
      {/* Modals */}
      <ContactCustomerRegistrationModal
        showModal={showModalRegistration}
        handleClose={handleCloseModalRegistration}
        handleSubmit={addContact}
        customer={customer}
      />
      <ContactCustomerEditModal
        data={selectedContact}
        showModal={showModalEdit}
        handleClose={handleCloseModalEdit}
        handleSubmit={editContact}
      />
      <CustomerDomainRegistrationModal
        showModal={showModalDomainRegistration}
        handleClose={handleCloseModalDomainRegistration}
        handleSubmit={addDomain}
      />
      <CustomerDomainEditModal
        data={selectedDomain}
        showModal={showModalEditDomain}
        handleClose={handleCloseModalEditDomain}
        handleSubmit={editDomain}
      />
      <DialogModal
        message="Tem certeza que deseja excluir este domínio?"
        showModal={showDialog}
        handleClose={handleCloseDialog}
        response={deleteDomain}
      />

      {/* Contacts Card */}
      <Card className="mb-4">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Contatos</b>
          <IconButton
            iconClassName="fs--2 me-1"
            variant="primary"
            size="sm"
            icon="plus"
            onClick={() => setShowModalRegistration(true)}
          >
            Novo
          </IconButton>
        </Card.Header>
        <Card.Body className="py-0">
          <AdvanceTableProvider {...contactTable}>
            <AdvanceTable
              headerClassName="text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                striped: true,
                className: 'fs-10 mb-0 overflow-hidden'
              }}
            />
          </AdvanceTableProvider>
          <Pagination
            data={contacts}
            filters={filterContacts}
            setFilters={setFilterContacts}
          />
        </Card.Body>
      </Card>

      {/* Domains Card */}
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Domínios liberados</b>
          <IconButton
            iconClassName="fs--2 me-1"
            variant="primary"
            size="sm"
            icon="plus"
            onClick={() => setShowModalDomainRegistration(true)}
          >
            Novo
          </IconButton>
        </Card.Header>
        <Card.Body className="py-0">
          <AdvanceTableProvider {...domainTable}>
            <AdvanceTable
              headerClassName="text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                striped: true,
                className: 'fs-10 mb-0 overflow-hidden'
              }}
            />
          </AdvanceTableProvider>
          <Pagination
            data={domains}
            filters={filterDomains}
            setFilters={setFilterDomains}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default CustomerContact;