import GeneralDashboard from 'components/charts/general-dashboard/GeneralDashboard';
import FilterQueryGroup from 'components/filter/FilterQueryGroup';
import NewReleaseModal from 'components/modals/NewReleaseModal';
import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import DashboardService from 'services/Dashboard.service';
import TicketsByCatalog from '../../charts/TicketsByCatalog';
import TicketsByAgent from '../../charts/by-agent/TicketsByAgent';
import TicketsByCustomer from '../../charts/by-customer/TicketsByCustomer';
import TicketsByServiceGroup from '../../charts/by-service-group/TicketsByServiceGroup';
import TicketsByPriority from './by-priority/TicketsByPriority';


const SupportDesk = () => {
  const [showReleaseModal, setShowReleaseModal] = useState(false);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    priority: '',
    agent: '',
    customer: '',
    serviceGroup: '',
    initialCreationDate: '',
    endCreationDate: ''
  });
  const [ticketsByCatalog, setTicketsByCatalog] = useState([]);
  const [ticketsByCustomer, setTicketsByCustomer] = useState([]);
  const [ticketByServiceGroups, setTicketsByServiceGroups] = useState([]);
  const [ticketsByAgent, setTicketsByAgent] = useState([]);
  const [ticketsByPriority, setTicketsByPriority] = useState([]);

  async function getData() {
    try {
      const queryParams = new URLSearchParams(filters).toString();
      const [
        resTicketsByCatalog,
        resTicketsByCustomer,
        resTicketsByServiceGroup,
        resTicketsByAgent,
        resTicketsByPriority
      ] = await Promise.all([
        DashboardService.getTicketsByCatalog(queryParams),
        DashboardService.getTicketsByCustomer(queryParams),
        DashboardService.getTicketsByServiceGroup(queryParams),
        DashboardService.getTicketsByAgent(queryParams),
        DashboardService.getTicketsByPriority(queryParams)
      ]);
      setTicketsByCatalog(resTicketsByCatalog);
      setTicketsByCustomer(resTicketsByCustomer);
      setTicketsByServiceGroups(resTicketsByServiceGroup);
      setTicketsByAgent(resTicketsByAgent);
      setTicketsByPriority(resTicketsByPriority);
    } catch (error) {
      setError(error);
    }
  }

  useEffect(() => {
    getData();
  }, [filters]);

  useEffect(() => {
    const isModalShown = localStorage.getItem("releaseModalShown_v1.1.4");

    if (!isModalShown) {
      setShowReleaseModal(true);
      localStorage.setItem("releaseModalShown_v1.1.4", "true");
    }
    localStorage.removeItem("releaseModalShown_v1.1.3");
  }, []);

  const handleFilterChange = filtersChange => {
    setFilters(filtersChange);
  };

  return (
    <>
      <NewReleaseModal
        show={showReleaseModal}
        close={() => setShowReleaseModal(false)} />
      <Card>
        <Card.Body>
          {error === null &&
            <>
              <Row className="g-3 mb-3 z-0">
                <Col>
                  <Row className="g-3">
                    <Row className="my-3">
                      <FilterQueryGroup
                        onFilterChange={handleFilterChange}
                        refreshData={() => getData()}
                      />
                    </Row>
                    <Row>
                      <GeneralDashboard filtersDash={filters} />
                    </Row>

                    <Row className="g-3">
                      <Col md={6} xxl={6}>
                        <TicketsByServiceGroup data={ticketByServiceGroups} />
                      </Col>
                      <Col md={6} xxl={6}>
                        <TicketsByCatalog data={ticketsByCatalog} title="Tickets em Aberto por Catálogo de Serviço" />
                      </Col>
                    </Row>
                    <Row className="g-3">
                      <Col md={6} xxl={6}>
                        <TicketsByPriority data={ticketsByPriority} />
                      </Col>
                      <Col md={6} xxl={6}>
                        <TicketsByAgent data={ticketsByAgent} />
                      </Col>
                    </Row>
                    <Row className='g-3'>
                      <Col md={12} xxl={12}>
                        <TicketsByCustomer data={ticketsByCustomer} />
                      </Col>
                    </Row>

                  </Row>
                </Col>
              </Row>
            </>
          }
        </Card.Body>
      </Card>
    </>
  );
};

export default SupportDesk;
