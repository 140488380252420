import { useEffect, useState } from "react";

export const useReportFilters = (initialFilters) => {
  const [filters, setFilters] = useState({
    page: 0,
    size: 20,
    ...initialFilters,
  });

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...initialFilters,
    }));
  }, [initialFilters]);

  return [filters, setFilters];
};