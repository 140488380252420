import { preTicketsPaginated } from 'types/PreTicketsTypes';
import useHttp from '../helpers/HttpMethods';

class PreTicketService {
  /**
   * Busca os pre tickets.
   * @param {string} filter - Filtro em formato de string.
   * @returns {Promise<preTicketsPaginated>} - Pre tickets em formato paginado.
   */
  static async list(filter: string): Promise<preTicketsPaginated> {
    return await useHttp().get(`/pre-tickets?${filter}`);
  }

  /**
   * Exclui um pre ticket.
   * @param {number} id - ID do pre ticket.
   * @returns {Promise<Response>} - Retorno padrão de uma request.
   */
  static async delete(id: number): Promise<Response> {
   return await useHttp().del(`/pre-tickets/${id}`);
  }

  /**
   * Exclui todos os pre tickets.
   * @returns {Promise<Response>} - Retorno padrão de uma request.
   */
  static async deleteAll(): Promise<Response> {
    return await useHttp().del(`/pre-tickets/delete-all`);
  }

  /**
   * Exclui os pre tickets em grupo.
   * @param {{preTickets: number[]}} body - Objeto que possui IDs para serem excluídos.
   * @returns {Promise<Response>} - Retorna padrão de uma request
   */
  static async batchDelete(body: {preTickets: number[]}): Promise<Response> {
    return await useHttp().delWithBody(body, `/pre-tickets/batch-delete`);
  }
}

export default PreTicketService;