import useHttp from '../helpers/HttpMethods';
import { EmailNotificationTemplate, EmailNotificationTemplateEdit, EmailNotificationTemplateSummary } from '../types/EmailNotificationTemplateTypes';

class EmailNotificationTemplateService {
  static async list(filter : string) : Promise<EmailNotificationTemplateSummary[]> {
    return await useHttp().get(`/email-notifications?${filter}`);
  };

  static async find(id : number) : Promise<EmailNotificationTemplate> {
    return await useHttp().get(`/email-notifications/${id}`);
  };

  static async edit(id : number, data : EmailNotificationTemplateEdit) : Promise<EmailNotificationTemplate> {
    return await useHttp().put(data, `/email-notifications/${id}`);
  };

  static async activate(id : number) : Promise<Response> {
    return await useHttp().putWithoutBody(`/email-notifications/${id}/activate`);
  };

  static async inactivate(id : number) : Promise<Response> {
    return await useHttp().del(`/email-notifications/${id}/inactivate`);
  }
}

export default EmailNotificationTemplateService;