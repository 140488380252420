import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useApi from "helpers/api";
import { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const ServiceGroupEditModal = ({ showModal, data, handleClose, handleSubmit, refreshData, error }) => {
  const [body, setBody] = useState({
    name: '',
    inactive: ''
  });
  const [associatedAgents, setAssociatedAgents] = useState([]);
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [validated, setValidated] = useState(false);
  const api = useApi();
  const form = useRef(null);

  useEffect(() => {
    if (Object.keys(data).length !== 0) {
      setBody(data);
      getAssociatedAgents();
    }
  }, [data]);

  useEffect(() => {
    if (showModal) {
      setBody(data);
      setValidated(false);
    }
  }, [showModal]);

  useEffect(() => {
    const getAgents = async () => {
      let response = await api.agents.list();
      if (response.content) setAgents(response.content);
    };
    getAgents();
  }, []);

  const getAssociatedAgents = async () => {
    let response = await api.serviceGroupsAgents.list(data.id);
    setAssociatedAgents(response);
  };

  const associateAgent = async (e) => {
    e.preventDefault();
    let response = await api.serviceGroupsAgents.associate(data.id, selectedAgent);
    if (response.status === 204) {
      getAssociatedAgents();
      refreshData();
    } else {
      error({ description: response.description });
    }
  };

  const unassociateAgent = async (e, agentId) => {
    e.preventDefault();
    let response = await api.serviceGroupsAgents.unassociate(data.id, agentId);
    if (response.status === 204) {
      getAssociatedAgents();
      refreshData();
    } else {
      error({ description: response.description });
    }
  };

  const onSubmit = (e) => {
    if (form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      handleSubmit({ name: body.name, inactive: body.inactive });
      setValidated(false);
    }
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar grupo de serviço</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form validated={validated} ref={form}>
          <Form.Group className="mb-3" controlId="editForm.Name">
            <Form.Label>Nome</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Nome"
              autoFocus
              value={body.name}
              onChange={(e) => setBody((prevBody) => ({ ...prevBody, name: e.target.value }))}
            />
            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Insira um nome.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Status</Form.Label>
            <Form.Check
              type="switch"
              id="status-switch"
              label={body.inactive ? "Inativo" : "Ativo"}
              checked={!body.inactive}
              onChange={() => setBody((prevBody) => ({ ...prevBody, inactive: !prevBody.inactive }))}
            />
          </Form.Group>

          <Form.Label>Associe um agente</Form.Label>
          <Form.Group className="w-100 d-flex justify-content-between">
            <Form.Select
              aria-label="Agentes"
              className="flex-1 me-2"
              onChange={(e) => setSelectedAgent(e.target.value)}
            >
              <option>Selecione uma opção</option>
              {agents.map((item, i) => (
                <option value={item.id} key={i}>
                  {item.firstName}
                </option>
              ))}
            </Form.Select>
            <button className="btn btn-primary btn-sm" onClick={associateAgent}>
              + Adicionar
            </button>
          </Form.Group>
        </Form>

        <div className="table-responsive scrollbar">
          <table className="table fs-10 mb-0 mt-3">
            <thead className="bg-200 text-900">
              <tr>
                <th>Agentes</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {associatedAgents.map((item, i) => (
                <tr key={i}>
                  <td data-value={item.id}>{item.firstName}</td>
                  <td>
                    <button
                      className="btn btn-link text-danger btn-sm"
                      onClick={(e) => unassociateAgent(e, item.id)}
                    >
                      x
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="falcon-default" onClick={handleClose}>
          Fechar
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          <FontAwesomeIcon icon={faSave} className="me-2" />
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ServiceGroupEditModal;
