import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useApi from 'helpers/api';
import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import PauseReasonsService from "services/PauseReasons.service";

const TicketAppointmentRegistrationModal = ({ title, data, showModal, handleClose, handleSubmit }) => {
  const [validated, setValidated] = useState(false);
  const [body, setBody] = useState({
    description: '',
    typeService: '',
    timeSpent: '',
    date: '',
    pauseSla: false,
    pauseReason: { id: '' }
  });
  const [pauseReasons, setPauseReasons] = useState([]);
  const [filters] = useState({ includeInactives: false });

  const api = useApi();
  const form = useRef(null);

  useEffect(() => {
    getPauseReasons();
  }, []);

  useEffect(() => {
    setBody({
      description: data.description ? data.description : '',
      typeService: data.typeService ? data.typeService.typeService : 'INTERNAL',
      timeSpent: data.timeSpent ? data.timeSpent : '00:00',
      date: data.date ? data.date : '',
      pauseSla: false,
      pauseReason: { id: '' }
    });
  }, [data]);

  const getPauseReasons = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await PauseReasonsService.list(queryParams);

    response.content && setPauseReasons(response.content);
  };

  const onSubmit = e => {
    if (form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      handleSubmit({
        description: body.description,
        typeService: body.typeService,
        timeSpent: body.timeSpent,
        date: body.date,
        pauseSla: body.pauseSla,
        pauseReason: body.pauseReason
      });
      clearFields();
      setValidated(false);
    }
  };

  const clearFields = () => {
    setBody({
      description: '',
      typeService: '',
      timeSpent: '',
      date: '',
      pauseSla: false,
      pauseReason: { id: '' }
    });
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    return now.toISOString().slice(0, 16); // Formata como yyyy-MM-ddTHH:mm
  };

  const today = getCurrentDateTime();

  const setFieldBody = (fieldName, value) => {
    setBody(prevBody => ({ ...prevBody, [fieldName]: value }));
  };

  return (
    <Modal size="lg" show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form validated={validated} ref={form}>
          <Row className="mb-3">
            <Form.Group as={Col} md="5" controlId="appointment.type">
              <Form.Label>Tipo <span className="text-danger">*</span></Form.Label>
              <Form.Select aria-label="Tipo" className="flex-1 me-2" value={body.typeService} onChange={e => setFieldBody('typeService', e.target.value)} required>
                <option value="INTERNAL" key="INTERNAL">Interno</option>
                <option value="EXTERNAL" key="EXTERNAL">Externo</option>
              </Form.Select>
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Escolha uma opção!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="appointment.profile">
              <Form.Label>Dia <span className="text-danger">*</span></Form.Label>
              <Form.Control
                type="datetime-local"
                value={body.date}
                onChange={e => setFieldBody('date', e.target.value)}
                required
                max={today} />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Selecione uma data!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="3" controlId="appointment.profile">
              <Form.Label>Horas <span className="text-danger">*</span></Form.Label>
              <Form.Control type="time" value={body.timeSpent} min="00:00" onChange={e => setFieldBody('timeSpent', e.target.value)} required />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira as horas trabalhadas!</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md={2}>
              <Form.Label>Pausar SLA?</Form.Label>
              <Form.Switch
                value={body.pauseSla}
                onChange={() => setFieldBody('pauseSla', !body.pauseSla)} />
            </Form.Group>

            {body.pauseSla &&
              <Form.Group as={Col} md={4}>
                <Form.Label>Motivo de pausa <span className="text-danger">*</span></Form.Label>
                <Form.Select required={body.pauseReason} value={body.pauseReason.id} onChange={e => setBody({ ...body, pauseReason: { id: e.target.value } })}>
                  <option value="">Selecione</option>
                  {pauseReasons.map((item, i) => (
                    <option value={item.id} key={i}>{item.name}</option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Motivo de pausa obrigatório.</Form.Control.Feedback>
              </Form.Group>
            }
          </Row>

          <Form.Group controlId="appointment.profile">
            <Form.Label>Descrição*</Form.Label>
            <Form.Control
              as="textarea"
              rows={6}
              name="description"
              value={body.description}
              onChange={e => setFieldBody('description', e.target.value)}
              required
            />
            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Insira uma descrição!</Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fechar
        </Button>
        <Button variant="primary" type="submit" onClick={onSubmit}>
          <FontAwesomeIcon icon={faSave} className="me-2" />
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TicketAppointmentRegistrationModal;