import { PhotoResponse, UpdatePhotoResponse } from 'types/AgetPhotoTypes';
import useHttp from '../helpers/HttpMethods';
import { typesTicketModel, typeTicketPaginated } from 'types/TypeTicketsTypes';

class TypeTicketsService {
  /**
   * Busca os tipos de ticket.
   * @param {string} filter - Filtro em formato de string.
   * @returns {Promise<typeTicketPaginated>} - Tipos de ticket em formato paginado.
   */
  static async list(filter: string): Promise<typeTicketPaginated> {
    return await useHttp().get(`/types-ticket?${filter}`);
  }

  /**
   * Cria um novo tipo de ticket.
   * @param {{name: string}} body - Body para criar um novo tipo de ticket.
   * @returns {Promise<typesTicketModel>} - Objeto retornado na criação de um novo tipo.
   */
  static async create(body: {name: string}): Promise<typesTicketModel> {
   return await useHttp().post(body, `/types-ticket`);
  }

  /**
   * Altera um tipo de ticket.
   * @param {{name: string, inactive: true}} body - Informações para atualizar um tipo de ticket.
   * @returns {Promise<typesTicketModel>} - Retorna o tipo de ticket modificado
   */
  static async edit(id: number, body: {name: string, inactive: boolean}): Promise<typesTicketModel> {
    return await useHttp().put(body, `/types-ticket/${id}`);
  }
}

export default TypeTicketsService;