import DatePickerComponent from "components/common/DatePickerComponent";
import Flex from "components/common/Flex";
import MultiSelect from "components/common/MultiSelect";
import useApi from 'helpers/api';
import { formatDateUs } from "helpers/utils";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton';
import HoursIndicated from "./HoursIndicatedByAgent";
import ProductivityByAgent from "./ProductivityByAgent";
import ProductivityByServiceGroup from "./ProductivityByServiceGroup";
import Summary from "./Summary";
import NumberOfTickets from "./number-of-tickets/NumberOfTickets";

const ProductivityAnalysis = () => {
  const api = useApi();
  const date = new Date();
  const [startDate, setStartDate] = useState(date.setDate(date.getDate() - 7));
  const [endDate, setEndDate] = useState(new Date());
  const [serviceGroups, setServiceGroups] = useState([]);
  const [selectedServiceGroups, setSelectedServiceGroups] = useState([]);
  const [formattedServiceGroups, setFormattedServiceGroups] = useState([]);
  const [agents, setAgents] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [formattedAgents, setFormattedAgents] = useState([]);
  const [filterQuery] = useState({
    size: 100,
    includeInactives: false
  });
  const [filters, setFilters] = useState({
    initialDate: formatDateUs(startDate),
    endDate: formatDateUs(endDate),
    serviceGroups: [],
    agents: []
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getServiceGroups();
    getAgents();
  }, []);

  useEffect(() => {
    setFormattedServiceGroups(serviceGroups.map(item => ({
      value: parseInt(item.id),
      label: item.name
    })));
  }, [serviceGroups]);

  useEffect(() => {
    setFormattedAgents(agents.map(agent => ({
      value: parseInt(agent.id),
      label: agent.firstName + ' ' + agent.lastName
    })));
  }, [agents]);

  useEffect(() => {
    setFilters({ ...filters, initialDate: formatDateUs(startDate), endDate: formatDateUs(endDate) });
  }, [startDate, endDate]);

  useEffect(() => {
    setFilters({ ...filters, serviceGroups: selectedServiceGroups.map(item => item.value) });
  }, [selectedServiceGroups]);

  useEffect(() => {
    setFilters({ ...filters, agents: selectedAgents.map(item => item.value) });
  }, [selectedAgents]);

  const getServiceGroups = async () => {
    setIsLoading(true);
    const queryParams = new URLSearchParams(filterQuery);
    let response = await api.serviceGroups.list(queryParams);
    if (response.content) {
      setServiceGroups(response.content);
      setIsLoading(false);
    }
  };

  const getAgents = async () => {
    const response = await api.agents.listSummaryData();
    setAgents(response ? response : []);
  };

  return (
    <>
      <Flex alignItems="center">
        <Form.Group className='w-25 me-3'>
          <h6 className="text-700">Período </h6>
          {isLoading ? (
            <Skeleton height={38} />
          ) : (
            <DatePickerComponent startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} applyCustomStyles={false} />
          )}
        </Form.Group>

        <Form.Group className='me-3 flex-1'>
          <h6 className="text-700">Grupos de serviço </h6>
          {isLoading ? (
            <Skeleton height={38} />
          ) : (
            <MultiSelect
              value={selectedServiceGroups}
              options={formattedServiceGroups}
              placeholder='Selecione...'
              onChange={selectedOptions => setSelectedServiceGroups(selectedOptions)} />
          )}
        </Form.Group>

        <Form.Group className='me-3 w-25'>
          <h6 className="text-700">Agentes </h6>
          {isLoading ? (
            <Skeleton height={38} />
          ) : (
            <MultiSelect
              value={selectedAgents}
              options={formattedAgents}
              placeholder='Selecione...'
              onChange={selectedOptions => setSelectedAgents(selectedOptions)} />
          )}
        </Form.Group>
      </Flex>

      {isLoading ? (
        <>
          <Skeleton height={200} className="mb-3" />
          <Skeleton height={300} className="mb-3" />
          <Skeleton height={250} className="mb-3" />
          <Skeleton height={350} className="mb-3" />
          <Skeleton height={150} className="mb-3" />
        </>
      ) : (
        <>
          <Summary filters={filters} />
          <ProductivityByAgent filters={filters} />
          <HoursIndicated filters={filters} />
          <ProductivityByServiceGroup filters={filters} />
          <NumberOfTickets filters={filters} />
        </>
      )}
    </>
  );
};

export default ProductivityAnalysis;