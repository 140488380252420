import {
  faCheck,
  faClipboardList,
  faExclamationTriangle,
  faFlag,
  faHourglassHalf,
  faInbox,
  faPause,
  faPlay,
  faUser,
  faUserSlash
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { FilterContext } from 'context/Context';
import useApi from 'helpers/api';
import PropTypes from 'prop-types';
import { useSelectedView } from 'providers/SelectedViewProvider';
import { useContext, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import DashboardService from 'services/Dashboard.service';

const GeneralDashboard = ({ filtersDash }) => {
  const api = useApi();
  const { setFilters } = useContext(FilterContext);
  const { setSelectedViewName } = useSelectedView();
  const navigate = useNavigate();

  const [myId, setMyId] = useState('');
  const [toDoCount, setToDoCount] = useState(0);
  const [inProgressCount, setInProgressCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [openingCount, setOpeningCount] = useState(0);
  const [unassigned, setUnassigned] = useState(0);
  const [myTickets, setMyTickets] = useState(0);
  const [responseExpireds, setResponseExpireds] = useState(0);
  const [solutionExpireds, setSolutionExpireds] = useState(0);
  const [slaPaused, setSlaPaused] = useState(0);
  const [preTickets, setPreTickets] = useState(0);
  const [answered, setAnswered] = useState(0);

  const openTickets = ['TO_DO', 'IN_PROGRESS', 'PENDING'];

  useEffect(() => {
    getByStatus();
    getUnassigneds();
    getMyTickets();
    getResponseExpireds();
    getSolutionExpireds();
    getSlaPaused();
    getAnswered();
  }, [filtersDash]);

  useEffect(() => {
    getPreTickets();
    getMyId();
  }, []);

  const getByStatus = async () => {
    const queryParams = new URLSearchParams(filtersDash).toString();
    const response = await DashboardService.getTicketsByStatus(queryParams);
    const isToDo = response.find(item => item.status === 'TO_DO');
    const isInProgress = response.find(item => item.status === 'IN_PROGRESS');
    const isPending = response.find(item => item.status === 'PENDING');
    const countToDo = isToDo ? isToDo.count : 0;
    const countInProgress = isInProgress ? isInProgress.count : 0;
    const countPending = isPending ? isPending.count : 0;

    setToDoCount(countToDo);
    setInProgressCount(countInProgress);
    setPendingCount(countPending);
    setOpeningCount(countToDo + countInProgress + countPending);
  };

  const getUnassigneds = async () => {
    const queryParams = new URLSearchParams(filtersDash).toString();
    const response = await DashboardService.getTicketsUnassigned(queryParams);
    setUnassigned(response ? response.count : 0);
  };

  const getAnswered = async () => {
    const queryParams = new URLSearchParams(filtersDash).toString();
    const response = await DashboardService.getAnswered(queryParams);
    setAnswered(response ? response.count : 0);
  };

  const getMyTickets = async () => {
    const queryParams = new URLSearchParams(filtersDash).toString();
    const response = await DashboardService.getMyTickets(queryParams);
    setMyTickets(response ? response.count : 0);
  };

  const getResponseExpireds = async () => {
    const queryParams = new URLSearchParams(filtersDash).toString();
    const response = await DashboardService.getTicketsResponseExpireds(queryParams);
    setResponseExpireds(response ? response.count : 0);
  };

  const getSolutionExpireds = async () => {
    const queryParams = new URLSearchParams(filtersDash).toString();
    const response = await DashboardService.getTicketsSolutionExpireds(queryParams);
    setSolutionExpireds(response ? response.count : 0);
  };

  const getSlaPaused = async () => {
    const queryParams = new URLSearchParams(filtersDash).toString();
    const response = await DashboardService.getTicketsWithSlaPaused(queryParams);
    setSlaPaused(response ? response.count : 0);
  };

  const getPreTickets = async () => {
    const response = await DashboardService.getPreTickets();
    setPreTickets(response ? response.count : 0);
  };

  const getMyId = async () => {
    const response = await api.agents.getUserIdAuthenticated();
    response && setMyId(response);
  };

  const handleSetFilters = (filterName, value) => {
    setFilters(prevState => ({ ...prevState, [filterName]: value }));
  };

  const handleChangeFilterProp = (selectedFilter, filterName, value) => {
    setSelectedViewName(selectedFilter);
    clearFilters();
    handleSetFilters(filterName, value);
    navigate('/tickets');
  };

  const handleChangeFilterProps = (selectedFilter, changes) => {
    setSelectedViewName(selectedFilter);
    clearFilters();
    setFilters({ page: 0, size: 10, ...changes });
    navigate('/tickets');
  };

  const clearFilters = () => {
    setFilters({
      page: 0,
      size: 20,
      code: '',
      priorities: '',
      status: '',
      customer: '',
      serviceCatalog: '',
      category: '',
      subcategory: '',
      subject: '',
      agent: '',
      serviceGroup: '',
      typeTicket: '',
      initialCreationDate: '',
      endCreationDate: '',
      initialSolutionDate: '',
      endSolutionDate: '',
      statusSlaResponse: '',
      statusSlaSolution: '',
      createdBy: '',
      defaultFilter: ''
    });
  };

  const GeneralItem = ({ data }) => {
    return (
      <Flex className="w-100 h-100" alignItems="center" justifyContent="start">
        <Flex justifyContent="center" alignItems="center" className='rounded-circle me-3' style={{ backgroundColor: '#C0FB50', width: 45, height: 45 }}>
          <FontAwesomeIcon icon={data.icon} className='text-dark' size='md' />
        </Flex>
        <div className='flex-1'>
          <h6 className="m-0 text-secondary text-truncation">{data.title}</h6>
          <h4 className="m-0 fw-bold">{data.count}</h4>
        </div>
      </Flex>
    )
  };

  return (
    <>
      <Row className="g-2 home-cards">
        <h3>Geral</h3>
        <Col md={2}>
          <Card
            role="button"
            onClick={() => handleChangeFilterProp("A Fazer", 'status', ['TO_DO'])}
          >
            <Card.Body>
              <GeneralItem data={{ title: 'A Fazer', count: toDoCount, icon: faFlag }} />
            </Card.Body>
          </Card>
        </Col>
        <Col md={2}>
          <Card
            role="button"
            onClick={() => handleChangeFilterProp("Em atendimento", 'status', ['IN_PROGRESS'])}
          >
            <Card.Body>
              <GeneralItem data={{ title: 'Em Atendimento', count: inProgressCount, icon: faPlay }} />
            </Card.Body>
          </Card>
        </Col>
        <Col md={2}>
          <Card
            role="button"
            onClick={() => handleChangeFilterProp("Pausado", 'status', ['PENDING'])}
          >
            <Card.Body>
              <GeneralItem data={{ title: 'Pausado', count: pendingCount, icon: faPause }} />
            </Card.Body>
          </Card>
        </Col>
        <Col md={2}>
          <Card
            role="button"
            onClick={() => handleChangeFilterProp("Aberto", 'status', openTickets)}
          >
            <Card.Body>
              <GeneralItem data={{ title: 'Aberto', count: openingCount, icon: faInbox }} />
            </Card.Body>
          </Card>
        </Col>
        <Col md={2}>
          <Card as={Link} to="/pre-tickets" style={{ textDecoration: 'none' }}>
            <Card.Body>
              <GeneralItem data={{ title: 'Pré-tickets', count: preTickets, icon: faClipboardList }} />
            </Card.Body>
          </Card>
        </Col>
        <Col md={2}>
          <Card
            role="button"
            onClick={() => handleChangeFilterProp("Não atribuído", 'agent', 0)}
          >
            <Card.Body>
              <GeneralItem data={{ title: 'Não atribuído', count: unassigned, icon: faUserSlash }} />
            </Card.Body>
          </Card>
        </Col>
        <Col md={2}>
          <Card
            role="button"
            onClick={() =>
              handleChangeFilterProps("Meus", {
                status: openTickets,
                agent: myId
              })
            }
          >
            <Card.Body>
              <GeneralItem data={{ title: 'Meus', count: myTickets, icon: faUser }} />
            </Card.Body>
          </Card>
        </Col>
        <Col md={2}>
          <Card
            role="button"
            onClick={() =>
              handleChangeFilterProps("Respondidos", {
                answered: true,
              })
            }
          >
            <Card.Body>
              <GeneralItem data={{ title: 'Respondidos', count: answered, icon: faCheck }} />
            </Card.Body>
          </Card>
        </Col>
        <Col md={2}>
          <Card
            role="button"
            onClick={() =>
              handleChangeFilterProps("SLA Resposta vencido", {
                status: openTickets,
                statusSlaResponse: 'BREACHED'
              })
            }
          >
            <Card.Body>
              <GeneralItem data={{ title: 'SLA Resposta Vencido', count: responseExpireds, icon: faExclamationTriangle }} />
            </Card.Body>
          </Card>
        </Col>
        <Col md={2}>
          <Card
            role="button"
            onClick={() =>
              handleChangeFilterProps("SLA Solução vencido", {
                status: openTickets,
                statusSlaSolution: 'BREACHED'
              })
            }
          >
            <Card.Body>
              <GeneralItem data={{ title: 'SLA Solução Vencido', count: solutionExpireds, icon: faExclamationTriangle }} />
            </Card.Body>
          </Card>
        </Col>
        <Col md={2}>
          <Card
            role="button"
            onClick={() =>
              handleChangeFilterProps("SLA Pausado", {
                status: openTickets,
                statusSlaSolution: 'PAUSED'
              })
            }
          >
            <Card.Body>
              <GeneralItem data={{ title: 'SLA Pausado', count: slaPaused, icon: faHourglassHalf }} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

GeneralDashboard.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array).isRequired
};

export default GeneralDashboard;
