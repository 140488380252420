import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import useApi from 'helpers/api';
import { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';
import Skeleton from 'react-loading-skeleton';

const CustomerInfo = ({ data, error, success, loading }) => {
  const api = useApi();
  const form = useRef(null);
  const [body, setBody] = useState({});
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    setBody(data);
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (form.current.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      const response = await api.customers.edit(body.id, body);

      if (response.status) {
        error(response);
      } else {
        success(true);
        setBody(response);
      }

      setValidated(false);
    }
  };

  const isJuridicalPerson = body.type === 'JURIDICAL_PERSON';

  return (
    <div className="mb-3">
      <div>
        <Row>
          <Col lg xxl={12}>
            <Row className="justify-content-between align-items-center">
              <Col>
                <h6 className="fw-semi-bold ls mb-3 text-uppercase">Editar informações</h6>
              </Col>
              <Col xs="auto">
                <Form.Group>
                  <Flex alignItems="center" gap={2}>
                    <Form.Label className="m-0 p-0 me-2 fs-10">Ativo </Form.Label>
                    {loading ? (
                      <Skeleton width={30} height={20} />
                    ) : (
                      <Form.Check
                        className="fs-10"
                        type="switch"
                        name="inactive"
                        checked={!body.inactive}
                        onChange={() => setBody((prevBody) => ({ ...prevBody, inactive: !prevBody.inactive }))}
                      />
                    )}
                  </Flex>
                </Form.Group>
              </Col>
            </Row>

            <Form validated={validated} ref={form} onSubmit={handleSubmit}>
              {/* Primeira linha - Tipo e Nome Completo */}
              <Row className="mb-3">
                <Form.Group as={Col} md="3" controlId="customer.type">
                  <Form.Label>Tipo</Form.Label>
                  {loading ? (
                    <Skeleton height={38} />
                  ) : (
                    <Form.Select
                      aria-label="Tipo"
                      className="flex-1 me-2"
                      onChange={(e) => setBody((prevBody) => ({ ...prevBody, type: e.target.value }))}
                      required
                      value={body.type}
                    >
                      <option value="">Selecione uma opção</option>
                      <option value="NATURAL_PERSON">Física</option>
                      <option value="JURIDICAL_PERSON">Jurídica</option>
                    </Form.Select>
                  )}
                  <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Insira um tipo.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="9" controlId="customer.name">
                  <Form.Label>Nome completo</Form.Label>
                  {loading ? (
                    <Skeleton height={38} />
                  ) : (
                    <Form.Control
                      required
                      type="text"
                      placeholder="Nome completo"
                      value={body.name || ''}
                      onChange={(e) => setBody((prevBody) => ({ ...prevBody, name: e.target.value }))}
                    />
                  )}
                  <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Insira um nome completo.</Form.Control.Feedback>
                </Form.Group>
              </Row>

              {/* Segunda linha - Campos específicos para Pessoa Física */}
              {!isJuridicalPerson && (
                <>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="customer.cpf">
                      <Form.Label>CPF</Form.Label>
                      {loading ? (
                        <Skeleton height={38} />
                      ) : (
                        <MaskedInput
                          mask={[/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                          guide={false}
                          required
                          type="text"
                          placeholder="CPF"
                          value={body.cpf || ''}
                          onChange={(e) => setBody((prevBody) => ({ ...prevBody, cpf: e.target.value }))}
                          render={(ref, props) => <Form.Control ref={ref} {...props} />}
                        />
                      )}
                      <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">Insira um CPF.</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId="customer.birthday">
                      <Form.Label>Data de nascimento</Form.Label>
                      {loading ? (
                        <Skeleton height={38} />
                      ) : (
                        <Form.Control
                          type="date"
                          value={body.birthday || ''}
                          onChange={(e) => setBody((prevBody) => ({ ...prevBody, birthday: e.target.value }))}
                          max={new Date().toISOString().split('T')[0]}
                        />
                      )}
                      <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId="customer.gender">
                      <Form.Label>Gênero</Form.Label>
                      {loading ? (
                        <Skeleton height={38} />
                      ) : (
                        <Form.Select
                          aria-label="Gênero"
                          value={body.gender || ''}
                          onChange={(e) => setBody((prevBody) => ({ ...prevBody, gender: e.target.value }))}
                        >
                          <option value="">Selecione uma opção</option>
                          <option value="MALE">Masculino</option>
                          <option value="FEMININE">Feminino</option>
                        </Form.Select>
                      )}
                      <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                </>
              )}

              {/* Campos específicos para Pessoa Jurídica */}
              {isJuridicalPerson && (
                <>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="customer.corporateName">
                      <Form.Label>Razão social</Form.Label>
                      {loading ? (
                        <Skeleton height={38} />
                      ) : (
                        <Form.Control
                          required
                          type="text"
                          placeholder="Razão social"
                          value={body.corporateName || ''}
                          onChange={(e) => setBody((prevBody) => ({ ...prevBody, corporateName: e.target.value }))}
                        />
                      )}
                      <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">Insira uma razão social.</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="customer.fantasyName">
                      <Form.Label>Nome fantasia</Form.Label>
                      {loading ? (
                        <Skeleton height={38} />
                      ) : (
                        <Form.Control
                          required
                          type="text"
                          placeholder="Nome fantasia"
                          value={body.fantasyName || ''}
                          onChange={(e) => setBody((prevBody) => ({ ...prevBody, fantasyName: e.target.value }))}
                        />
                      )}
                      <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">Insira um nome fantasia.</Form.Control.Feedback>
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="customer.cnpj">
                      <Form.Label>CNPJ</Form.Label>
                      {loading ? (
                        <Skeleton height={38} />
                      ) : (
                        <MaskedInput
                          mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                          guide={false}
                          required
                          type="text"
                          placeholder="CNPJ"
                          value={body.cnpj || ''}
                          onChange={(e) => setBody((prevBody) => ({ ...prevBody, cnpj: e.target.value }))}
                          render={(ref, props) => <Form.Control ref={ref} {...props} />}
                        />
                      )}
                      <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">Insira um CNPJ.</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="customer.stateRegistration">
                      <Form.Label>Inscrição estadual</Form.Label>
                      {loading ? (
                        <Skeleton height={38} />
                      ) : (
                        <Form.Control
                          type="text"
                          placeholder="Inscrição estadual"
                          value={body.stateRegistration || ''}
                          onChange={(e) => setBody((prevBody) => ({ ...prevBody, stateRegistration: e.target.value }))}
                        />
                      )}
                      <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">Insira uma inscrição estadual.</Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                </>
              )}
            </Form>
          </Col>
        </Row>
      </div>

      <div className="border-top text-end pt-3">
        {loading ? (
          <Skeleton width={100} height={38} />
        ) : (
          <IconButton
            className="ms-2"
            iconClassName="fs--2 me-1"
            variant="falcon-primary"
            size="sm"
            icon="check"
            onClick={handleSubmit}
          >
            Salvar
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default CustomerInfo;