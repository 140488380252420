import { blackListPaginated, blacklistPaginatedContent } from 'types/BlackListTypes';
import useHttp from '../helpers/HttpMethods';

class BlackListService {
  /**
   * Busca todas as blacklists.
   * @param {string} filter - Filtro em formato de string.
   * @returns {Promise<blackListPaginated>} - Pre tickets em formato paginado.
   */
  static async list(filter: string): Promise<blackListPaginated> {
    return await useHttp().get(`/blacklist?${filter}`);
  }

  /**
   * Cria uma nov blacklist.
   * @param {{sender: string}} body - Conteúdo para criar uma blacklist.
   * @returns {Promise< blacklistPaginatedContent>} - Retorna a blacklist criada.
   */
  static async add(body: {sender: string}): Promise<blacklistPaginatedContent> {
   return await useHttp().post(body, `/blacklist`);
  }

  /**
   * Exclui uma blacklist.
   * @param {number} id - ID da blacklist a ser excluída.
   * @returns {Promise<Response>} - Retorno padrão de uma request.
   */
  static async delete(id: number): Promise<Response> {
    return await useHttp().del(`/blacklist/${id}`);
  }
}

export default BlackListService;