import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FalconComponentCard from "components/common/FalconComponentCard";
import Flex from "components/common/Flex";
import { useState } from "react";
import { Table } from "react-bootstrap";

const AssetDetailsFromRMM = ({ data }) => {
  const [showBody, setShowBody] = useState(true);
  return (
    <FalconComponentCard className="w-100 mb-3">
      <Flex
        className="p-3 bg-100 w-100 rounded-top-4"
        onClick={() => setShowBody(!showBody)}
        role="button" justifyContent="between">
        <h6 className="m-0">DETALHES DO DATTO RMM</h6>
        <FontAwesomeIcon icon={showBody ? faChevronUp : faChevronDown} size="sm" />
      </Flex>
      {showBody &&
        <Table className="">
          <tbody>
            <tr style={{ borderColor: 'transparent' }}>
              <TableTdItem name="Hostname" value={data.hostname} />
              <TableTdItem name="Descrição" value={data.description} />
              <TableTdItem name="Tipo" value={data.deviceTypeCategory + ' > ' + data.deviceTypeType} />
            </tr>
            <tr style={{ borderColor: 'transparent' }}>
              <TableTdItem name="Domínio" value={data.domain} />
              <TableTdItem name="Endereço IP Interno" value={data.intIpAddress} />
              <TableTdItem name="Endereço IP Externo" value={data.extIpAddress} />
            </tr>
            <tr style={{ borderColor: 'transparent' }}>
              <TableTdItem name="Status de Ger. de Soft." value={data.softwareStatus} />
              <TableTdItem name="Status Antivírus" value={data.antivirusStatus} />
              <TableTdItem name="Status Patch" value={data.patchStatus} />
            </tr>
            <tr style={{ borderColor: 'transparent' }}>
              <TableTdItem name="Fabricante" value={data.manufacturer} />
              <TableTdItem name="Modelo" value={data.model} />
              <TableTdItem name="Número de série" value={data.serialNumber} />
            </tr>
            <tr style={{ borderColor: 'transparent' }}>
              <TableTdItem name="SO" value={data.operatingSystem} />
              <TableTdItem name="Arquitetura" value={`${data.a64bit ? '64' : '32'} Bit`} />
              <TableTdItem name="Placa Mãe" value={data.baseBoard} />
            </tr>
            <tr style={{ borderColor: 'transparent' }}>
              <TableTdItem name="Processador" value={data.processor} />
              <TableTdItem name="Memória RAM" value={Math.ceil(data.totalPhysicalMemory / (1024 ** 3)) + ' GB'} />
              <TableTdItem name="Último Usuário" value={data.lastLoggedUser} />
            </tr>
          </tbody>
        </Table>
      }
    </FalconComponentCard>
  );
};

export default AssetDetailsFromRMM;

export const TableTdItem = ({ name, value, className }) => {
  return (
    <td style={{ width: '33%' }} className={`pb-0 ${className}`}>
      <strong>{name}</strong>: {value || '---'}
    </td>
  );
};
