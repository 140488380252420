import { faCog, faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FalconComponentCard from "components/common/FalconComponentCard";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import DialogModal from "components/modals/DialogModal";
import ProfileEditModal from "components/modals/ProfileEditModal";
import ProfilePermissionModal from "components/modals/ProfilePermissionModal";
import ProfileRegistrationModal from "components/modals/ProfileRegistrationModal";
import Pagination from "components/pagination";
import FailedRequest from "components/requests-response/FailedRequest";
import SuccessRequest from "components/requests-response/SuccessRequest";
import useApi from 'helpers/api';
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import ProfilesService from "services/Profiles.service";

const ProfileRegistration = () => {
  const api = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAlertSuccess, setShowAlertSuccess] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState({});
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showProfilePermissionModal, setShowProfilePermissionModal] = useState(false);
  const [error, setError] = useState({});
  const [success, setSuccess] = useState('');
  const [profiles, setProfiles] = useState({
    content: [
      { name: '', agents: [], isAdmin: false }
    ]
  });
  const [filters, setFilters] = useState({
    page: 0,
    size: 20,
  });

  const getData = async () => {
    setIsLoading(true);
    const queryParams = new URLSearchParams(filters).toString();
    const response = await ProfilesService.list(queryParams);
    if (response.content) setProfiles(response);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, [filters]);

  const columns = [
    {
      accessorKey: 'name',
      header: 'Nome',
      meta: {
        headerProps: { className: 'text-900' }
      },
    },
    {
      accessorKey: 'agents',
      header: 'Agentes',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const { agents } = rowData.row.original;
        return (
          agents.map((agent, index) => (
            <span key={index}>
              {agent.firstName}
              {index !== agents.length - 1 && ', '}
            </span>
          ))
        )
      }
    },
    {
      accessorKey: 'isAdmin',
      header: 'Administrador',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const { isAdmin } = rowData.row.original;
        return (
          isAdmin
            ? <span className="text-success">Sim</span>
            : <span className="text-danger">Não</span>


        );
      }
    },
    {
      accessorKey: 'actions',
      header: 'Ações',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const item = rowData.row.original;
        return (
          <>
            <Button size="sm" variant="link" onClick={() => handleSetup(item)} disabled={item.isAdmin}>
              <FontAwesomeIcon icon={faCog} size="sm" />
            </Button>
            <Button size="sm" variant="link" onClick={() => handleEdit(item)}>
              <FontAwesomeIcon icon={faPen} size="sm" />
            </Button>
            <Button size="sm" variant="link" onClick={() => handleDelete(item)} disabled={item.isAdmin}>
              <FontAwesomeIcon icon={faTrash} size="sm" />
            </Button>
          </>
        );
      }
    }
  ];

  const table = useAdvanceTable({
    data: profiles.content,
    columns
  });

  const handleCloseModalRegister = () => {
    setShowModal(false);
  };

  const handleCloseModalEdit = () => {
    setShowModalEdit(false);
  }

  const handleCloseDialog = () => {
    setShowDialog(false);
  }

  const handleCloseProfilePermissionModal = () => {
    setShowProfilePermissionModal(false);
  };

  const handleEdit = item => {
    setSelectedProfile(item);
    setShowModalEdit(true);
  };

  const handleDelete = item => {
    setSelectedProfile(item);
    setShowDialog(true);
  };

  const handleSetup = item => {
    setSelectedProfile(item);
    setShowProfilePermissionModal(true);
  }

  const createProfile = async body => {
    const response = await ProfilesService.create(body);

    if (response) {
      getData();
      handleCloseModalRegister();
      setShowAlertSuccess(true);
      setSuccess("Perfil criado com sucesso");
    } else {
      setError(response);
      setShowAlertError(true);
    }
  };

  const editProfile = async bodyChange => {
    let response = await ProfilesService.edit(selectedProfile.id, bodyChange);
    if (response) {
      getData();
      handleCloseModalEdit();
      setSuccess("Perfil editado com sucesso");
      setShowAlertSuccess(true);
    } else {
      setError(response);
      setShowAlertError(true);
    }
  }

  const deleteProfile = async response => {
    if (response) {
      let request = await ProfilesService.delete(selectedProfile.id);
      if (request.ok) {
        getData();
        setSuccess("Perfil excluído com sucesso");
        setShowAlertSuccess(true);
      } else {
        setError(request);
        setShowAlertError(true);
      }
      handleCloseDialog();
    }
  };

  const managePermissions = async (permissionsToAdd, permissionsToRemove) => {
    if (permissionsToAdd.permissions.length != 0) {
      let response = await ProfilesService.associatePermissions(parseInt(selectedProfile.id), permissionsToAdd);
      if (response.ok) {
        getData();
        handleCloseProfilePermissionModal();
        setSuccess("Permissões alteradas com sucesso");
        setShowAlertSuccess(true);
      } else {
        setError(response);
        setShowAlertError(true);
      }
    }
    if (permissionsToRemove.permissions.length != 0) {
      let reqUnassociatePermissions = await ProfilesService.unassociatePermissions(parseInt(selectedProfile.id), permissionsToRemove);
      if (reqUnassociatePermissions.ok) {
        getData();
        handleCloseProfilePermissionModal();
        setSuccess("Permissões alteradas com sucesso");
        setShowAlertSuccess(true);
      } else {
        setError(response);
        setShowAlertError(true);
      }
    }
  };

  return (
    <>
      <SuccessRequest message={success} showAlert={showAlertSuccess} setShowAlert={() => setShowAlertSuccess(false)} />
      <FailedRequest message={error.description} showAlert={showAlertError} setShowAlert={() => setShowAlertError(false)} />
      <FalconComponentCard>
        <FalconComponentCard.Header title="Perfis de agentes">
          <div className="w-100 d-flex justify-content-between">
            <Button type="button" variant="primary" size="sm" onClick={() => setShowModal(true)}>
              <FontAwesomeIcon icon={faPlus} size="sm" className="me-2" />
              Novo
            </Button>
          </div>
        </FalconComponentCard.Header>
        <FalconComponentCard.Body>
          <ProfileEditModal
            showModal={showModalEdit}
            data={selectedProfile}
            handleClose={handleCloseModalEdit}
            handleSubmit={editProfile} />
          <ProfileRegistrationModal
            showModal={showModal}
            handleClose={handleCloseModalRegister}
            handleSubmit={createProfile} />
          <DialogModal
            message="Tem certeza que deseja excluir este perfil?"
            showModal={showDialog}
            handleClose={handleCloseDialog} response={deleteProfile} />
          <ProfilePermissionModal
            showModal={showProfilePermissionModal}
            handleClose={handleCloseProfilePermissionModal}
            data={selectedProfile}
            handleSubmit={managePermissions} />

          {isLoading ? (
            <Skeleton count={10} height={44} baseColor="#5E6E82" />
          ) : (
            <>
              <AdvanceTableProvider {...table}>
                <AdvanceTable
                  table
                  headerClassName="text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{

                    striped: true,
                    className: 'fs-10 mb-0 overflow-hidden'
                  }} />
              </AdvanceTableProvider>
              <Pagination
                data={profiles}
                filters={filters}
                setFilters={setFilters} />
            </>
          )}
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>

  );
};

export default ProfileRegistration;