import FalconComponentCard from "components/common/FalconComponentCard"
import { useEffect, useState } from "react"
import { Card, Col, Nav, Row, Tab, Table } from "react-bootstrap"
import Skeleton from "react-loading-skeleton"
import EmailNotificationTemplateService from "services/EmailNotificationTemplate.service"
import SimpleBar from "simplebar-react"
import NotificationItem from "./EmailNotificationItem"

const EmailNotification = () => {
  const [agentNotifications, setAgentNotifications] = useState([]);
  const [requesterNotifications, setRequesterNotifications] = useState([]);
  const [showModal, setShowModal] = useState(false)
  const [selectedNotification, setSelectedNotification] = useState(null)
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAgentNotifications();
    getRequesterNotifications();
  }, []);

  const getAgentNotifications = async () => {
    setIsLoading(true);

    const queryParams = new URLSearchParams({ recipientType: 'AGENT' }).toString();
    const response = await EmailNotificationTemplateService.list(queryParams);
    if (Array.isArray(response))
      setAgentNotifications(response);

    setIsLoading(false);
  };

  const getRequesterNotifications = async () => {
    setIsLoading(true);

    const queryParams = new URLSearchParams({ recipientType: 'REQUESTER' }).toString();
    const response = await EmailNotificationTemplateService.list(queryParams);
    if (Array.isArray(response))
      setRequesterNotifications(response);

    setIsLoading(false);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleSaveChanges = () => {
    if (selectedNotification) {
      setRequesterNotifications(
        requesterNotifications.map((notif) => (notif.id === selectedNotification.id ? selectedNotification : notif)),
      )
      setShowModal(false)
    }
  };

  const handleEdit = (notification) => {
    setSelectedNotification(notification)
    setShowModal(true)
  };

  return (
    <Row className="g-2">
      <Col xl={9} className="h-100">
        <FalconComponentCard>
          <FalconComponentCard.Header title="Notificações por email" />
          <FalconComponentCard.Body>
            <Tab.Container defaultActiveKey="agentNotification">
              <SimpleBar>
                <Card.Header className="p-0">
                  <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                    <Nav.Item>
                      <Nav.Link eventKey="agentNotification" className="d-flex align-items-center py-3 px-x1 mb-0">
                        <h5 className="mb-0 fs-9 text-600">Notificações do agente</h5>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="solicitantNotifications"
                        className="d-flex align-items-center py-3 px-x1 mb-0"
                      >
                        <h5 className="mb-0 fs-9 text-600">Notificações do solicitante</h5>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
              </SimpleBar>
              <Card.Body className="p-0">
                <Tab.Content>
                  <Tab.Pane eventKey="agentNotification">
                    {isLoading ?
                      <Skeleton count={9} />
                      :
                      <Table striped bordered hover>
                        <tbody>
                          {agentNotifications.map((notification) => (
                            <NotificationItem
                              key={notification.id}
                              itemData={notification}
                              refreshAgent={getAgentNotifications}
                              refreshRequester={getRequesterNotifications}
                            />
                          ))}
                        </tbody>
                      </Table>
                    }
                  </Tab.Pane>
                  <Tab.Pane eventKey="solicitantNotifications">
                    <Table striped bordered hover>
                      <tbody>
                        {requesterNotifications.map((notification, i) => (
                          <NotificationItem
                            key={notification.id}
                            itemData={notification}
                            refreshAgent={getAgentNotifications}
                            refreshRequester={getRequesterNotifications}
                          />
                        ))}
                      </tbody>
                    </Table>
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </FalconComponentCard.Body>
        </FalconComponentCard>
      </Col>
      <Col xl={3}>
        <FalconComponentCard style={{ height: "87vh" }}>
          <FalconComponentCard.Body>
            <p className="fw-bold">Notificações por e-mail</p>
            <p className="fs-10">
              No MSP Desk, você pode configurar o envio automático de notificações por e-mail tanto para os agentes
              quanto para os solicitantes sempre que determinados eventos ocorrerem. Essas notificações podem ser
              totalmente personalizadas e incluir conteúdo dinâmico utilizando espaços reservados. Por exemplo, é
              possível notificar os clientes sempre que os agentes adicionarem comentários ou notas públicas aos seus
              tickets, com um e-mail personalizado que contém um link direto para o ticket.
            </p>
            <br />
            <p className="fs-10">
              Se o seu plano permitir suporte a múltiplos idiomas, você terá a possibilidade de adicionar diferentes
              idiomas e ajustar os modelos de e-mail para cada um deles. O ícone /information/ indica que as traduções
              dessas notificações para outros idiomas podem não estar atualizadas.
            </p>
          </FalconComponentCard.Body>
        </FalconComponentCard>
      </Col>
    </Row>
  )
}

export default EmailNotification

