import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PreviewTicket from 'components/app/support-desk/tickets-preview/PreviewTicket';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import useApi from 'helpers/api';
import { formatDateInFromNow, formatDateTime } from 'helpers/utils';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useAdvanceTable from './useAdvanceTable';


export const PrioritySelect = ({ title, color, data, className, style }) => {
  return (
    <div
      style={style}
      className={classNames('d-flex align-items-center gap-2', className)}
    >
      <div style={{ '--falcon-circle-progress-bar': data }}>
        <svg
          className="circle-progress-svg"
          width="26"
          height="26"
          viewBox="0 0 120 120"
        >
          <circle
            className="progress-bar-rail"
            cx="60"
            cy="60"
            r="54"
            fill="none"
            strokeLinecap="round"
            strokeWidth="12"
          ></circle>
          <circle
            className="progress-bar-top"
            cx="60"
            cy="60"
            r="54"
            fill="none"
            strokeLinecap="round"
            stroke={color}
            strokeWidth="12"
          ></circle>
        </svg>
      </div>
      <h6 className="mb-0 text-700">{title}</h6>
    </div>
  );
};

PrioritySelect.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  data: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export const columns = [
  {
    accessorKey: 'customer.name',
    header: 'Cliente',
    meta: {
      headerProps: { className: 'fs-10 ps-2' },
      cellProps: {
        className: 'py-2 white-space-nowrap pe-3 pe-xxl-4 ps-2',
      },
    },
    cell: rowData => {
      const { customer } = rowData.row.original;
      return (
        <Flex alignItems="center" className="position-relative py-1">
          <h6 className="mb-0" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            <Link
              to={`/registration/customers/${customer.id}`}
              className="fs-10 stretched-link text-900"
            >
              {customer.type == 'JURIDICAL_PERSON' ? customer.fantasyName : customer.name}
            </Link>
          </h6>
        </Flex>
      );
    }
  },
  {
    accessorKey: 'subject',
    header: 'Assunto',
    meta: {
      headerProps: { className: 'fs-10' },
      cellProps: {
        className: 'py-2 pe-4'
      },
    },
    cell: rowData => {
      const [showPreview, setShowPreview] = useState(false);
      const { subject, code, id, status, description } = rowData.row.original;

      return (
        <h6 className='mb-0' style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          <Link
            to={`/tickets/${id}`}
            className="fs-10 fw-700"
            onMouseEnter={() => setShowPreview(true)}
            onMouseLeave={() => setShowPreview(false)}>
            #{code} {subject}
          </Link>
          {showPreview && <PreviewTicket data={{ subject, code, id, status, description }} />}
        </h6>
      );
    }
  },
  {
    accessorKey: 'status.description',
    header: 'Status',
    meta: {
      headerProps: { className: 'fs-10' },
      cellProps: {
        className: 'pe-4'
      },
    },
    cell: rowData => {
      const { status } = rowData.row.original;
      return (
        <SoftBadge style={{ backgroundColor: status.color }} className="me-2 fs-11">
          {status.description}
        </SoftBadge>
      );
    }
  },
  {
    accessorKey: 'priority.description',
    header: 'Priori.',
    meta: {
      headerProps: { className: 'fs-10' },
      cellProps: {
        className: 'pe-4'
      },
    },
    cell: rowData => {
      const { priority } = rowData.row.original;
      return (
        <PrioritySelect
          title={priority.description}
          color={priority.color}
          data={priority.data} />
      );
    }
  },
  {
    accessorKey: 'serviceGroup.name',
    header: 'Grupo',
    meta: {
      headerProps: { className: 'fs-10' }
    },
    cell: rowData => {
      const { serviceGroup } = rowData.row.original;
      return <h6 className='fs-10 text-700' style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{serviceGroup ? serviceGroup.name : '---'}</h6>;
    }
  },
  {
    accessorKey: 'agent.firstName',
    header: 'Agente',
    meta: {
      headerProps: { className: 'fs-10' },
      cellProps: { className: 'pe-4 pe-xxl-4 ps-2' },
    },
    cell: rowData => {
      const { agent } = rowData.row.original;
      return <h6 className='fs-10 d-flex justify-content-end align-items-center text-700' style={{ width: '80px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{agent ? agent.firstName : '---'}</h6>;
    }
  },
  {
    accessorKey: 'statusSlaResponse.description',
    header: "Vencimento Resposta",
    meta: {
      headerProps: { className: 'fs-10' }
    },
    cell: rowData => {
      const { id, statusSlaResponse, responseSlaExpiration } = rowData.row.original;

      const api = useApi();
      const [slaPause, setSlaPause] = useState({});

      const isValidSla = (statusSlaResponse && statusSlaResponse.description === "Dentro do prazo") || (statusSlaResponse && statusSlaResponse.description === "Prestes a estourar");
      const isPaused = (statusSlaResponse && statusSlaResponse.description === 'SLA pausado') || (statusSlaResponse && statusSlaResponse.description === 'SLA pausado');


      useEffect(() => {
        if (isPaused) {
          getActiveSlaPause();
        }
      }, [id]);

      const getActiveSlaPause = async () => {
        const response = await api.slaPauses.getActivePause(id);
        response && setSlaPause(response);
      };

      return (
        <div className='d-flex'>
          <FontAwesomeIcon icon={faClock} size='sm' style={{ color: statusSlaResponse && statusSlaResponse.color }} />
          <h6 className='ms-1 text-start fs-10 text-700' style={{ width: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {isValidSla
              ? formatDateTime(responseSlaExpiration)
              : isPaused
                ? <OverlayTrigger overlay={<Tooltip>{slaPause.pauseReason && slaPause.pauseReason.name}</Tooltip>}>
                  <span>{statusSlaResponse && statusSlaResponse.description}</span>
                </OverlayTrigger>

                : <OverlayTrigger overlay={<Tooltip>{responseSlaExpiration ? formatDateTime(responseSlaExpiration) : '---'}</Tooltip>}>
                  <span>{statusSlaResponse && statusSlaResponse.description}</span>
                </OverlayTrigger>
            }
          </h6>
        </div>
      );
    }
  },
  {
    accessorKey: 'statusSlaSolution.description',
    header: "Vencimento Solução",
    meta: {
      headerProps: { className: 'fs-10' }
    },
    cell: rowData => {
      const { id, statusSlaSolution, solutionSlaExpiration } = rowData.row.original;
      const api = useApi();

      const [slaPause, setSlaPause] = useState({});
      const isValidSla = (statusSlaSolution && statusSlaSolution.description === "Dentro do prazo") || (statusSlaSolution && statusSlaSolution.description === "Prestes a estourar");
      const isPaused = (statusSlaSolution && statusSlaSolution.description === 'SLA pausado') || (statusSlaSolution && statusSlaSolution.description === 'SLA pausado');

      useEffect(() => {
        if (isPaused) {
          getActiveSlaPause();
        }
      }, [id]);

      const getActiveSlaPause = async () => {
        const response = await api.slaPauses.getActivePause(id);
        response && setSlaPause(response);
      };

      return (
        <div className='d-flex'>
          <FontAwesomeIcon icon={faClock} size='sm' style={{ color: statusSlaSolution && statusSlaSolution.color }} />
          <h6 className='ms-1 text-start fs-10 text-700' style={{ width: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {isValidSla
              ? formatDateTime(solutionSlaExpiration)
              : isPaused
                ? <OverlayTrigger overlay={<Tooltip>{slaPause.pauseReason && slaPause.pauseReason.name}</Tooltip>}>
                  <span>{statusSlaSolution && statusSlaSolution.description}</span>
                </OverlayTrigger>

                : <OverlayTrigger overlay={<Tooltip>{solutionSlaExpiration ? formatDateTime(solutionSlaExpiration) : '---'}</Tooltip>}>
                  <span>{statusSlaSolution && statusSlaSolution.description}</span>
                </OverlayTrigger>
            }
          </h6>
        </div>
      );
    }
  },
  {
    accessorKey: 'creationDate',
    header: 'Criado',
    meta: {
      headerProps: { className: 'fs-10 pspy-2 text-start' },
    },
    cell: rowData => {
      const { creationDate } = rowData.row.original;
      return <h6 className='text-start fs-10 text-700' style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {creationDate ? formatDateInFromNow(creationDate) : '---'}
      </h6>;
    }
  },
  {
    accessorKey: 'lastUpdateDate',
    header: 'Atualizado',
    meta: {
      headerProps: { className: 'fs-10 py-2 pe-4 text-start' },
    },
    cell: rowData => {
      const { lastUpdateDate } = rowData.row.original;
      return <h6 className='py-2 pe-4 text-start fs-10 text-700' style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {lastUpdateDate ? formatDateInFromNow(lastUpdateDate) : '---'}
      </h6>;
    }
  }
];

const useTicketsTable = options => {
  const table = useAdvanceTable({
    columns,
    ...options
  });

  return table;
};

export default useTicketsTable;
