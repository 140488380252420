import { emailBoxCreationModel, emailBoxesContent, emailBoxesPaginated } from 'types/EmailBoxTypes';
import useHttp from '../helpers/HttpMethods';

class EmailBoxService {
  /**
   * Busca as caixas de email.
   * @param {string} filter - Filtro em formato de string.
   * @returns {Promise<emailBoxesPaginated | Response>} - Caixas de email em formato paginado.
   */
  static async list(filter: string): Promise<emailBoxesPaginated | Response> {
    return await useHttp().get(`/email-boxes?${filter}`);
  }

  /**
   * Cria uma nova caixa de email.
   * @param {emailBoxCreationModel} body - Conteúdo para criar uma caixa de email.
   * @returns {Promise<emailBoxesContent>} - Retorna a caixa de email criada.
   */
  static async create(body: emailBoxCreationModel): Promise<emailBoxesContent> {
   return await useHttp().post(body, `/email-boxes`);
  }

  /**
   * Atualiza uma caixa de email.
   * @param {number} id - ID da caixa de email.
   * @param {emailBoxCreationModel} body - Conteúdo com as atualizações da emailbox.
   * @returns {Promise<Response | emailBoxesContent>} - Retorno padrão de uma request ou o objeto modificado.
   */
  static async edit(id: number, body: emailBoxCreationModel): Promise<Response | emailBoxesContent> {
    return await useHttp().put(body, `/email-boxes/${id}`);
  }

  /**
   * Exclui uma caixa de email.
   * @param {number} id - ID da caixa de email a ser excluída.
   * @returns {Promise<Response>} - Retorno padrão de uma request.
   */
  static async delete(id: number): Promise<Response> {
    return await useHttp().del(`/email-boxes/${id}`);
  }
}

export default EmailBoxService;