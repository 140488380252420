import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Pagination = ({ data, filters, setFilters }) => {
  const totalPages = data.totalPages;
  const pagesToShow = 3;
  const startIndex = data.totalElementsPerPage * filters.page + 1;
  const endIndex = Math.min(startIndex + data.totalElementsPerPage - 1, data.totalElements);
  let startPage = Math.max(0, filters.page - 1);
  let endPage = Math.min(totalPages - 1, startPage + pagesToShow - 1);

  if (endPage - startPage < pagesToShow - 1) {
    startPage = Math.max(0, endPage - pagesToShow + 1);
  }

  const toFirstPage = (event) => {
    event.preventDefault();
    handleSetFilters('page', 0);
  };

  const toPreviousPage = (event) => {
    event.preventDefault();
    if (filters.page !== 0) {
      handleSetFilters('page', filters.page - 1);
    }
  };

  const toNextPage = (event) => {
    event.preventDefault();
    if (data.hasNextPage) {
      handleSetFilters('page', filters.page + 1);
    }
  };

  const toLastPage = (event) => {
    event.preventDefault();
    handleSetFilters('page', totalPages - 1);
  };

  const seeMore = () => {
    handleSetFilters('size', filters.size === 20 ? 50 : 20);
  };

  const changePage = (event) => {
    event.preventDefault();
    const { dataset } = event.target;
    const { value } = dataset;
    handleSetFilters('page', parseInt(value));
  };

  const handleSetFilters = (name, value) => {
    setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
  };

  const pageButtons = [];
  for (let i = startPage; i <= endPage; i++) {
    pageButtons.push(
      <li className={"page-item" + (filters.page === i ? " active" : "")} key={i}>
        <a className="page-link" href="#" data-value={i} onClick={changePage}>
          {i + 1}
        </a>
      </li>
    );
  }

  return (
    <footer className="w-100 d-flex justify-content-between align-items-center">
      <div>
        <span style={{ fontSize: 13 }}>
          Mostrando de {startIndex} à {endIndex} de {data.totalElements} itens   —
        </span>
        <button className="btn btn-sm btn btn-link p-0" style={{ fontSize: 13 }} onClick={seeMore}>
          {filters.size === 20 ? "Ver mais" : "Ver menos"}
        </button>
      </div>
      <nav className="mt-3">
        <ul className="pagination">
          <li className="page-item">
            <a className="page-link" href="#" aria-label="Previous" onClick={toFirstPage}>
              <span aria-hidden="true">
                <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: 10 }} />
                <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: 10 }} />
              </span>
              <span className="sr-only">Previous</span>
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#" aria-label="Previous" onClick={toPreviousPage}>
              <span aria-hidden="true">
                <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: 10 }} />
              </span>
              <span className="sr-only">Previous</span>
            </a>
          </li>
          {pageButtons}
          <li className="page-item">
            <a className="page-link" href="#" aria-label="Next" onClick={toNextPage}>
              <span aria-hidden="true">
                <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 10 }} />
              </span>
              <span className="sr-only">Next</span></a>
          </li>
          <li className="page-item">
            <a className="page-link" href="#" aria-label="Next" onClick={toLastPage}>
              <span aria-hidden="true">
                <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 10 }} />
                <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 10 }} />
              </span>
              <span className="sr-only">Next</span></a>
          </li>
        </ul>
      </nav>
    </footer>
  );
};

export default Pagination;
