import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import example from 'assets/csv/catalogo_test.csv';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import Loading from 'components/loading/Loading';
import FailedRequest from 'components/requests-response/FailedRequest';
import SuccessRequest from 'components/requests-response/SuccessRequest';
import useApi from 'helpers/api';
import { useRef, useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import ServiceCatalogService from 'services/ServiceCatalog.service';

const ImportCatalogModal = ({ show, close, refreshData }) => {
  const api = useApi();
  const [selectedFile, setSelectedFile] = useState(null);
  const inputFile = useRef(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const upload = async () => {
    setLoading(true);
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      const request = await ServiceCatalogService.importCatalogs(formData);
      if (request.ok) {
        setShowSuccess(true);
        setSelectedFile(null);
        refreshData();
        close();
      } else {
        const response = await request.json();
        setError(response);
        setShowError(true);
      }
    }
    setLoading(false);
  };

  const handleFileSelect = e => {
    setSelectedFile(e.target.files[0]);
  };

  return (
    <>
      <SuccessRequest
        message="Catálogos importados com sucesso"
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)} />

      <FailedRequest
        message={error.userMessage}
        showAlert={showError}
        setShowAlert={() => setShowError(false)} />

      {loading && <Loading />}
      <Modal show={show} onHide={close} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Importar catálogos</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h4>Orientações para o Upload do Arquivo CSV</h4>
          <p>Para garantir que a importação do catálogo de serviços funcione corretamente, siga as orientações abaixo ao criar o arquivo CSV:</p>

          <h5>Estrutura Esperada do Arquivo</h5>
          <p>O arquivo CSV deve conter três colunas obrigatórias, que representam o catálogo, a categoria e a subcategoria dos serviços, respectivamente. A ordem das colunas deve ser:</p>
          <ul>
            <li><strong>Catálogo:</strong> Nome do catálogo ao qual o serviço pertence.</li>
            <li><strong>Categoria:</strong> Categoria dentro do catálogo.</li>
            <li><strong>Subcategoria:</strong> Subcategoria específica da categoria.</li>
          </ul>

          <h5>Delimitador Suportado</h5>
          <p>O <strong>delimitador suportado</strong> nesta implementação é a <strong>vírgula</strong> (<code>,</code>). Portanto, certifique-se de que os valores estejam separados por vírgulas.</p>

          <h5>Requisitos Importantes</h5>
          <ul>
            <li>O arquivo <strong>deve ter a extensão .csv</strong>.</li>
            <li>Certifique-se de que o conteúdo do arquivo segue a estrutura e ordem descritas acima.</li>
            <li><strong>Linhas em branco ou com colunas faltantes</strong> podem causar erros durante a importação, por isso, revise o arquivo antes de enviá-lo.</li>
          </ul>

          <h5>Como salvar o arquivo</h5>
          <ol>
            <li>Use editores de planilhas como <strong>Microsoft Excel</strong>, <strong>Google Sheets</strong> ou editores de texto como <strong>Notepad</strong> para criar o arquivo.</li>
            <li>Ao salvar no <strong>Excel</strong> ou <strong>Google Sheets</strong>, escolha a opção <strong>Salvar como</strong> e selecione o formato <strong>CSV (Comma Delimited)</strong>.</li>
            <li>Verifique se o arquivo gerado tem a extensão <strong>.csv</strong>.</li>
          </ol>

          <h5>O que ocorre em caso de erro</h5>
          <p>Se o arquivo enviado estiver no <strong>formato incorreto</strong> (por exemplo, um arquivo de texto ou planilha com extensão <code>.txt</code> ou <code>.xlsx</code>), a importação será recusada, e você receberá uma mensagem de erro indicando que <strong>somente arquivos CSV são permitidos</strong>.</p>
          <p>Se o delimitador não for uma vírgula, a importação falhará, pois o sistema não será capaz de separar corretamente os valores.</p>

          <p>Seguindo essas orientações, você garantirá que o arquivo seja corretamente importado para o sistema.</p>

          <h5>Exemplo de arquivo CSV</h5>

          <p className='mb-1'>
            Abaixo você encontra um exemplo de arquivo CSV aceito pelo MSP Desk para importação de catálogos:
          </p>

          <a href={example} download>Exemplo CSV</a>

          <div className='mt-3'>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="paperclip"
              transform="shrink-2"
              iconAlign="middle"
              onClick={() => inputFile.current.click()}
              className="me-2"
            >
              <span className="d-none d-md-inline-block ms-1">Escolher arquivo</span>
            </IconButton>

            <input type="file" ref={inputFile} onChange={handleFileSelect} style={{ display: 'none' }} />
          </div>
        </Modal.Body>
        <Modal.Footer as={Flex} justifyContent="end">
          <Button onClick={() => upload()}>
            <FontAwesomeIcon icon={faArrowUp} size='sm' className='me-1' />
            Importar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImportCatalogModal;
