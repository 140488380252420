import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import EmptyList from 'components/common/EmptyList'; // Importe o EmptyList
import DialogModal from 'components/modals/DialogModal';
import TicketAppointmentRegistrationModal from 'components/modals/TicketAppointmentRegistrationModal';
import TicketAppointmentViewModal from 'components/modals/TicketAppointmentViewModal';
import Pagination from 'components/pagination';
import FailedRequest from 'components/requests-response/FailedRequest';
import SuccessRequest from 'components/requests-response/SuccessRequest';
import useApi from 'helpers/api';
import { formatDateTime, formatTime } from 'helpers/utils';
import useTicketsAppointmentsTable from 'hooks/useTicketsAppointmentsTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useEffect, useState } from "react";
import { Button, Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

const TicketAppointments = ({ ticketId, ticketStatus, refreshLogs, refreshData }) => {
  const api = useApi();
  const [appointments, setAppointments] = useState({ content: [] });
  const [summary, setSummary] = useState({});
  const [filters, setFilters] = useState({
    page: 0,
    size: 10
  });

  const [showModalRegister, setShowModalRegister] = useState(false);
  const [showModalView, setShowModalView] = useState(false);
  const [showAlert, setShowAlert] = useState(true);
  const [showAlertError, setShowAlertError] = useState(false);
  const [error, setError] = useState({});
  const [success, setSuccess] = useState('');

  const [selectedAppointment, setSelectedAppointment] = useState({});
  const [showDialogConfirm, setShowDialogConfirm] = useState(false);

  const hasTicketId = ticketId != undefined;
  const isAppointmentEmpty = Object.keys(selectedAppointment).length === 0;
  const isEmpty = hasTicketId && appointments.content && appointments.content.length === 0;

  useEffect(() => {
    getSummary();
  }, [ticketId]);

  useEffect(() => {
    list();
  }, [ticketId, filters]);

  const columns = [
    {
      accessorKey: 'typeService',
      header: 'Tipo',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const { typeService } = rowData.row.original;
        return <p>{typeService && typeService.description}</p>
      }
    },
    {
      accessorKey: 'description',
      header: 'Descrição',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const { description } = rowData.row.original;
        return (
          <OverlayTrigger overlay={<Tooltip>{description}</Tooltip>}>
            <p style={{ maxWidth: 80, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {description}
            </p>
          </OverlayTrigger>
        );
      }
    },
    {
      accessorKey: 'date',
      header: 'Data',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const { date } = rowData.row.original;
        return <p>{formatDateTime(date)}</p>
      }
    },
    {
      accessorKey: 'timeSpent',
      header: 'Horas trabalhadas',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const { timeSpent } = rowData.row.original;
        return <p>{formatTime(timeSpent)}</p>
      }
    },
    {
      accessorKey: 'agent',
      header: 'Agente',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const { user } = rowData.row.original;
        return <p>{user.firstName}</p>
      }
    },
    {
      accessorKey: 'actions',
      header: 'Ações',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const item = rowData.row.original;
        return (
          <>
            <Button variant='link' size='sm' onClick={() => handleView(item)}>
              <FontAwesomeIcon icon={faEye} size='sm' />
            </Button>
            <Button variant='link' size='sm' onClick={() => handleEdit(item)} disabled={ticketStatus === 'COMPLETED' || ticketStatus === 'CLOSED'}>
              <FontAwesomeIcon icon={faPen} size="sm" />
            </Button>
            <Button variant='link' size='sm' onClick={() => handleDelete(item)} disabled={ticketStatus === 'COMPLETED' || ticketStatus === 'CLOSED'}>
              <FontAwesomeIcon icon={faTrash} size="sm" />
            </Button>
          </>
        );
      }
    }
  ];

  const list = async () => {
    if (hasTicketId) {
      const queryParams = new URLSearchParams(filters).toString();
      const response = await api.appointments.list(ticketId, queryParams);
      setAppointments(response);
    }
  };

  const getSummary = async () => {
    if (hasTicketId) {
      let response = await api.appointments.summary(ticketId);
      setSummary(response);
    }
  };

  const createAppointment = async body => {
    let response = await api.appointments.create(ticketId, body);
    if (response.id) {
      setSuccess("Apontamento criado com sucesso");
      setShowAlert(true);
      list();
      refreshLogs();
      refreshData();
    } else {
      setError(response);
      setShowAlertError(true);
    }
    handleCloseModalRegister();
  };

  const editAppointment = async body => {
    let response = await api.appointments.edit(ticketId, selectedAppointment.id, body);
    if (response.id) {
      setSuccess("Apontamento editado com sucesso");
      setShowAlert(true);
      list();
      refreshLogs();
    } else {
      setError(response);
      setShowAlertError(true);
    }
    handleCloseModalRegister();
  };

  const deleteAppointment = async response => {
    if (response && hasTicketId) {
      let request = await api.appointments.delete(ticketId, selectedAppointment.id);
      if (request.ok) {
        list();
        refreshLogs();
      } else {
        setError(request);
        setShowAlertError(true);
      }
      handleCloseDialog();
    }
  };

  const handleActionModal = body => {
    isAppointmentEmpty
      ? createAppointment(body)
      : editAppointment(body);
  };

  const handleRegister = () => {
    setSelectedAppointment({});
    setShowModalRegister(true);
  };

  const handleView = item => {
    setSelectedAppointment(item);
    setShowModalView(true);
  };

  const handleEdit = item => {
    setSelectedAppointment(item);
    setShowModalRegister(true);
  };

  const handleDelete = item => {
    setSelectedAppointment(item);
    setShowDialogConfirm(true);
  };

  const handleCloseModalRegister = () => {
    setShowModalRegister(false);
  };

  const handleCloseDialog = () => {
    setShowDialogConfirm(false);
  };

  const table = useTicketsAppointmentsTable({
    data: appointments.content,
    columns
  });

  return (
    <>
      <Row className='gx-2 mb-3'>
        <Col md={3}>
          <Card>
            <Card.Body>
              <h6 className="text-600 mb-2">Horas internas</h6>
              <h5>{summary.internalHours}</h5>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <Card.Body>
              <h6 className="text-600 mb-2">Horas externas</h6>
              <h5>{summary.externalHours}</h5>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <Card.Body>
              <h6 className="text-600 mb-2">Total horas</h6>
              <h5>{summary.totalHours}</h5>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <Card.Body>
              <h6 className="text-600 mb-2">Apontamentos</h6>
              <h5>{summary.count}</h5>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <FalconComponentCard>
        <FalconComponentCard.Header title="Horas apontadas">
          <div className="w-100 d-flex justify-content-between">
            <Button type="button" disabled={ticketStatus === 'COMPLETED' || ticketStatus === 'CLOSED'} variant="primary" size="sm" onClick={() => handleRegister()}>
              <FontAwesomeIcon icon={faPlus} size="sm" className="me-2" />
              Novo
            </Button>
          </div>
        </FalconComponentCard.Header>
        <FalconComponentCard.Body>
          <SuccessRequest
            message={success}
            showAlert={showAlert}
            setShowAlert={() => setShowAlert(false)} />
          <FailedRequest
            message={error.description}
            showAlert={showAlertError}
            setShowAlert={() => setShowAlertError(false)} />
          <TicketAppointmentViewModal data={selectedAppointment} showModal={showModalView} handleClose={() => setShowModalView(false)} />
          <TicketAppointmentRegistrationModal
            showModal={showModalRegister}
            handleClose={handleCloseModalRegister}
            handleSubmit={handleActionModal}
            data={selectedAppointment}
            title={isAppointmentEmpty ? "Novo apontamento" : "Editar apontamento"} />

          <DialogModal
            message="Tem certeza que deseja excluir este apontamento?"
            showModal={showDialogConfirm}
            handleClose={handleCloseDialog}
            response={deleteAppointment} />

          {isEmpty ? (
            <div style={{ minHeight: '300px' }}>
              <EmptyList imgSize={250} />
            </div>
          ) : (
            <>
              <AdvanceTableProvider {...table}>
                <AdvanceTable
                  table
                  headerClassName="text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    bordered: true,
                    striped: true,
                    className: 'fs-10 mb-0 overflow-hidden'
                  }} />
              </AdvanceTableProvider>
              <Pagination
                data={appointments}
                filters={filters}
                setFilters={setFilters} />
            </>
          )}
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default TicketAppointments;