import { MSPTalksSettings, MSPTalksSettingsInput } from 'types/MSPTalksSettingsTypes';
import useHttp from '../helpers/HttpMethods';

class MSPTalksSettingsService {
  static async find(): Promise<MSPTalksSettings> {
    return await useHttp().get('/msptalks/settings');
  };

  static async create(): Promise<MSPTalksSettings> {
    return await useHttp().postWithoutBodyInFormatJson('/msptalks/settings');
  };

  static async update(body: MSPTalksSettingsInput): Promise<MSPTalksSettings> {
    return await useHttp().put(body, '/msptalks/settings');
  };

  static async delete(): Promise<void> {
    return await useHttp().del('/msptalks/settings');
  };

  static async generateToken(): Promise<void> {
    return await useHttp().putWithoutBodyInFormatText('/msptalks/settings/token');
  };
};

export default MSPTalksSettingsService;