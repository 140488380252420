import { PauseReasonModel, PauseReasonsPaginated } from 'types/PauseReasonsTypes';
import useHttp from '../helpers/HttpMethods';

class PauseReasonsService {
  /**
   * @param {string} filters - Filtro para pesquisa dos motivos de pausa.
   * Ex: page=0&size=10&includeInactives=false
   * @returns {Promise<PauseReasonsPaginated>}
   */
  static async list(filters: string): Promise<PauseReasonsPaginated> {
    return await useHttp().get(`/pause-reasons?${filters}`);
  }
  /**
   * @param {{name: string}} body - Objeto com apenas a propriedade name que cria um
   * novo motivo de pausa.  
   * @returns {Promise<PauseReasonModel>}
   */
  static async create(body: {name: string}): Promise<PauseReasonModel> {
    return await useHttp().post(body, `/pause-reasons`);
  }
  /**
   * @param {number} id - id do item a ser alterado.
   * @param {{name: string, inactive: boolean}} body - Conteúdo a ser atualizado no
   * motivo de pausa.
   * @returns {Promise<Response | PauseReasonModel>} Retorna ou o objeto do motivo de pausa, atualizado.
   * Ou a Response padrão retornada pelo fetch.
   */
  static async edit(id: number, body: {name: string, inactive: boolean}): Promise<Response | PauseReasonModel> {
    return await useHttp().put(body, `/pause-reasons/${id}`);
  }
}

export default PauseReasonsService