import { SlaPolicy, SlaDetailed } from '../types/SlaPoliciesTypes';
import useHttp from '../helpers/HttpMethods';
import DefaultApiResponse from 'types/DefaultApiResponseType';

class SlaPoliciesService {
  /**
   * Busca todas as políticas de SLA.
   * @returns {Promise<SlaPolicy[]>}
   */
  static async slaPoliciesList(): Promise<SlaPolicy[]> {
    return await useHttp().get('/sla-policies');
  }

  /**
   * Busca uma política de SLA por ID.
   * @param {number} id - ID da política.
   * @returns {Promise<SlaDetailed>}
   */
  static async slaPoliciesFindById(id: number): Promise<SlaDetailed> {
    return await useHttp().get(`/sla-policies/${id}`);
  }

  /**
   * Ativa uma política de SLA.
   * @param {number} id - ID da política.
   * @returns {Promise<ApiResponse>}
   */
  static async slaPoliciesActivate(id: number): Promise<Response> {
    return await useHttp().putWithoutBody(`/sla-policies/${id}/activate`);
  }

  /**
   * Inativa uma política de SLA.
   * @param {number} id - ID da política.
   * @returns {Promise<ApiResponse>}
   */
  static async slaPoliciesInactivate(id: number): Promise<Response> {
    return await useHttp().del(`/sla-policies/${id}/inactivate`);
  }

  /**
   * Exclui uma política de SLA.
   * @param {number} id - ID da política.
   * @returns {Promise<ApiResponse>}
   */
  static async slaPoliciesDelete(id: number): Promise<Response> {
    return await useHttp().del(`/sla-policies/${id}`);
  }

  /**
   * Cria uma nova política de SLA.
   * @param {SlaPolicy} body - Dados da política.
   * @returns {Promise<ApiResponse>}
   */
  static async slaPoliciesCreate(body: SlaPolicy): Promise<Response> {
    return await useHttp().post(body, '/sla-policies');
  }

  /**
   * Edita uma política de SLA existente.
   * @param {number} id - ID da política.
   * @param {SlaPolicy} body - Dados atualizados da política.
   * @returns {Promise<ApiResponse>}
   */
  static async slaPoliciesEdit(id: number, body: SlaPolicy): Promise<Response> {
    return await useHttp().put(body, `/sla-policies/${id}`);
  }

  /**
   * Exclui uma escalação de uma política de SLA.
   * @param {number} slaPolicyId - ID da política.
   * @param {number} slaEscalationId - ID da escalação.
   * @returns {Promise<ApiResponse>}
   */
  static async slaPoliciesDeleteEscalation(slaPolicyId: number, slaEscalationId: number): Promise<Response> {
    return await useHttp().del(`/sla-policies/${slaPolicyId}/escalations/${slaEscalationId}`);
  }
}

export default SlaPoliciesService;