import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useApi from "helpers/api";
import { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import MaskedInput from "react-text-mask";

const ContactCustomerRegistrationModal = ({ showModal, handleClose, handleSubmit, customer }) => {
  const api = useApi();
  const [customers, setCustomers] = useState([]);
  const [filters] = useState({ size: 100, includeInactives: false });
  const [body, setBody] = useState({
    name: '',
    email: '',
    phone: '',
    landline: '',
    inactive: false,
    customer: { id: '' }
  });
  const [validated, setValidated] = useState(false);
  const form = useRef(null);

  useEffect(() => {
    if (showModal) {
      clearFields();
      setValidated(false);
    };
  }, [showModal]);

  useEffect(() => {
    getCustomers();
  }, [filters.name]);

  const getCustomers = async () => {
    if (filters.name != '') {
      const queryParams = new URLSearchParams(filters).toString();
      let response = await api.customers.list(queryParams);
      setCustomers(response.content);
    }
  };

  const onSubmit = e => {
    if (form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      setValidated(false);
      handleSubmit({
        name: body.name,
        email: body.email === '' ? null : body.email,
        phone: body.phone === '' ? null : body.phone,
        landline: body.landline === '' ? null : body.landline,
        customer: { id: customer ? customer.id : body.customer.id },
        inactive: false
      });
      clearFields();
    }
  };

  const clearFields = () => {
    setBody({
      name: '',
      email: '',
      phone: '',
      landline: '',
      customer: { id: '' },
      inactive: false
    });
  }

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Novo contato</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form validated={validated} ref={form}>
          <Form.Group className="mb-3" controlId="contact.name">
            <Form.Label>Nome <span className="text-danger">*</span></Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Nome"
              autoFocus
              value={body.name}
              onChange={e => setBody(prevState => ({ ...prevState, name: e.target.value }))}
            />
            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Insira um nome.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="contact.email">
            <Form.Label>E-mail {body.phone === '' && body.landline === '' && <span className="text-danger">*</span>}</Form.Label>
            <Form.Control
              required={body.phone === '' && body.landline === ''}
              type="email"
              placeholder="E-mail"
              value={body.email}
              onChange={e => setBody(prevState => ({ ...prevState, email: e.target.value }))}
            />
            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Insira um e-mail.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="agent.phone">
            <Form.Label>Telefone {body.email === '' && body.phone === '' && <span className="text-danger">*</span>}</Form.Label>
            <MaskedInput
              mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              placeholder="(00) 0000-0000"
              required={body.email === '' && body.phone === ''}
              guide={false}
              value={body.landline}
              onChange={e => setBody(prevBody => ({ ...prevBody, landline: e.target.value }))}
              render={(ref, props) => (
                <Form.Control ref={ref} {...props} />
              )}
            />

            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Insira um número de telefone.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="agent.phone">
            <Form.Label>Celular {body.email === '' && body.landline === '' && <span className="text-danger">*</span>}</Form.Label>
            <MaskedInput
              mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              placeholder="(00) 00000-0000"
              required={body.email === '' && body.landline === ''}
              guide={false}
              value={body.phone}
              onChange={e => setBody(prevBody => ({ ...prevBody, phone: e.target.value }))}
              render={(ref, props) => (
                <Form.Control ref={ref} {...props} />
              )}
            />

            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Insira um número de celular.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="contact.customer">
            <Form.Label>Cliente principal <span className="text-danger">*</span></Form.Label>
            <Form.Select defaultValue={customer.id} disabled>
              {customers.map((item, i) => (
                <option value={item.id} key={i}>{item.name}</option>
              ))}
            </Form.Select>
            <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Insira um cliente.</Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="falcon-default" onClick={handleClose}>
          Fechar
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          <FontAwesomeIcon icon={faSave} className="me-2" />
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ContactCustomerRegistrationModal;