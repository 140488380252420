import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar, { AvatarGroup } from 'components/common/Avatar';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import Loading from 'components/loading/Loading';
import useApi from 'helpers/api';
import { classColors } from 'helpers/utils';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { useReportFilters } from 'hooks/useReportFilters';
import PropTypes from 'prop-types';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import React, { useEffect, useState } from 'react';
import { Card, OverlayTrigger, ProgressBar, Tooltip } from 'react-bootstrap';
import CompletedTicketsByServiceGroupModal from './CompletedTicketsByServiceGroupModal';
import HoursIndicatedByServiceGroupModal from './HoursIndicatedByServiceGroupModal';
import AgentPhotoService from 'services/AgentPhoto.service';

const ProductivityByServiceGroup = ({ filters }) => {
  const api = useApi();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [completedTicketsFilters, setCompletedTicketsFilters] = useReportFilters(filters);
  const [hoursIndicatedFilters, setHoursIndicatedFilters] = useReportFilters(filters);
  const [completedTickets, setCompletedTickets] = useState({});
  const [hoursIndicatedTickets, setHoursIndicatedTickets] = useState({});
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [reportType, setReportType] = useState(0);
  const [showCompletedTickets, setShowCompletedTickets] = useState(false);
  const [showHoursIndicated, setShowHoursIndicated] = useState(false);

  useEffect(() => {
    getData();
  }, [filters]);

  const getData = async () => {
    setLoading(true);
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.reports.productivity.productivityByServiceGroup(queryParams);

    if (response) {
      const enhancedData = await Promise.all(
        response.map(async (group) => {
          if (group.agentIds && group.agentIds.length > 0) {
            /* const requests = group.agentIds.map(id => api.agentPhoto.find(id)); */
            const requests = group.agentIds.map(id => AgentPhotoService.find(id));
            const responses = await Promise.all(requests);
            const agentPhotos = [];
            for (let i = 0; i < responses.length; i++) {
              if (responses[i].ok) {
                const { url } = await responses[i].json();
                agentPhotos.push({ agentId: group.agentIds[i], url });
              }
            }
            return { ...group, agentPhotos };
          }
          return group;
        })
      );
      setData(enhancedData);
    } else {
      setData([]);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (selectedGroupId && reportType === 1) {
      setShowCompletedTickets(true);
    } else if (selectedGroupId && reportType === 2) {
      setShowHoursIndicated(true);
    }
  }, [selectedGroupId, reportType, api]);

  useEffect(() => {
    if (selectedGroupId && reportType === 1) {
      const fetchCompletedTickets = async () => {
        setLoading(true);
        const queryParams = new URLSearchParams(completedTicketsFilters).toString();
        const response = await api.reports.productivity.completedTicketsByServiceGroup(selectedGroupId, queryParams);
        if (response.content) {
          setCompletedTickets(response);
        }
        setLoading(false);
      };

      fetchCompletedTickets();
    }
  }, [selectedGroupId, reportType, completedTicketsFilters, api]);

  useEffect(() => {
    if (selectedGroupId && reportType === 2) {
      const fetchHoursIndicatedTickets = async () => {
        setLoading(true);
        const queryParams = new URLSearchParams(hoursIndicatedFilters).toString();
        const response = await api.reports.productivity.hoursIndicatedByServiceGroup(selectedGroupId, queryParams);
        if (response.content) {
          setHoursIndicatedTickets(response);
        }
        setLoading(false);
      };

      fetchHoursIndicatedTickets();
    }
  }, [selectedGroupId, reportType, hoursIndicatedFilters, api]);

  const handleGroupSelection = (groupId, report) => {
    setSelectedGroupId(groupId);
    setReportType(report);

    if (report === 1) {
      setCompletedTicketsFilters((prevFilters) => ({ ...prevFilters, page: 0 }));
    } else if (report === 2) {
      setHoursIndicatedFilters((prevFilters) => ({ ...prevFilters, page: 0 }));
    }
  };

  const columns = [
    {
      accessorKey: 'serviceGroupName',
      header: 'Grupo de Serviço',
      meta: {
        headerProps: { className: 'text-800' }
      },
      cell: ({ row: { original } }) => {
        const { serviceGroupName, inactive } = original;

        const getColor = (serviceGroupName) => {
          const index = serviceGroupName.length % classColors.length;
          return classColors[index];
        };

        return (
          <Flex alignItems="center" className="position-relative">
            <Avatar
              name={serviceGroupName}
              width="60"
              alt=""
              mediaClass={`text-${getColor(serviceGroupName)} bg-${getColor(serviceGroupName)}-subtle fs-9`}
            />
            <div className="flex-1 ms-3">
              <p className="fs-10 mb-0 text-800">{serviceGroupName} {inactive && <span className='text-danger'>(Inativo)</span>}</p>
            </div>
          </Flex>
        );
      }
    },
    {
      accessorKey: 'progress',
      header: <><span className='me-1'>Tickets Concluídos</span>
        <OverlayTrigger overlay={<Tooltip>Para visualizar os tickets de forma detalhada, clique sobre a barra de pogressão.</Tooltip>}>
          <span><FontAwesomeIcon icon={faInfoCircle} /></span>
        </OverlayTrigger></>,
      enableSorting: false,
      meta: {
        headerProps: {
          className: 'text-center text-800'
        },
        cellProps: {
          className: 'text-center'
        }
      },
      cell: ({ row: { original } }) => {
        const { serviceGroupId, resolvedTicketsPercentage, resolvedTickets } = original;
        return (
          <OverlayTrigger overlay={<Tooltip>Tickets resolvidos: {resolvedTickets} - {resolvedTicketsPercentage}%</Tooltip>}>
            <ProgressBar
              now={resolvedTicketsPercentage}
              style={{ height: 5 }}
              className="rounded-pill align-middle"
              variant="progress-gradient"
              onClick={() => handleGroupSelection(serviceGroupId, 1)} // Ação de clique
              role="button"
            />
          </OverlayTrigger>
        );
      }
    },
    {
      accessorKey: 'totalHours',
      header: <><span className='me-1'>Horas Apontadas</span>
        <OverlayTrigger overlay={<Tooltip>Para visualizar as horas apontadas de forma detalhada, clique sobre as horas.</Tooltip>}>
          <span><FontAwesomeIcon icon={faInfoCircle} /></span>
        </OverlayTrigger></>,
      meta: {
        cellProps: {
          className: 'text-center fw-semibold fs-10'
        },
        headerProps: {
          className: 'text-center text-800'
        }
      },
      cell: ({ row: { original } }) => {
        const { serviceGroupId, totalHours } = original;
        return (
          <span role="button" onClick={() => handleGroupSelection(serviceGroupId, 2)}>
            {totalHours || "00:00"}
          </span>
        );
      }
    },
    {
      accessorKey: 'agentIds',
      header: 'Agentes Associados',
      enableSorting: false,
      meta: {
        headerProps: {
          className: 'text-end text-800'
        }
      },
      cell: ({ row: { original } }) => {
        const { agentPhotos } = original;
        return (
          <AvatarGroup className="justify-content-end">
            {agentPhotos && agentPhotos.map((photo, i) => {
              return (
                <Avatar
                  src={photo.url}
                  name={'Agente'}
                  key={i}
                  isExact
                  className="border border-3 rounded-circle border-200"
                />
              );
            })}
          </AvatarGroup>
        );
      }
    }
  ];

  const table = useAdvanceTable({
    data,
    columns
  });

  return (
    <>
      {/* {loading && <Loading />} */}
      <CompletedTicketsByServiceGroupModal
        show={showCompletedTickets}
        close={() => {
          setShowCompletedTickets(false);
          setSelectedGroupId(null);
        }}
        tickets={completedTickets}
        filters={completedTicketsFilters}
        setFilters={setCompletedTicketsFilters}
        groupId={selectedGroupId}
      />
      <HoursIndicatedByServiceGroupModal
        show={showHoursIndicated}
        close={() => {
          setShowHoursIndicated(false);
          setSelectedGroupId(null);
        }}
        tickets={hoursIndicatedTickets}
        filters={hoursIndicatedFilters}
        setFilters={setHoursIndicatedFilters}
        groupId={selectedGroupId}
      />
      <AdvanceTableProvider {...table}>
        <Card className="h-100 mt-3">
          <FalconCardHeader title="Produtividade por Grupo de Serviço" titleTag="h4" />
          <Card.Body className="p-0">
            <AdvanceTable
              headerClassName="bg-body-tertiary text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                borderless: true,
                className: 'fs-10 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
        </Card>
      </AdvanceTableProvider>
    </>
  );
};

ProductivityByServiceGroup.propTypes = {
  filters: PropTypes.object.isRequired,
};

export default ProductivityByServiceGroup;