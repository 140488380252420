import { PhotoResponse, UpdatePhotoResponse } from 'types/AgetPhotoTypes';
import useHttp from '../helpers/HttpMethods';

class CompanyLogoService {
  /**
   * Busca a foto da empresa.
   * @param {number} id - ID da foto da empresa.
   * @returns {Promise<Response>} - Response que traz a imagem com request.blob().
   */
  static async findById(id: number): Promise<Response> {
    return await useHttp().getWithAccept(`/companies/${id}/logo`, '*/*');
  }

  /**
   * Altera a foto da empresa.
   * @param {number} id - ID do usuário.
   * @param {FormData} logo - Foto escolhida pelo usuário em FormData.
   * @returns {Promise<Response>}
   */
  static async update(id: number, logo: FormData): Promise<Response> {
   return await useHttp().putFile(logo, `/companies/${id}/logo`);
  }

  /**
   * Exclui a foto da empresa.
   * @param {number} id - ID do usuário.
   * @returns {Promise<Response>} - Retorno padrão de uma de uma request.
   */
  static async delete(id: number): Promise<Response> {
    return await useHttp().del(`/companies/${id}/logo`);
  }
}

export default CompanyLogoService;