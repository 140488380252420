import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useApi from 'helpers/api';
import { ufs } from "helpers/utils";
import useNotification from "hooks/useNotification";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import MaskedInput from "react-text-mask";

// Constants
const CUSTOMER_TYPES = {
  JURIDICAL: 'JURIDICAL_PERSON',
  NATURAL: 'NATURAL_PERSON'
};

const INITIAL_STATE = {
  type: CUSTOMER_TYPES.JURIDICAL,
  corporateName: '',
  fantasyName: '',
  cnpj: '',
  stateRegistration: '',
  name: '',
  cpf: '',
  gender: '',
  birthday: '',
  domains: '',
  address: {
    name: '',
    street: '',
    number: '',
    neighborhood: '',
    city: '',
    state: '',
    cep: '',
    complement: ''
  }
};

const GENDER_OPTIONS = [
  { value: '', label: 'Selecione uma opção' },
  { value: 'MALE', label: 'Masculino' },
  { value: 'FEMININE', label: 'Feminino' }
];

const CustomerRegistrationOffcanvas = ({ showModal, handleClose, isSuccess, createdCustomer }) => {
  // Hooks
  const api = useApi();
  const navigate = useNavigate();
  const { showSuccessNotification, showErrorNotification } = useNotification();

  // State
  const [validated, setValidated] = useState(false);
  const [body, setBody] = useState(INITIAL_STATE);
  const form = useRef(null);

  // Derived state
  const isCompany = body.type === CUSTOMER_TYPES.JURIDICAL;

  // Effects
  useEffect(() => {
    if (showModal) {
      clearFields();
      setValidated(false);
    }
  }, [showModal]);

  // CEP Search
  const searchCEP = async (cep) => {
    const cleanedCEP = cep.replace(/\D/g, '');
    if (cleanedCEP.length === 8) {
      try {
        const response = await fetch(`https://viacep.com.br/ws/${cleanedCEP}/json/`);
        if (response.ok) {
          const data = await response.json();
          if (!data.erro) {
            setBody(prev => ({
              ...prev,
              address: {
                ...prev.address,
                street: data.logradouro || '',
                neighborhood: data.bairro || '',
                city: data.localidade || '',
                state: data.uf || '',
                complement: data.complemento || '',
                cep: data.cep || cep
              }
            }));
          }
        }
      } catch (error) {
        console.error('Erro ao buscar CEP:', error);
      }
    }
  };

  // Handlers
  const handleAddressChange = useCallback(async (field, value) => {
    const newBody = {
      ...body,
      address: {
        ...body.address,
        [field]: value
      }
    };

    setBody(newBody);

    // Busca automática do CEP quando completo
    if (field === 'cep' && value.length === 9) {
      await searchCEP(value);
    }
  }, [body]);

  const handleFieldChange = useCallback((field, value) => {
    setBody(prev => ({ ...prev, [field]: value }));
  }, []);

  const clearFields = useCallback(() => {
    setBody(INITIAL_STATE);
  }, []);

  // Form preparation
  const prepareFormData = useCallback(() => {
    const { type, corporateName, fantasyName, cnpj, stateRegistration,
      name, cpf, gender, birthday, domains, address } = body;

    const formData = {
      type,
      corporateName: isCompany ? corporateName : null,
      fantasyName,
      cnpj: isCompany ? cnpj : null,
      stateRegistration: isCompany && stateRegistration ? stateRegistration : null,
      name: isCompany ? fantasyName : name,
      cpf: !isCompany ? cpf : null,
      gender: !isCompany && gender ? gender : null,
      birthday: !isCompany && birthday ? birthday : null,
      domains: domains ? domains.split(';').map(d => d.trim()).filter(d => d) : null,
    };

    if (address.name) {
      formData.address = { ...address };
    }

    return formData;
  }, [body, isCompany]);

  // Form submission
  const handleSubmit = useCallback(async (shouldNavigate = false) => {
    if (!form.current.checkValidity()) {
      setValidated(true);
      isSuccess(false);
      return;
    }

    const formData = prepareFormData();

    try {
      const response = await api.customers.create(formData);

      if (response.userMessage) {
        showErrorNotification(response.userMessage || "Erro ao cadastrar cliente");
        isSuccess(false);
        setValidated(true);
        return;
      }

      showSuccessNotification("Cliente cadastrado com sucesso");
      createdCustomer(response);
      clearFields();

      if (shouldNavigate) {
        navigate(`/registration/customers/${response.id}`);
      }

      handleClose();
    } catch (error) {
      showErrorNotification(error.userMessage || "Erro ao processar requisição");
    }
  }, [api.customers, clearFields, createdCustomer, handleClose, isSuccess,
    navigate, prepareFormData, showErrorNotification, showSuccessNotification]);

  const handleCreate = useCallback((e) => {
    e?.preventDefault();
    handleSubmit(false);
  }, [handleSubmit]);

  const handleCreateAndOpen = useCallback((e) => {
    e?.preventDefault();
    handleSubmit(true);
  }, [handleSubmit]);

  return (
    <Offcanvas
      show={showModal}
      onHide={handleClose}
      placement="end"
      style={{ minWidth: '45%', maxWidth: '90%' }}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Novo cliente</Offcanvas.Title>
      </Offcanvas.Header>

      <Offcanvas.Body>
        <Form validated={validated} ref={form}>
          {/* Tipo e Documento */}
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="customerType">
              <Form.Label>Tipo <span className="text-danger">*</span></Form.Label>
              <Form.Select
                value={body.type}
                onChange={(e) => handleFieldChange('type', e.target.value)}
                required
              >
                <option value={CUSTOMER_TYPES.JURIDICAL}>Jurídica</option>
                <option value={CUSTOMER_TYPES.NATURAL}>Física</option>
              </Form.Select>
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira um tipo.</Form.Control.Feedback>
            </Form.Group>

            {isCompany ? (
              <Form.Group as={Col} md="8" controlId="cnpj">
                <Form.Label>CNPJ <span className="text-danger">*</span></Form.Label>
                <MaskedInput
                  mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                  guide={false}
                  required
                  placeholder="CNPJ"
                  value={body.cnpj}
                  onChange={(e) => handleFieldChange('cnpj', e.target.value)}
                  render={(ref, props) => (
                    <Form.Control
                      ref={ref}
                      {...props}
                      minLength={18}
                    />
                  )}
                />
                <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Insira um CNPJ válido.</Form.Control.Feedback>
              </Form.Group>
            ) : (
              <Form.Group as={Col} md="8" controlId="cpf">
                <Form.Label>CPF <span className="text-danger">*</span></Form.Label>
                <MaskedInput
                  mask={[/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                  guide={false}
                  required
                  placeholder="CPF"
                  value={body.cpf}
                  onChange={(e) => handleFieldChange('cpf', e.target.value)}
                  render={(ref, props) => (
                    <Form.Control
                      ref={ref}
                      {...props}
                      minLength={14}
                    />
                  )}
                />
                <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">Insira um CPF válido.</Form.Control.Feedback>
              </Form.Group>
            )}
          </Row>

          {/* Nome/Razão Social e Nome Fantasia/Tratamento */}
          <Row className="mb-3">
            {isCompany ? (
              <>
                <Form.Group as={Col} md="6" controlId="corporateName">
                  <Form.Label>Razão social <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Razão social"
                    value={body.corporateName}
                    onChange={(e) => handleFieldChange('corporateName', e.target.value)}
                  />
                  <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Insira uma razão social.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="fantasyName">
                  <Form.Label>Nome fantasia <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Nome fantasia"
                    value={body.fantasyName}
                    onChange={(e) => handleFieldChange('fantasyName', e.target.value)}
                  />
                  <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Insira um nome fantasia.</Form.Control.Feedback>
                </Form.Group>
              </>
            ) : (
              <>
                <Form.Group as={Col} md="6" controlId="fullName">
                  <Form.Label>Nome completo <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Nome completo"
                    value={body.name}
                    onChange={(e) => handleFieldChange('name', e.target.value)}
                    onBlur={() => {
                      if (!body.fantasyName) {
                        handleFieldChange('fantasyName', body.name);
                      }
                    }}
                  />
                  <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Insira um nome completo.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="fantasyName">
                  <Form.Label>Nome de tratamento <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Nome de tratamento"
                    value={body.fantasyName}
                    onChange={(e) => handleFieldChange('fantasyName', e.target.value)}
                  />
                  <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Insira um nome de tratamento.</Form.Control.Feedback>
                </Form.Group>
              </>
            )}
          </Row>

          {isCompany && (
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="stateRegistration">
                <Form.Label>Inscrição estadual</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Inscrição estadual"
                  value={body.stateRegistration}
                  onChange={(e) => handleFieldChange('stateRegistration', e.target.value)}
                />
                <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              </Form.Group>
            </Row>
          )}

          {!isCompany && (
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="birthDate">
                <Form.Label>Data de nascimento</Form.Label>
                <Form.Control
                  type="date"
                  value={body.birthday}
                  max={new Date().toISOString().split('T')[0]}
                  onChange={(e) => handleFieldChange('birthday', e.target.value)}
                />
                <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="gender">
                <Form.Label>Gênero</Form.Label>
                <Form.Select
                  value={body.gender}
                  onChange={(e) => handleFieldChange('gender', e.target.value)}
                >
                  {GENDER_OPTIONS.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              </Form.Group>
            </Row>
          )}

          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="allowedDomains">
              <Form.Label>Domínios liberados</Form.Label>
              <Form.Control
                type="text"
                placeholder="exemplo.com;outroexemplo.com"
                value={body.domains}
                onChange={(e) => handleFieldChange('domains', e.target.value)}
              />
              <Form.Text className="text-muted">
                Insira os domínios de email permitidos para este cliente, separados por ponto e vírgula.
              </Form.Text>
            </Form.Group>
          </Row>

          {/* Endereço - Agora com CEP na primeira linha */}
          <h5 className="mt-4 mb-3">Endereço principal</h5>

          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="addressCEP">
              <Form.Label>CEP</Form.Label>
              <MaskedInput
                mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                guide={false}
                placeholder="CEP"
                value={body.address.cep}
                onChange={(e) => handleAddressChange('cep', e.target.value)}
                render={(ref, props) => <Form.Control ref={ref} {...props} />}
              />
            </Form.Group>

            <Form.Group as={Col} md="8" controlId="addressName">
              <Form.Label>Nome do endereço</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ex: Matriz, Filial, Casa, Trabalho"
                value={body.address.name}
                onChange={(e) => handleAddressChange('name', e.target.value)}
              />
              <Form.Text className="text-muted">
                Dê um nome para identificar este endereço.
              </Form.Text>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="8" controlId="addressStreet">
              <Form.Label>Logradouro</Form.Label>
              <Form.Control
                type="text"
                placeholder="Rua, Avenida, etc."
                value={body.address.street}
                onChange={(e) => handleAddressChange('street', e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="addressNumber">
              <Form.Label>Número</Form.Label>
              <Form.Control
                type="text"
                placeholder="Número"
                value={body.address.number}
                onChange={(e) => handleAddressChange('number', e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="addressNeighborhood">
              <Form.Label>Bairro</Form.Label>
              <Form.Control
                type="text"
                placeholder="Bairro"
                value={body.address.neighborhood}
                onChange={(e) => handleAddressChange('neighborhood', e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="addressCity">
              <Form.Label>Cidade</Form.Label>
              <Form.Control
                type="text"
                placeholder="Cidade"
                value={body.address.city}
                onChange={(e) => handleAddressChange('city', e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="addressState">
              <Form.Label>UF</Form.Label>
              <Form.Select
                value={body.address.state}
                onChange={(e) => handleAddressChange('state', e.target.value)}
              >
                <option value="">Selecione</option>
                {ufs.map(uf => (
                  <option key={uf.value} value={uf.value}>{uf.label}</option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} md="8" controlId="addressComplement">
              <Form.Label>Complemento</Form.Label>
              <Form.Control
                type="text"
                placeholder="Complemento"
                value={body.address.complement}
                onChange={(e) => handleAddressChange('complement', e.target.value)}
              />
            </Form.Group>
          </Row>
        </Form>
      </Offcanvas.Body>

      <footer className="fixed-bottom p-2 w-100 d-flex justify-content-end">
        <Button variant="primary" onClick={handleCreateAndOpen} className="me-2">
          Salvar e editar
        </Button>
        <Button variant="primary" onClick={handleCreate}>
          <FontAwesomeIcon icon={faSave} className="me-2" />
          Salvar
        </Button>
      </footer>
    </Offcanvas>
  );
};

export default CustomerRegistrationOffcanvas;