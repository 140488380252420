import { HolidayPaginated } from "types/HolidaysTypes";
import useHttp from '../helpers/HttpMethods';
import DefaultApiResponse from "types/DefaultApiResponseType";

class HolidaysService {
  /**
   * Busca os feriados cadastrados.
   * @returns {Promise<HolidayPaginated>}
   */
  static async holidayList(filter: string): Promise<HolidayPaginated>{
    return await useHttp().get(`/holidays?${filter}`);
  } 
  
  /**
   * Cadastra um novo feriado.
   * @param {DTO para criar um feriado: {date: string, description: string}}
   * @returns {Promise<DefaultApiResponse>}
   */
  static async holidayAdd(body: {date: string, description: string}): Promise<DefaultApiResponse>{
    return await useHttp().post(body ,`/holidays`);
  } 

  /**
   * 
   * NOT IMPLEMENTED - NÃO ESTÁ IMPLEMENTADO! TODO: TALVEZ RETURAR A FUNÇÃO
   * 
   * Cadastra um novo .
   * @param {DTO para criar um feriado: {date: string, description: string}}
   * @returns {Promise<DefaultApiResponse>}
   */
  /* static async holidayEdit(body: {date: string, description: string}, id: number): Promise<HolidayPaginated>{
    return await useHttp().put(body ,`/holidays/${id}`);
  }  */

    /**
   * Excluir um feriado.
   * @param {id de um feriado}
   * @returns {Promise<DefaultApiResponse>}
   */
  static async holidayDelete(id: number): Promise<DefaultApiResponse>{
    return await useHttp().del(`/holidays/${id}`);
  } 
}

export default HolidaysService;