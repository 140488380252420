import { PhotoResponse, UpdatePhotoResponse } from 'types/AgetPhotoTypes';
import useHttp from '../helpers/HttpMethods';

class AgentPhotoService {
  /**
   * Busca a foto do usuário.
   * @param {number} id - ID do usuário.
   * @returns {Promise<PhotoResponse>} - URL da foto em formato JSON.
   */
  static async find(id: number): Promise<PhotoResponse> {
    return await useHttp().getWithAccept(`/users/${id}/photo`, "*/*");
  }

  /**
   * Altera a foto do usuário.
   * @param {number} id - ID do usuário.
   * @param {FormData} photo - Foto escolhida pelo usuário em FormData.
   * @returns {Promise<UpdatePhotoResponse>} - Dados da foto atualizada.
   */
  static async update(id: number, photo: FormData): Promise<UpdatePhotoResponse> {
   return await useHttp().putFile(photo, `/users/${id}/photo`);
  }

  /**
   * Exclui a foto do usuário.
   * @param {number} id - ID do usuário.
   * @returns {Promise<Response>} - Sem retorno de conteúdo no sucesso.
   */
  static async delete(id: number): Promise<Response> {
    return await useHttp().del(`/users/${id}/photo`);
  }
}

export default AgentPhotoService;